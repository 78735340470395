export const hasValidImageDimensions = async (rawfile, allowedDimensions) => {

  const { width: maxWidth, height: maxHeight } = allowedDimensions;

  const getImageDimensions = (rawfile) => {
    return new Promise((resolve, reject) => {
      const url = window.URL.createObjectURL(rawfile);
      const image = new Image();
      image.onload = ev => {
        window.URL.revokeObjectURL(url);
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = err => {
        window.URL.revokeObjectURL(url);
        reject(err)
      };
      image.src = url;
    });
  }

  const { width, height } = await getImageDimensions(rawfile);
  return {
    isValid: (width <= maxWidth && height <= maxHeight),
    dimensions: { width, height }
  }
}

