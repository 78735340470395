

export const TranslationKeyReturnPaths =  {
    origins : ['/TranslationKey', '/ContentPage/{id}'],
    defaultPath : '/TranslationKey'
}

export const PlaylistEditReturnPaths =  {
    origins : ['/Playlist', '/ContentPage/{id}'],
    defaultPath : '/Playlist'
}

export const ContentPageEditReturnPaths =  {
    origins : [],
    defaultPath : '/ContentPage'
}

export const TourEditReturnPaths =  {
    origins : [],
    defaultPath : '/Tour'
}
