/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTour = /* GraphQL */ `
  mutation CreateTour(
    $input: CreateTourInput!
    $condition: ModelTourConditionInput
  ) {
    createTour(input: $input, condition: $condition) {
      id
      origin
      schedule
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTour = /* GraphQL */ `
  mutation UpdateTour(
    $input: UpdateTourInput!
    $condition: ModelTourConditionInput
  ) {
    updateTour(input: $input, condition: $condition) {
      id
      origin
      schedule
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTour = /* GraphQL */ `
  mutation DeleteTour(
    $input: DeleteTourInput!
    $condition: ModelTourConditionInput
  ) {
    deleteTour(input: $input, condition: $condition) {
      id
      origin
      schedule
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPlaylist = /* GraphQL */ `
  mutation CreatePlaylist(
    $input: CreatePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    createPlaylist(input: $input, condition: $condition) {
      id
      name
      sequence
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imageFiles {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePlaylist = /* GraphQL */ `
  mutation UpdatePlaylist(
    $input: UpdatePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    updatePlaylist(input: $input, condition: $condition) {
      id
      name
      sequence
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imageFiles {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deletePlaylist = /* GraphQL */ `
  mutation DeletePlaylist(
    $input: DeletePlaylistInput!
    $condition: ModelPlaylistConditionInput
  ) {
    deletePlaylist(input: $input, condition: $condition) {
      id
      name
      sequence
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imageFiles {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createVideoFile = /* GraphQL */ `
  mutation CreateVideoFile(
    $input: CreateVideoFileInput!
    $condition: ModelVideoFileConditionInput
  ) {
    createVideoFile(input: $input, condition: $condition) {
      id
      title
      posterStorageKey
      videoStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateVideoFile = /* GraphQL */ `
  mutation UpdateVideoFile(
    $input: UpdateVideoFileInput!
    $condition: ModelVideoFileConditionInput
  ) {
    updateVideoFile(input: $input, condition: $condition) {
      id
      title
      posterStorageKey
      videoStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteVideoFile = /* GraphQL */ `
  mutation DeleteVideoFile(
    $input: DeleteVideoFileInput!
    $condition: ModelVideoFileConditionInput
  ) {
    deleteVideoFile(input: $input, condition: $condition) {
      id
      title
      posterStorageKey
      videoStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createContentPage = /* GraphQL */ `
  mutation CreateContentPage(
    $input: CreateContentPageInput!
    $condition: ModelContentPageConditionInput
  ) {
    createContentPage(input: $input, condition: $condition) {
      id
      uniqueName
      titleTranslationKey
      sloganTranslationKey
      descriptionTranslationKey
      contactID
      linkOutTranslationKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      announcements {
        items {
          id
          text
          startDate
          endDate
          url
          contentpageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentFiles {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateContentPage = /* GraphQL */ `
  mutation UpdateContentPage(
    $input: UpdateContentPageInput!
    $condition: ModelContentPageConditionInput
  ) {
    updateContentPage(input: $input, condition: $condition) {
      id
      uniqueName
      titleTranslationKey
      sloganTranslationKey
      descriptionTranslationKey
      contactID
      linkOutTranslationKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      announcements {
        items {
          id
          text
          startDate
          endDate
          url
          contentpageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentFiles {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteContentPage = /* GraphQL */ `
  mutation DeleteContentPage(
    $input: DeleteContentPageInput!
    $condition: ModelContentPageConditionInput
  ) {
    deleteContentPage(input: $input, condition: $condition) {
      id
      uniqueName
      titleTranslationKey
      sloganTranslationKey
      descriptionTranslationKey
      contactID
      linkOutTranslationKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      announcements {
        items {
          id
          text
          startDate
          endDate
          url
          contentpageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentFiles {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createBadge = /* GraphQL */ `
  mutation CreateBadge(
    $input: CreateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    createBadge(input: $input, condition: $condition) {
      id
      text
      url
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateBadge = /* GraphQL */ `
  mutation UpdateBadge(
    $input: UpdateBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    updateBadge(input: $input, condition: $condition) {
      id
      text
      url
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteBadge = /* GraphQL */ `
  mutation DeleteBadge(
    $input: DeleteBadgeInput!
    $condition: ModelBadgeConditionInput
  ) {
    deleteBadge(input: $input, condition: $condition) {
      id
      text
      url
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      text
      startDate
      endDate
      url
      contentpageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      text
      startDate
      endDate
      url
      contentpageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      text
      startDate
      endDate
      url
      contentpageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      name
      jobTitle
      phone
      fax
      email
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentPages {
        items {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      name
      jobTitle
      phone
      fax
      email
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentPages {
        items {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      name
      jobTitle
      phone
      fax
      email
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentPages {
        items {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createDocumentFile = /* GraphQL */ `
  mutation CreateDocumentFile(
    $input: CreateDocumentFileInput!
    $condition: ModelDocumentFileConditionInput
  ) {
    createDocumentFile(input: $input, condition: $condition) {
      id
      title
      documentStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateDocumentFile = /* GraphQL */ `
  mutation UpdateDocumentFile(
    $input: UpdateDocumentFileInput!
    $condition: ModelDocumentFileConditionInput
  ) {
    updateDocumentFile(input: $input, condition: $condition) {
      id
      title
      documentStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteDocumentFile = /* GraphQL */ `
  mutation DeleteDocumentFile(
    $input: DeleteDocumentFileInput!
    $condition: ModelDocumentFileConditionInput
  ) {
    deleteDocumentFile(input: $input, condition: $condition) {
      id
      title
      documentStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createImageFile = /* GraphQL */ `
  mutation CreateImageFile(
    $input: CreateImageFileInput!
    $condition: ModelImageFileConditionInput
  ) {
    createImageFile(input: $input, condition: $condition) {
      id
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ImageFileLanguages {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateImageFile = /* GraphQL */ `
  mutation UpdateImageFile(
    $input: UpdateImageFileInput!
    $condition: ModelImageFileConditionInput
  ) {
    updateImageFile(input: $input, condition: $condition) {
      id
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ImageFileLanguages {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteImageFile = /* GraphQL */ `
  mutation DeleteImageFile(
    $input: DeleteImageFileInput!
    $condition: ModelImageFileConditionInput
  ) {
    deleteImageFile(input: $input, condition: $condition) {
      id
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ImageFileLanguages {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createTranslationKey = /* GraphQL */ `
  mutation CreateTranslationKey(
    $input: CreateTranslationKeyInput!
    $condition: ModelTranslationKeyConditionInput
  ) {
    createTranslationKey(input: $input, condition: $condition) {
      id
      key
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTranslationKey = /* GraphQL */ `
  mutation UpdateTranslationKey(
    $input: UpdateTranslationKeyInput!
    $condition: ModelTranslationKeyConditionInput
  ) {
    updateTranslationKey(input: $input, condition: $condition) {
      id
      key
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTranslationKey = /* GraphQL */ `
  mutation DeleteTranslationKey(
    $input: DeleteTranslationKeyInput!
    $condition: ModelTranslationKeyConditionInput
  ) {
    deleteTranslationKey(input: $input, condition: $condition) {
      id
      key
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      locale
      nativeName
      displayName
      published
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      imagefiles {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videofiles {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentfiles {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      announcements {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      locale
      nativeName
      displayName
      published
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      imagefiles {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videofiles {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentfiles {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      announcements {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      locale
      nativeName
      displayName
      published
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      imagefiles {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videofiles {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentfiles {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      announcements {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createTranslation = /* GraphQL */ `
  mutation CreateTranslation(
    $input: CreateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    createTranslation(input: $input, condition: $condition) {
      id
      translationkeyID
      languageID
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTranslation = /* GraphQL */ `
  mutation UpdateTranslation(
    $input: UpdateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    updateTranslation(input: $input, condition: $condition) {
      id
      translationkeyID
      languageID
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteTranslation = /* GraphQL */ `
  mutation DeleteTranslation(
    $input: DeleteTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    deleteTranslation(input: $input, condition: $condition) {
      id
      translationkeyID
      languageID
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createImageFileLanguage = /* GraphQL */ `
  mutation CreateImageFileLanguage(
    $input: CreateImageFileLanguageInput!
    $condition: ModelImageFileLanguageConditionInput
  ) {
    createImageFileLanguage(input: $input, condition: $condition) {
      id
      imagefileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imagefile {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateImageFileLanguage = /* GraphQL */ `
  mutation UpdateImageFileLanguage(
    $input: UpdateImageFileLanguageInput!
    $condition: ModelImageFileLanguageConditionInput
  ) {
    updateImageFileLanguage(input: $input, condition: $condition) {
      id
      imagefileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imagefile {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteImageFileLanguage = /* GraphQL */ `
  mutation DeleteImageFileLanguage(
    $input: DeleteImageFileLanguageInput!
    $condition: ModelImageFileLanguageConditionInput
  ) {
    deleteImageFileLanguage(input: $input, condition: $condition) {
      id
      imagefileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imagefile {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createVideoFileLanguage = /* GraphQL */ `
  mutation CreateVideoFileLanguage(
    $input: CreateVideoFileLanguageInput!
    $condition: ModelVideoFileLanguageConditionInput
  ) {
    createVideoFileLanguage(input: $input, condition: $condition) {
      id
      videofileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateVideoFileLanguage = /* GraphQL */ `
  mutation UpdateVideoFileLanguage(
    $input: UpdateVideoFileLanguageInput!
    $condition: ModelVideoFileLanguageConditionInput
  ) {
    updateVideoFileLanguage(input: $input, condition: $condition) {
      id
      videofileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteVideoFileLanguage = /* GraphQL */ `
  mutation DeleteVideoFileLanguage(
    $input: DeleteVideoFileLanguageInput!
    $condition: ModelVideoFileLanguageConditionInput
  ) {
    deleteVideoFileLanguage(input: $input, condition: $condition) {
      id
      videofileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createDocumentFileLanguage = /* GraphQL */ `
  mutation CreateDocumentFileLanguage(
    $input: CreateDocumentFileLanguageInput!
    $condition: ModelDocumentFileLanguageConditionInput
  ) {
    createDocumentFileLanguage(input: $input, condition: $condition) {
      id
      documentfileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      documentfile {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDocumentFileLanguage = /* GraphQL */ `
  mutation UpdateDocumentFileLanguage(
    $input: UpdateDocumentFileLanguageInput!
    $condition: ModelDocumentFileLanguageConditionInput
  ) {
    updateDocumentFileLanguage(input: $input, condition: $condition) {
      id
      documentfileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      documentfile {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDocumentFileLanguage = /* GraphQL */ `
  mutation DeleteDocumentFileLanguage(
    $input: DeleteDocumentFileLanguageInput!
    $condition: ModelDocumentFileLanguageConditionInput
  ) {
    deleteDocumentFileLanguage(input: $input, condition: $condition) {
      id
      documentfileID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      documentfile {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createAnnouncementLanguage = /* GraphQL */ `
  mutation CreateAnnouncementLanguage(
    $input: CreateAnnouncementLanguageInput!
    $condition: ModelAnnouncementLanguageConditionInput
  ) {
    createAnnouncementLanguage(input: $input, condition: $condition) {
      id
      announcementID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      announcement {
        id
        text
        startDate
        endDate
        url
        contentpageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateAnnouncementLanguage = /* GraphQL */ `
  mutation UpdateAnnouncementLanguage(
    $input: UpdateAnnouncementLanguageInput!
    $condition: ModelAnnouncementLanguageConditionInput
  ) {
    updateAnnouncementLanguage(input: $input, condition: $condition) {
      id
      announcementID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      announcement {
        id
        text
        startDate
        endDate
        url
        contentpageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteAnnouncementLanguage = /* GraphQL */ `
  mutation DeleteAnnouncementLanguage(
    $input: DeleteAnnouncementLanguageInput!
    $condition: ModelAnnouncementLanguageConditionInput
  ) {
    deleteAnnouncementLanguage(input: $input, condition: $condition) {
      id
      announcementID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      announcement {
        id
        text
        startDate
        endDate
        url
        contentpageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createBadgeLanguage = /* GraphQL */ `
  mutation CreateBadgeLanguage(
    $input: CreateBadgeLanguageInput!
    $condition: ModelBadgeLanguageConditionInput
  ) {
    createBadgeLanguage(input: $input, condition: $condition) {
      id
      badgeID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      badge {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateBadgeLanguage = /* GraphQL */ `
  mutation UpdateBadgeLanguage(
    $input: UpdateBadgeLanguageInput!
    $condition: ModelBadgeLanguageConditionInput
  ) {
    updateBadgeLanguage(input: $input, condition: $condition) {
      id
      badgeID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      badge {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteBadgeLanguage = /* GraphQL */ `
  mutation DeleteBadgeLanguage(
    $input: DeleteBadgeLanguageInput!
    $condition: ModelBadgeLanguageConditionInput
  ) {
    deleteBadgeLanguage(input: $input, condition: $condition) {
      id
      badgeID
      languageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      badge {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      language {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createContentPageBadge = /* GraphQL */ `
  mutation CreateContentPageBadge(
    $input: CreateContentPageBadgeInput!
    $condition: ModelContentPageBadgeConditionInput
  ) {
    createContentPageBadge(input: $input, condition: $condition) {
      id
      contentpageID
      badgeID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      badge {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateContentPageBadge = /* GraphQL */ `
  mutation UpdateContentPageBadge(
    $input: UpdateContentPageBadgeInput!
    $condition: ModelContentPageBadgeConditionInput
  ) {
    updateContentPageBadge(input: $input, condition: $condition) {
      id
      contentpageID
      badgeID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      badge {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteContentPageBadge = /* GraphQL */ `
  mutation DeleteContentPageBadge(
    $input: DeleteContentPageBadgeInput!
    $condition: ModelContentPageBadgeConditionInput
  ) {
    deleteContentPageBadge(input: $input, condition: $condition) {
      id
      contentpageID
      badgeID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      badge {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createContentPageDocumentFile = /* GraphQL */ `
  mutation CreateContentPageDocumentFile(
    $input: CreateContentPageDocumentFileInput!
    $condition: ModelContentPageDocumentFileConditionInput
  ) {
    createContentPageDocumentFile(input: $input, condition: $condition) {
      id
      contentpageID
      documentfileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      documentfile {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateContentPageDocumentFile = /* GraphQL */ `
  mutation UpdateContentPageDocumentFile(
    $input: UpdateContentPageDocumentFileInput!
    $condition: ModelContentPageDocumentFileConditionInput
  ) {
    updateContentPageDocumentFile(input: $input, condition: $condition) {
      id
      contentpageID
      documentfileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      documentfile {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteContentPageDocumentFile = /* GraphQL */ `
  mutation DeleteContentPageDocumentFile(
    $input: DeleteContentPageDocumentFileInput!
    $condition: ModelContentPageDocumentFileConditionInput
  ) {
    deleteContentPageDocumentFile(input: $input, condition: $condition) {
      id
      contentpageID
      documentfileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      documentfile {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createContentPageVideoFile = /* GraphQL */ `
  mutation CreateContentPageVideoFile(
    $input: CreateContentPageVideoFileInput!
    $condition: ModelContentPageVideoFileConditionInput
  ) {
    createContentPageVideoFile(input: $input, condition: $condition) {
      id
      contentpageID
      videofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateContentPageVideoFile = /* GraphQL */ `
  mutation UpdateContentPageVideoFile(
    $input: UpdateContentPageVideoFileInput!
    $condition: ModelContentPageVideoFileConditionInput
  ) {
    updateContentPageVideoFile(input: $input, condition: $condition) {
      id
      contentpageID
      videofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteContentPageVideoFile = /* GraphQL */ `
  mutation DeleteContentPageVideoFile(
    $input: DeleteContentPageVideoFileInput!
    $condition: ModelContentPageVideoFileConditionInput
  ) {
    deleteContentPageVideoFile(input: $input, condition: $condition) {
      id
      contentpageID
      videofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      contentpage {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPlaylistImageFile = /* GraphQL */ `
  mutation CreatePlaylistImageFile(
    $input: CreatePlaylistImageFileInput!
    $condition: ModelPlaylistImageFileConditionInput
  ) {
    createPlaylistImageFile(input: $input, condition: $condition) {
      id
      playlistID
      imagefileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      imagefile {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updatePlaylistImageFile = /* GraphQL */ `
  mutation UpdatePlaylistImageFile(
    $input: UpdatePlaylistImageFileInput!
    $condition: ModelPlaylistImageFileConditionInput
  ) {
    updatePlaylistImageFile(input: $input, condition: $condition) {
      id
      playlistID
      imagefileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      imagefile {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deletePlaylistImageFile = /* GraphQL */ `
  mutation DeletePlaylistImageFile(
    $input: DeletePlaylistImageFileInput!
    $condition: ModelPlaylistImageFileConditionInput
  ) {
    deletePlaylistImageFile(input: $input, condition: $condition) {
      id
      playlistID
      imagefileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      imagefile {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPlaylistVideoFile = /* GraphQL */ `
  mutation CreatePlaylistVideoFile(
    $input: CreatePlaylistVideoFileInput!
    $condition: ModelPlaylistVideoFileConditionInput
  ) {
    createPlaylistVideoFile(input: $input, condition: $condition) {
      id
      playlistID
      videofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updatePlaylistVideoFile = /* GraphQL */ `
  mutation UpdatePlaylistVideoFile(
    $input: UpdatePlaylistVideoFileInput!
    $condition: ModelPlaylistVideoFileConditionInput
  ) {
    updatePlaylistVideoFile(input: $input, condition: $condition) {
      id
      playlistID
      videofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deletePlaylistVideoFile = /* GraphQL */ `
  mutation DeletePlaylistVideoFile(
    $input: DeletePlaylistVideoFileInput!
    $condition: ModelPlaylistVideoFileConditionInput
  ) {
    deletePlaylistVideoFile(input: $input, condition: $condition) {
      id
      playlistID
      videofileID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      videofile {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;
