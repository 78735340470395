
import React from "react";

import {
  required,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  ImageInput,
  TextInput,
  DateField,
  BulkDeleteButton
} from 'react-admin';

import {S3RecordFileProvider} from "../../components/Storage/S3FileProvider";
import S3FileNameField from "../../components/Storage/S3FileNameField";
import { transformFileAttachment } from "../../dataprovider/datastoreProvider"
import { splitStorageId } from "../../dataprovider/datastoreProvider";
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import { LocalisationField, LocalisationInput } from "../../components/Associations/LocalisationField";
import { DetailsPanel, DetailsDateField, DetailsImageField, DetailsFileNameField } from "../../components/DetailsPanel";
import useEditViewStyles from "../../themes/EditViewStyles";
import useListViewStyles from "../../themes/ListViewStyles";
import {  ImagePlaceholder } from "../../components/Fields/FileDropField"
import { hasValidImageDimensions } from "./imageValidation";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";

const MediaFilesTitle = ({ record }) => {

  const getImageName = (remoteFileName) => {
    const [id, fullName] = splitStorageId(remoteFileName);
    return fullName.split('.').slice(0, -1).join('.');
  }

  return <span>
    Images - {getImageName(record.imageStorageKey)}
  </span>;
};

const ImageFileFilters = [
  <TextInput
    label="Search file names"
    source="imageStorageKey.contains"
    alwaysOn
    key='searchFilter'
  />
];


const ImageFileActionButtons = props => (
  <>
    <BulkDeleteButton
      {...props}
      undoable={false}
    />
  </>
);

const validateForm = async (formValues) => {
  const {_imageStorageKey} = formValues;
  let error = {};
  if(_imageStorageKey){

    const {rawFile} = _imageStorageKey;
    const maxDimensions = {width:1920, height:1080};
    const {isValid:dimensionsValid, dimensions} = await hasValidImageDimensions(rawFile, maxDimensions);
    if(! dimensionsValid) {
      error._imageStorageKey = `Image exceeds max. dimensions: ${dimensions.width}x${dimensions.height}px (allowed max.: ${maxDimensions.width}x${maxDimensions.height}px)`
    }

    const maxByteSize = 2.5 * 1048576;
    if( rawFile.size > maxByteSize){
      error._imageStorageKey = `Image exceeds max. size: ${(rawFile.size /1048576).toPrecision(3)}MB (allowed max.: ${(maxByteSize/1048576).toPrecision(2)}MB)`
    }
  }
  return error;
}

export const ImageFileList = (props) => {

  const styles = useListViewStyles();
  return (

    <List
      {...props}
      exporter={false}
      filters={ImageFileFilters}
      bulkActionButtons={<ImageFileActionButtons />}
      pagination={<DefaultPagination/>}
      perPage={PerPageDefaults.min}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <S3RecordFileProvider label={""} source="imageStorageKey" destination="_image" appendRecord sortable={false}>
          <ImageField source="_image" classes={styles} />
        </S3RecordFileProvider>
        <S3FileNameField label="File name" source="imageStorageKey" />
        <LocalisationField source='ImageFileLanguages' sortable={false}/>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )
};

export const ImageFileEdit = props => {

  const transform = record => transformFileAttachment(record, ['_imageStorageKey'], { level: 'public' });
  const styles = useEditViewStyles();

  return (

    <Edit
      title={<MediaFilesTitle />}
      {...props}
      transform={transform}
      actions={false}
      mutationMode='pessimistic'
      aside={<ImageFileDetails/>}
      classes={{ card: styles.minHeightCard }}
    >

      <SimpleForm validate={validateForm} toolbar={<SaveCancelToolbar />} >
        <LocalisationInput source='ImageFileLanguages' />
        <ImageInput
          label='Upload new image'
          source='_imageStorageKey'
          accept='image/png,.jpg'
          placeholder={<ImagePlaceholder />}
        >
          <ImageField source='_imageStorageKey' />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )

};


export const ImageFileCreate = (props) => {

  const transform = record => transformFileAttachment(record, ['_imageStorageKey'], { level: 'public' }); 

  return (
    <Create
      {...props}
      transform={transform}
    >
      <SimpleForm validate={validateForm} redirect="list" toolbar={<SaveCancelToolbar />}>
        <LocalisationInput source='ImageFileLanguages' />
        <ImageInput
          label='Upload image'
          source='_imageStorageKey'
          accept='image/png,.jpg'
          validate={required()}          
          placeholder={<ImagePlaceholder />}
        >
          <ImageField source='_imageStorageKey' />
        </ImageInput>
      </SimpleForm>
    </Create>

  );
}


const ImageFileDetails = () => (
  <DetailsPanel size="large">
    <DetailsImageField label="Image" source="imageStorageKey" />
    <DetailsFileNameField label="Image file name" source="imageStorageKey" />
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)






