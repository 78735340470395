import React from "react";
import * as _ from "lodash";
import { Typography} from "@material-ui/core";

import { useRecordContext } from "ra-core";
import { useDetailPanelStyles } from "../../themes/DetailsPanelStyles"

export const DetailsDateField = (props) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  const toLocalDateString = (value) => {
    const date = value instanceof Date ? value : new Date(value);
    return date.toLocaleString()
  }

  return (
    <>
      <Typography
        className={styles.label}
        variant="caption"
      >
        {props.label}
      </Typography>
      <Typography
        className={styles.field}
        variant="body2"
      >
        { record ? toLocalDateString(_.get(record, props.source)) : ""}
      </Typography>
    </>
  )
}

export default DetailsDateField
