import  React from "react";
import get from 'lodash/get';
import { useFormState } from 'react-final-form';
import { Labeled } from 'react-admin';

/**
 * Displayss the Value of a Form field as TextField
 * 
 * @param {*} props 
 * @param {string} props.label the displayed label
 * @param {string} props.source the data source field  
 * @param {Function} props.transform (optional) function transforming th source value, must return string
 * @returns {React.Component} a labeld TextField containing the Form Value
 */
const FormTextField = props => {
    const { values } = useFormState();

    const renderText = () =>{
        const value = props.source ? get(values,props.source) : null;
        if(props.transform) return props.transform(value);
        return value;
    }

    return (
        <div>
            <Labeled label={props.label ?? "Label"}>
            <span>
                {renderText()}
            </span>
            </Labeled>
        </div>
    );
}

export default FormTextField;