import {transformFileAttachment} from "../../dataprovider/datastoreProvider"
import dataProviderCommands from "./dataProviderCommands";
import prettyoutput from 'prettyoutput';

const documentPickerOptions = 
{
    types: [ { description: 'Documents', accept: { 'application/pdf': ['.pdf'] } }, ],
    excludeAcceptAllOption: true,
    multiple: false
};

const documentFileCommands = {
    create: {
        description: 'create an documentFile from a local image',
        usage: 'create -> choose file from ImagePicker',
        fn: async (terminal) =>{
            try
            {   
                let [fileHandle] = await window.showOpenFilePicker(documentPickerOptions);
                const fileData = await fileHandle.getFile();

                const data = transformFileAttachment({_documentStorageKey : {rawFile: fileData}}, ['_documentStorageKey'], {level: 'public'});
                const result = await terminal.dataProvider.create('DocumentFile', {data: data});

                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list DocumentFiles',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "DocumentFile",page,perPage)
    },
    get: {
        description: 'get DocumentFile by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"DocumentFile",id)
    },
    delete: {
        description: 'delete DocumentFile by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"DocumentFile",id)
    },
    listlang:
    {
        description: 'list language associations for a documentFile',
        usage: 'listlang <documentFileId>',
        fn: async (terminal, imageId) => {
            try
            {
                const {data:documentFileLanguageList} = await terminal.dataProvider.getList('DocumentFileLanguage', {});
                const documentFileLanguages = documentFileLanguageList.filter(ifl => ifl.documentfile.id === imageId);
                terminal.log(prettyoutput(documentFileLanguages));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addlang:
    {
        description: 'associate an DocumentFile with a language.',
        usage: 'addlang <documentFileId> <languageId>',
        fn: async (terminal, imageId, languageId) => 
        {
            try
            {
                const {data:documentfile} = await terminal.dataProvider.getOne('DocumentFile', {id:imageId});
                const {data:language} = await terminal.dataProvider.getOne('Language',  {id:languageId});
                const data = {
                    documentfile: documentfile,
                    language: language
                }
                const result = await terminal.dataProvider.create('DocumentFileLanguage', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remlang: 
    {
        description: 'remove a language association',
        usage: 'remlang <documentFileLanguageId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"DocumentFileLanguage",id)
    }
}

export default documentFileCommands;