import dataProviderCommands from "./dataProviderCommands";
import prettyoutput from 'prettyoutput';

const playListCommands = {
    create: {
        description: 'create a playlist',
        usage: 'create <playlistName>',
        fn: async (terminal, playlistName) =>{
            try{
                let data ={ name:playlistName}
                const result = await terminal.dataProvider.create('Playlist', {data: data});
                terminal.log(prettyoutput(result));
                return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list DocumentFiles',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "Playlist",page,perPage)
    },
    get: {
        description: 'get DocumentFile by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"Playlist",id)
    },
    delete: {
        description: 'delete DocumentFile by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"Playlist",id)
    },
    listvideos:
    {
        description: 'list playlist videos',
        usage: 'listvideos <playlistId>',
        fn: async (terminal, playlistId) => {
            try
            {
                const {data:playlistVideoList} = await terminal.dataProvider.getList('PlaylistVideoFile', {});
                const playlistVideos = playlistVideoList.filter(pvl => pvl.playlist.id === playlistId);
                terminal.log(prettyoutput(playlistVideos));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addvideo:
    {
        description: 'add a video to the playlist',
        usage: 'addvideo <playlistId> <videoId>',
        fn: async (terminal, playlistId, videoId) => 
        {
            try
            {
                const {data:playlist} = await terminal.dataProvider.getOne('Playlist', {id:playlistId});
                const {data:video} = await terminal.dataProvider.getOne('VideoFile',  {id:videoId});
                const data = {
                    playlist: playlist,
                    videofile: video
                }
                const result = await terminal.dataProvider.create('PlaylistVideoFile', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remvideo: 
    {
        description: 'remove a video playlist association',
        usage: 'remvideo <playlistVideoFileId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"PlaylistVideoFile",id)
    },
    listimage:
    {
        description: 'list playlist images',
        usage: 'listimage <playlistId>',
        fn: async (terminal, playlistId) => {
            try
            {
                const {data:playlistImageList} = await terminal.dataProvider.getList('PlaylistImageFile', {});
                const playlistImages = playlistImageList.filter(pil => pil.playlist.id === playlistId);
                terminal.log(prettyoutput(playlistImages));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addimage:
    {
        description: 'add a image to the playlist',
        usage: 'addimage <playlistId> <imageFileId>',
        fn: async (terminal, playlistId, imageId) => 
        {
            try
            {
                const {data:playlist} = await terminal.dataProvider.getOne('Playlist', {id:playlistId});
                const {data:image} = await terminal.dataProvider.getOne('ImageFile',  {id:imageId});
                const data = {
                    playlist: playlist,
                    imagefile: image
                }
                const result = await terminal.dataProvider.create('PlaylistImageFile', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remimage: 
    {
        description: 'remove a image playlist association',
        usage: 'remimage <playlistImageFileId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"PlaylistImageFile",id)
    }
}

export default playListCommands;