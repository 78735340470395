import prettyoutput from 'prettyoutput';

const jsonPickerOptions = 
{
    types: [ { description: 'Json', accept: { 'application/json': ['.json'] } }, ],
    excludeAcceptAllOption: true,
    multiple: false
};

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader(); 
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    })
  }


const dataProviderCommands = {
    list: {  
        description: 'queries a resource and lists the result',
        usage: 'list <resource> <page> <perPage>',
        fn: async (terminal, resource, page, perPage) => {        
            page = page || 1;
            perPage = perPage || 10
            let params = { pagination: { page: page, perPage: perPage }, sort: { field: 'id', order: 'ASC' }, filter: {} }
            try
            {                     
                const result = await terminal.dataProvider.getList(resource, params);
                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    get: {  
        description: 'queries a resource for one item',
        usage: 'list <resource> <id>',
        fn: async (terminal, resource, id ) => {

            const data ={ id:id }
            try{
                const result = await terminal.dataProvider.getOne(resource, data);
                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    delete: {
        description: 'deletes the item form the resource',
        usage: 'delete <resource> <id>',
        fn: async (terminal, resource, id ) => {

            const data = { id:id }
            try{
                const result = await terminal.dataProvider.delete(resource, data);
                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    create: {
        description: 'create one or mored itemasfrom a json file',
        usage: 'create <resource> -> then select json file',
        fn: async (terminal, resource) =>{

            const createItem = async (resource, data) =>{
                const result = await terminal.dataProvider.create(resource, {data: data});
                terminal.log(prettyoutput(result));
            }

            try{
                let [jsonFileHandle] = await window.showOpenFilePicker(jsonPickerOptions);
                const jsonFile = await jsonFileHandle.getFile();
                const jsonText = await readFileAsync(jsonFile);
                const json = JSON.parse(jsonText);

                if(Array.isArray(json))
                {
                    await Promise.all(json.map(async (item) => {
                       await createItem(resource, {data:item})
                      }));
                }
                else{
                    await createItem(resource, {data: json});
                }
                return;
                
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    }
}

export default dataProviderCommands