import React  from "react";

import {
  List,
  Datagrid,
  Edit,
  TextField,
  DateField,
  TextInput
} from 'react-admin';

import { FormWithRedirect } from "ra-core";
import { DetailsDateField, DetailsPanel } from "../../components/DetailsPanel";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";
import TourEditor from "./tourEditor"

const TourTitle = ({ record }) => {
  return <span>
    Tour - {record.origin}
  </span>;
};

const TourFilters = [
  <TextInput
    label="Search name"
    source="origin.contains"
    alwaysOn
    key='searchFilter'
  />
];

export const TourList = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      filters={TourFilters}
      bulkActionButtons={false}
      pagination={<DefaultPagination/>}
      perPage={PerPageDefaults.default}
      sort={{ field: 'origin', order: 'ASC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField source="origin"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List >
  )
};

export const TourEdit = props => {

  return (
    <Edit
      {...props}
      title={<TourTitle />}
      actions={false}
      mutationMode='pessimistic'
      aside={<TourDetails />}     
    >
      <FormWithRedirect
        resource="Tour"
        render={({
          handleSubmitWithRedirect,
          pristine,
          saving,
          record
        }) => (
          <TourEditor
            record={record}  
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            pristine={pristine}
            saving={saving}
          />
        )}
      />
    </Edit>
  )

};

const TourDetails = () => (
  <DetailsPanel size="small">
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)
