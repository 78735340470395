import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { Storage } from "@aws-amplify/storage";
import { HttpError } from "react-admin";
import { Logger } from "aws-amplify";
import * as _ from "lodash";

import * as models from "../models";
import { schema } from "../models/schema";
import * as uuid from "uuid";

import { ConnectionTypes, ConnectionMap } from "./typeConnectionMap";
import { Hub } from "@aws-amplify/core";

export const attachStorageId = (filename, id) => {
  const [name, extension] = splitFileExtension(filename);
  if (!id || !uuid.validate(id)) id = uuid.v4();
  return `${name}_${id}.${extension}`;
};

export const splitStorageId = (filename) => {
  const [name, extension] = splitFileExtension(filename);

  const tokens = name.split("_");
  const [id] = tokens.slice(-1);
  const fullname = [tokens.slice(0, -1).join("_"), extension].join(".");

  if (uuid.validate(id)) return [id, fullname];
  else return [null, fullname];
};

export const transformFileAttachment = (
  record,
  sourcFields,
  storageOptions
) => {
  let { ...data } = record;
  let _files = {};

  if (!storageOptions) {
    storageOptions = {
      level: "protected",
    };
  }

  sourcFields.forEach((src) => {
    // this is required since, when a user adds
    // an image and then removes it from the dropzone
    // the pristine state of the form gets out of sync
    if (!data[src]) {
      delete data[src];
      return;
    }

    let file = data[src];
    let key = src.slice(1);
    let { ...options } = storageOptions;
    options.contentType = file.rawFile.type;
    _files[key] = {
      file: file,
      storageOptions: options,
    };
    delete data[src];
  });
  if (Object.keys(_files).length > 0) data._files = _files;

  return data;
};

const splitFileExtension = (filename) => {
  const tokens = filename.split(".");
  const [extension] = tokens.slice(-1);
  const name = tokens.slice(0, -1).join(".");
  return [name, extension];
};

export default class DatastoreProvider {
  constructor() {
    this.sortOrderMap = {
      ASC: SortDirection.ASCENDING,
      DESC: SortDirection.DESCENDING,
    };

    this.logger = new Logger("DataProvider");
    this.dataStoreReady = false;
    this.pendingQueries = [];
    this.initialize();
  }

  async initialize() {
    this.logger.debug('Initializing...');
    this.unsubAuthEvents = this.handleAuthenticationEvents();
    this.unsubStoreEvents = this.handleDataStoreEvents();
    this.logger.debug('Subscribed to Hub events.');
    await DataStore.clear();
    this.logger.debug('Cleared datastore.');
    await DataStore.start();
    this.logger.debug('Started datastore.');
    this.logger.debug('Initialisation completed.');
  }

  handleAuthenticationEvents() {
    return Hub.listen("auth", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      if (event === "signIn") {
        this.logger.debug('Starting DataStore on SignIn.');
        this.dataStoreReady = false;
        this.pendingQueries = [];
        await DataStore.clear();
        this.logger.debug('Cleared datastore.');
        await DataStore.start();
        this.logger.debug('Started datastore.');
      }

      if (event === "signOut") {
        this.logger.debug('Clearing DataStore on SignOut.');
        await DataStore.clear();
        this.logger.debug('Cleared datastore.');
      }
    });
  }

  handleDataStoreEvents() {
    return Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      if (event === "ready") {
        this.logger.debug('DataStore ready.');
        this.dataStoreReady = true;
        this.pendingQueries.forEach((query) => query.resolve());
      }
    });
  }

  waitForDataStore() {
    const readyPromise = new Promise((resolve, reject) => {
      this.pendingQueries.push({ resolve: resolve, reject: reject });
    });
    return readyPromise;
  }
  // resource:
  // name of the model to query
  // currently a pluralized name is expected

  // params:
  // {
  //     "pagination": {
  //         "page": 1,           // page to return starts at 1
  //         "perPage": 10        // entities per page to return
  //     },
  //     "sort": {
  //         "field": "createdAt",    // object field to sort by
  //         "order": "ASC"           // sort order ASCending or DESCending
  //     },
  //     "filter": {
  //         "name": "tem"       // key->field to filter by value-> value to filter by
  //     }
  // }

  async getList(resource, params) {
    this.logRequest("getList", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const resourceModel = models[resource];
    if (resourceModel === undefined) {
      throw new HttpError(
        "Could not determin model of resource: " + resource,
        404
      );
    }

    try {
      const { filter, sort } = params;

      const predicate = this.buildQueryPredicate(filter);
      const sorting = this.buildQuerySorting(sort);
      const queryResult = await DataStore.query(
        resourceModel,
        predicate,
        sorting
      );

      // sorting on a string field should sort caseinsensitive, which the datastore doesn't
      const sortFieldType = _.get(
        schema,
        `models.${resource}.fields.${sort.field}.type`,
        undefined
      );
      if (sortFieldType === "String")
        this.sortCaseInsensitive(queryResult, sort);

      // Datastore won't return total count; will query all and paginate maually; no big deal since queries hit local db
      const pagedResults = this.paginateResults(queryResult, params.pagination);

      // add list of ids of nested objects from many-to-many relations
      const appendedResults = await this.addConnectedAsLists(
        resource,
        pagedResults
      );

      const result = { data: appendedResults, total: queryResult.length };
      this.logResult("getList", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.query failed\n" + error);
      throw new HttpError(
        'DataStore query on resource "' + resource + '" failed.',
        500
      );
    }
  }

  async addConnectedAsLists(resource, records) {
    if (!this.dataStoreReady) await this.waitForDataStore();

    if (!_.has(ConnectionMap, resource)) return records;
    const fieldnfos = ConnectionMap[resource];
    const joinObjects = await this.getConnectionObjects(fieldnfos);
    const appededRecords = records.map((record) => {
      const nested = _.fromPairs(
        _.toPairs(fieldnfos).map((entry) => {
          const [name, refs] = entry;
          const ids = joinObjects[name]
            .filter((jo) => jo[refs.self].id === record.id)
            .map((jo) => jo[refs.other].id);
          return [name, ids];
        })
      );
      return { ...nested, ...record };
    });
    return appededRecords;
  }

  async updateConnections(resource, record, connections) {
    if (!this.dataStoreReady) await this.waitForDataStore();

    if (_.isEmpty(connections)) return;

    const fieldnfos = ConnectionMap[resource];
    const joinObjects = await this.getConnectionObjects(fieldnfos);

    let removed = [];
    let added = [];

    _.toPairs(fieldnfos).forEach((nfo) => {
      const [fieldname, refs] = nfo;
      if (_.has(connections, fieldname)) {
        // filter relevant connections
        const connected = joinObjects[fieldname].filter(
          (jo) => jo[refs.self].id === record.id
        );

        // find obsolete connections
        const updatedOtherIds = connections[fieldname];
        const obsolete = connected.filter(
          (jo) => !updatedOtherIds.includes(jo[refs.other].id)
        );
        const obsoleteIds = obsolete.map((jo) => jo.id);
        if (!_.isEmpty(obsolete))
          removed.push({
            resource: refs.resource,
            ids: obsoleteIds,
          });

        // find new connections
        const currOtherIds = connected.map((jo) => jo[refs.other].id);
        const newOthersIds = updatedOtherIds.filter(
          (id) => !currOtherIds.includes(id)
        );
        const [otherResource] = _.keys(ConnectionTypes[refs.resource]).filter(
          (r) => r != resource
        );
        if (!_.isEmpty(newOthersIds))
          added.push({
            connectionResource: refs.resource,
            otherResource: otherResource,
            otherIds: newOthersIds,
            otherField: refs.other,
            selfField: refs.self,
          });
      }
    });

    // remove obsolete connections
    const removeConnections = async (toRemove) => {
      const { resource, ids } = toRemove;
      const resourceModel = models[resource];

      const obsoleteInstances = await Promise.all(
        ids.map((id) => DataStore.query(resourceModel, id))
      );
      obsoleteInstances.forEach((oi) => DataStore.delete(oi));
    };
    await Promise.all(removed.map((rc) => removeConnections(rc)));

    // create new connections
    const createConnection = async (self, toCreate) => {
      const {
        connectionResource,
        otherResource,
        otherIds,
        otherField,
        selfField,
      } = toCreate;
      const otherModel = models[otherResource];
      const connectionModel = models[connectionResource];
      const others = await Promise.all(
        otherIds.map((id) => DataStore.query(otherModel, id))
      );
      const newConnections = others
        .map((other) =>
          _.fromPairs([
            [otherField, other],
            [selfField, self],
          ])
        )
        .map((params) => new connectionModel(params));
      await Promise.all(newConnections.map((nc) => DataStore.save(nc)));
    };

    const selfModel = models[resource];
    const self = await DataStore.query(selfModel, record.id);
    await Promise.all(added.map((ac) => createConnection(self, ac)));
  }

  async getConnectionObjects(fields) {
    if (!this.dataStoreReady) await this.waitForDataStore();
    let entries = [];
    for (const entry of _.toPairs(fields)) {
      const [name, refs] = entry;
      const model = models[refs.resource];
      // sorting allows to show connected objects in the order they were added
      const objects = await DataStore.query(model, Predicates.ALL, {
        sort: o => o.createdAt(SortDirection.DESCENDING)
      });
      entries.push([name, objects]);
    }
    return _.fromPairs(entries);
  }

  detachConnectedLists(resource, record) {
    if (!_.has(ConnectionMap, resource)) return [record, {}];
    const reffields = _.keys(ConnectionMap[resource]);
    return [_.omit(record, reffields), _.pick(record, reffields)];
  }

  async getOne(resource, params) {
    if (!this.dataStoreReady) await this.waitForDataStore();
    this.logRequest("getOne", resource, params); //DEBUG

    const resourceModel = models[resource];
    if (resourceModel === undefined) {
      throw new HttpError(
        "Could not determin model of resource: " + resource,
        404
      );
    }

    try {
      const { id, shallow } = params;
      const queryResult = await DataStore.query(resourceModel, id);
      if (queryResult === undefined)
        throw new HttpError(
          `Item with id: ${id} not found in ${resource}.`,
          404
        );

      let result = { data: queryResult };

      if (!shallow) {
        // add list of ids of nested objects from many-to-many relations
        const [appendedResults] = await this.addConnectedAsLists(resource, [
          queryResult,
        ]);
        result = { data: appendedResults };
      }

      this.logResult("getOne", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.query failed\n" + error);
      throw new HttpError(
        'DataStore query on resource "' + resource + '" failed.',
        500
      );
    }
  }

  async getMany(resource, params) {
    this.logRequest("getMany", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const queries = params.ids.map((id) =>
      this.getOne(resource, { id: id, shallow: true })
    );
    try {
      const queryResult = await Promise.all(queries);
      const result = { data: queryResult.map((qr) => qr.data) };
      this.logResult("getMany", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.query failed\n" + error);
      throw new HttpError(
        'DataStore query on resource "' + resource + '" failed.',
        500
      );
    }
  }

  // {
  //     "target": "Order.productID",
  //     "id": "1db4ad5a-bd50-46da-99a9-b3792f4906e5",
  //     "pagination": {
  //         "page": 1,
  //         "perPage": 10
  //     },
  //     "sort": {
  //         "field": "id",
  //         "order": "DESC"
  //     },
  //     "filter": {}
  // }

  async getManyReference(resource, params) {
    this.logRequest("getManyReference", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();
    const { pagination, sort, filter, target, ...referenceValue } = params;
    filter[target] = { eq: Object.values(referenceValue)[0] };

    try {
      const queryResult = await this.getList(resource, {
        pagination,
        sort,
        filter,
      });
      this.logResult("getManyByReference", resource, params, queryResult); //DEBUG
      return queryResult;
    } catch (error) {
      this.logger.error("DataStore.getList failed\n" + error);
      throw new HttpError(
        'DataStore get list of resource "' + resource + '" failed.',
        500
      );
    }
  }

  async update(resource, params) {
    this.logRequest("update", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const resourceModel = models[resource];
    if (resourceModel === undefined) {
      throw new HttpError(
        "Could not determin model of resource: " + resource,
        404
      );
    }

    const { _files, ...data } = params.data;
    const [shallowRecord, connectionLists] = this.detachConnectedLists(
      resource,
      data
    );

    const s3FileKeys = _files ? await this.uploadFiles(_files) : {};

    let updatedInstance = {};
    try {
      const record = await this.getOne(resource, {
        id: params.id,
        shallow: true,
      });
      updatedInstance = resourceModel.copyOf(record.data, (updated) => {
        Object.assign(updated, { ...shallowRecord, ...s3FileKeys });
      });
    } catch (error) {
      this.logger.error("DataStore.update failed\n" + error);
      throw new HttpError(
        'DataStore update of resource "' + resource + '" failed.',
        500
      );
    }

    try {
      const savedRecord = await DataStore.save(updatedInstance);
      await this.updateConnections(resource, savedRecord, connectionLists);

      const result = { data: savedRecord };
      this.logResult("update", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.update failed\n" + error);
      throw new HttpError(
        'DataStore update of resource "' + resource + '" failed.',
        500
      );
    }
  }

  async updateMany(resource, params) {
    console.warn("Dataprovider.updateMany is yet untested.");

    this.logRequest("updateMany", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const queries = params.ids.map((id) =>
      this.update(resource, { id: id, data: params.data, previousData: {} })
    );

    let requestResult = {};
    try {
      requestResult = await Promise.all(queries);
      const result = { data: requestResult.map((qr) => qr.data.id) };
      this.logResult("updateMany", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.update on updateMany failed\n" + error);
      throw new HttpError(
        'DataStore update on resource "' + resource + '" failed.',
        500
      );
    }
  }

  // parameters:
  // (resource: string): the name of the datamodel to store the data with
  // (params:object): a object with a subset of the keys of the datamodel
  // note:
  //    the '_files' object contains rawfiles associated with keys which are present on the data object
  //    the dataprovider will upload the rawfile and store the file key in the respective field
  //    e.g.
  //    {
  //      s3key : '',
  //      _files:
  //          {
  //              s3Key: {... rawFile}
  //          }
  //    }

  async create(resource, params) {
    this.logRequest("create", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const resourceModel = models[resource];
    if (resourceModel === undefined) {
      throw new HttpError(
        "Could not determin model of resource: " + resource,
        403
      );
    }

    const { _files, ...data } = params.data;
    const [record, connectionLists] = this.detachConnectedLists(resource, data);

    const s3FileKeys = _files ? await this.uploadFiles(_files) : {};
    const instance = new resourceModel({ ...record, ...s3FileKeys });

    try {
      const datastoreresult = await DataStore.save(instance);
      await this.updateConnections(resource, instance, connectionLists);
      const result = { data: instance };
      this.logResult("create", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.save failed\n" + error);
      throw new HttpError(
        'DataStore save instance of resource "' + resource + '" failed.',
        500
      );
    }
  }

  // will return an object with the s3keys assigend to the keyFields as provided by the filemap
  async uploadFiles(filemap) {
    let uploadFile = async (keyField, data) => {
      const {
        file: { rawFile },
        storageOptions,
      } = data;
      const remotename = attachStorageId(rawFile.name);
      try {
        const remoteFileName = await Storage.put(
          remotename,
          rawFile,
          storageOptions
        );
        return [keyField, remoteFileName.key];
      } catch (error) {
        throw new HttpError("Failed to upload image: " + rawFile.name);
      }
    };

    const result = await Promise.all(
      Object.entries(filemap).map((e) => uploadFile(...e))
    );
    const fileFields = Object.fromEntries(new Map(result));

    return fileFields;
  }

  async delete(resource, params) {
    this.logRequest("delete", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const resourceModel = models[resource];
    if (resourceModel === undefined) {
      throw new HttpError(
        "Could not determin model of resource: " + resource,
        403
      );
    }

    let currentInstance = {};

    try {
      currentInstance = await DataStore.query(resourceModel, params.id);
      if (currentInstance === undefined)
        throw new HttpError(
          `Item with id: ${params.id} not found in ${resource}.`,
          404
        );
    } catch (error) {
      this.logger.error("DataStore.delete failed\n" + error);
      throw new HttpError(
        'DataStore query instance of resource "' + resource + '" failed.',
        500
      );
    }

    try {
      const deletedInstance = await DataStore.delete(currentInstance);
      const result = { data: deletedInstance };
      this.logResult("delete", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.delete failed\n" + error);
      throw new HttpError(
        'DataStore delete instance of resource "' + resource + '" failed.',
        500
      );
    }
  }

  async deleteMany(resource, params) {
    this.logRequest("deleteMany", resource, params); //DEBUG
    if (!this.dataStoreReady) await this.waitForDataStore();

    const deleteRequests = params.ids.map(
      (id) => this.delete (resource, { id: id })
    );

    try {
      const requestResults = await Promise.all(deleteRequests);

      const result = {
        data: requestResults.map((requestResult) => requestResult.data.id),
      };
      this.logResult("deleteMany", resource, params, result); //DEBUG
      return result;
    } catch (error) {
      this.logger.error("DataStore.delete failed\n" + error);
      throw new HttpError(
        'DataStore delete instances of resource "' + resource + '" failed.',
        500
      );
    }
  }

  paginateResults(results, pagination) {
    if (!pagination || _.isEmpty(pagination)) return results;

    let { page, perPage } = pagination;
    const total = results.length;
    const start = Math.max(0, --page * perPage);
    const end = Math.min(total, start + perPage);
    return results.slice(start, end);
  }

  buildQuerySorting(sort) {
    if (!sort || _.isEmpty(sort)) return null;
    return { sort: (s) => s[sort.field](this.sortOrderMap[sort.order]) };
  }

  buildQueryPredicate(filter) {
    if (!filter || _.isEmpty(filter)) return null;

    // pl = predicateList
    const pl = Object.entries(filter).map((entry) => {
      const [field, predicateTerm] = entry;
      const [predicate, value] = Object.entries(predicateTerm)[0];
      return { field, predicate, value };
    });

    switch (pl.length) {
      case 1:
        return (c) => c[pl[0].field](pl[0].predicate, pl[0].value);
      case 2:
        return (c) =>
          c[pl[0].field](pl[0].predicate, pl[0].value)[pl[1].field]( pl[1].predicate, pl[1].value);
      case 3:
        return (c) =>
          c[pl[0].field](pl[0].predicate, pl[0].value) [pl[1].field](pl[1].predicate, pl[1].value) [pl[2].field](pl[2].predicate, pl[2].value);
      case 4:
        return (c) =>
          c[pl[0].field](pl[0].predicate, pl[0].value) [pl[1].field](pl[1].predicate, pl[1].value) [pl[2].field](pl[2].predicate, pl[2].value) [pl[3].field](pl[3].predicate, pl[3].value);
      case 5:
        return (c) =>
          c[pl[0].field](pl[0].predicate, pl[0].value) [pl[1].field](pl[1].predicate, pl[1].value) [pl[2].field](pl[2].predicate, pl[2].value) [pl[3].field](pl[3].predicate, pl[3].value) [pl[4].field](pl[4].predicate, pl[4].value);
      case 6:
        return (c) =>
          c[pl[0].field](pl[0].predicate, pl[0].value) [pl[1].field](pl[1].predicate, pl[1].value) [pl[2].field](pl[2].predicate, pl[2].value) [pl[3].field](pl[3].predicate, pl[3].value) [pl[4].field](pl[4].predicate, pl[4].value) [pl[5].field](pl[5].predicate, pl[5].value);
      default:
        return Predicates.ALL;
    }
  }

  sortCaseInsensitive(queryResult, sort) {
    queryResult.sort((a, b) => {
      const valueA = a[sort.field].toLowerCase();
      const valueB = b[sort.field].toLowerCase();
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      if (a[sort.field] < b[sort.field]) return -1;
      if (a[sort.field] > b[sort.field]) return 1;
      return 0;
    });
    if (sort.order === "DESC") queryResult.reverse();
  }

  // for development only -> remove
  logRequest(method, resource, params) {
    this.logger.debug(
      "DataProvider." + method + " invoked with:",
      "resource: ",
      JSON.parse(JSON.stringify(resource)),
      "params: ",
      JSON.parse(JSON.stringify(params ?? {}))
    );
  }

  // for development only -> remove
  logResult(method, resource, params, result) {
    this.logger.debug(
      "DataProvider." + method + " executed with:",
      "resource: ",
      JSON.parse(JSON.stringify(resource)),
      "params: ",
      JSON.parse(JSON.stringify(params ?? {})),
      "returned: ",
      JSON.parse(JSON.stringify(result))
    );
  }
}

