import React from "react";
import { Route } from 'react-router-dom';
import FHLTerminal from "../components/Terminal/Terminal";
import LegalText from "../components/LegalText/Legal";

const Routes = () =>{

    const default_routes = [
        <Route exact path="/imprint" component={LegalText} key='legalTextRoute'/>
    ];

    // eslint-disable-next-line no-undef
    if(process.env.NODE_ENV === 'development'){
        return [ <Route exact path="/terminal" component={FHLTerminal} key='terminalRoute'/>, ...default_routes]
    }
    else return default_routes;
}

export default Routes();

