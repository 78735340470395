
import React from "react";

import {
  required,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  DateField,
  BulkDeleteButton,
  ReferenceField,
  DateInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';


import { transformFileAttachment } from "../../dataprovider/datastoreProvider"
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import { LocalisationField, LocalisationInput } from "../../components/Associations/LocalisationField";

import { DetailsPanel, DetailsDateField } from "../../components/DetailsPanel";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";


const AnnouncementTitle = ({ record }) => {

  return <span>
    Announcement - {record.text}
  </span>;
};

const AnnouncementFilters = [
  <TextInput
    label="Search text"
    source="text.contains"
    alwaysOn
    key='searchFilter'
  />
];

const AnnouncementActionButtons = props => (
  <>
    <BulkDeleteButton
      {...props}
      undoable={false}
    />
  </>
);

const validateInterval = (record) => {
  const errors = {};

  const startDate = new Date(record.startDate);
  const endDate = new Date(record.endDate);

  if (startDate > endDate) {
    errors.startDate = {
      message: 'start date must lie before end date',
      min: endDate
    }
  }

  return errors;
}

export const AnnouncementList = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      filters={AnnouncementFilters}
      bulkActionButtons={<AnnouncementActionButtons />}
      pagination={<DefaultPagination/>}  
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={PerPageDefaults.min}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField source="text" />
        <ReferenceField label="Page" source="contentpageID" reference="ContentPage" link={false}>
          <TextField source="uniqueName" />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
        {/* <UrlField source="url" /> */}
        <LocalisationField />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )
};

export const AnnouncementEdit = props => {

  return (

    <Edit
      title={<AnnouncementTitle />}
      {...props}
      actions={false}
      mutationMode='pessimistic'
      aside={<AnnouncementDetails />}
    >
      <SimpleForm validate={validateInterval} toolbar={<SaveCancelToolbar />}>
        <ReferenceInput 
          perPage={1000}
          sort={{
            field:'uniqueName',
            order:'ASC'
          }}
          filter={{
            uniqueName: {
              contains: 'Übersicht'
            }
          }}
          label="Page" 
          source="contentpageID" 
          reference="ContentPage" 
          variant="standard"
        >
          <SelectInput optionText="uniqueName"/>
        </ReferenceInput>
        <LocalisationInput validate={required()}/>
        <TextInput source="text" variant="standard" />
        <TextInput source="url" variant="standard" />
        <DateInput source="startDate" variant="standard" />
        <DateInput source="endDate" variant="standard" />
      </SimpleForm>
    </Edit>
  )

};


export const AnnouncementCreate = (props) => {

  return (
    <Create
      {...props}
      validate={validateInterval}
    >
      <SimpleForm redirect="list" validate={validateInterval} toolbar={<SaveCancelToolbar />}>
        <ReferenceInput 
          perPage={1000}
          sort={{
            field:'uniqueName',
            order:'ASC'
          }}
          filter={{
            uniqueName: {
              contains: 'Übersicht'
            }
          }}
          label="Page" 
          validate={required()} 
          source="contentpageID" 
          reference="ContentPage" 
          variant="standard"
        >
          <SelectInput optionText="uniqueName"/>
        </ReferenceInput>
        <LocalisationInput validate={required()}/>
        <TextInput source="text" validate={required()} variant="standard" />
        <TextInput source="url" variant="standard" />
        <DateInput source="startDate" variant="standard" validate={required()} />
        <DateInput source="endDate" variant="standard" validate={required()} />
      </SimpleForm>
    </Create>
  );
}

const AnnouncementDetails = () => (
  <DetailsPanel>
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)




