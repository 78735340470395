import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useAuthorized from './useAuthorized';
import useSignOut from './useSignOut';


const UnauthorizedDialog = () => {

  const isAuthorized = useAuthorized();
  const signOut = useSignOut();

  return (
    <>
      <Dialog     
        open={!isAuthorized}
        keepMounted
        aria-labelledby="unauthorized-dialog-slide-title"
        aria-describedby="unauthorized-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">You are not authorized.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"You signed in successfully - you don't have admin priviliges though."}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            {"Please ask an admin to include you into the admin group. Once you have been enabled as admin you will need to sign in again."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>        
            <Button onClick={signOut} color="primary">
              Signout for now
            </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default UnauthorizedDialog 