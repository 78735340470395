import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import useHeuristicReturnPath from './useHeuristicReturnPath';

const HeuristicReturnButton = (props) => {

  const { origins, defaultPath } = props;
  const returnPath = useHeuristicReturnPath(origins, defaultPath);

  return (
    <Button
      component={Link}
      to={returnPath}
      style={{ marginBottom: '1em' }}
      color='primary'
      variant="text"
      size="small"
      startIcon={<KeyboardBackspaceIcon />}
    >
      RETURN
    </Button> 
  );
}

export default HeuristicReturnButton

