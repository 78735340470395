import React, { useState } from 'react'
import { Amplify } from 'aws-amplify';

import {
   Authenticator, 
   useAuthenticator, 
   CheckboxField, 
   Link,
   Text
  } from '@aws-amplify/ui-react';
import TermsAndConditions from './components/Authentication/AuthTermsAndConditions';

import ControlPanel from './resources/Main';
import awsExports from "./aws-exports";


 import "./themes/AuthenticatorStyles.css"

Amplify.configure(awsExports);
const LogType = {
  debug: "DEBUG",
  error: "ERROR",
  info: "INFO",
  warn: "WARN",
  verbose: "VERBOSE"
}

// eslint-disable-next-line no-undef
if(process.env.NODE_ENV === 'development'){
    Amplify.Logger.LOG_LEVEL = LogType.debug;
}

const App = () => {

  const [showTerms, setShowTerms] = useState(false);
  const [uiTab, setUiTab] = useState('signIn');

  const readTerms = () => {
    setShowTerms(true);
    setUiTab('signUp');
  }

  const returnToAuth = () => {
    setShowTerms(false);
  }

  const passwordPolicy = "Passwords need to be at least 8 characters long and include a uppercase letter, a lowercase letter, a number and a special character."

  return (
      showTerms ?
      <TermsAndConditions returnToAuth={returnToAuth}/>
      :
      <Authenticator
        initialState={uiTab}
        variation="modal"
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();
              return (
                <>
                  <Authenticator.SignUp.FormFields />
                  <Text variation="tertiary">{passwordPolicy}</Text>
                  <CheckboxField
                    errorMessage={validationErrors.acknowledgement}
                    hasError={!!validationErrors.acknowledgement}
                    name="acknowledgement"
                    value="yes"
                    label={<OptIn readTerms={readTerms} />}
                  />
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: 'You must agree to the Terms & Conditions',
              };
            }
          },
        }}
      >
        {() => <ControlPanel />}
      </Authenticator>
  );
}

const OptIn = (props) => {
  const { readTerms } = props;
  return (
    <>
      <span>Accept</span>
      <Link onClick={() => { readTerms() }}> Terms and Conditions</Link>
    </>
  );
}



export default App;
