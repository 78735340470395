// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import * as _ from 'lodash';

const useAuthorized = () =>{
    const {user} = useAuthenticator();
    const userGroups = _.get(user, "signInUserSession.accessToken.payload.cognito:groups", []);
    return userGroups.includes('admins');
}

export default useAuthorized;