import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography} from '@material-ui/core';
import { useLocation } from 'react-router-dom'

const linkStyles = makeStyles(theme => ({
  footer:{
    marginTop:'1.5em',
    maxWidth:'1080px',
    display:'flex',
    justifyContent:'center'
  }
}));

const LegalFooter = ({legalPath,hideOnPaths, ...props}) => {

  const location = useLocation();
  const styles = linkStyles();

  return (
    <div className={styles.footer} >
      <Link to={legalPath} hidden={hideOnPaths.includes(location.pathname)}>
        <Typography variant="caption"> Imprint - Privacy Policy - Legal</Typography>
      </Link>
    </div>
  )
}

export default LegalFooter