import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
// import indigo from '@material-ui/core/colors/indigo';
// import teal from '@material-ui/core/colors/teal';
// import pink from '@material-ui/core/colors/pink';
// import red from '@material-ui/core/colors/red';

const MainTheme = merge({}, defaultTheme, {
  palette: {
    // primary: teal,
    // secondary: pink,
    // error: red,
    // contrastThreshold: 3,
    // tonalOffset: 0.2,
  },
  shape:
  {
    // borderRadius:4
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    RaLayout: {
      content: {

      }
    },
    RaDatagrid: {
      table: {
        tableLayout: "fixed",
      }
    },
    RaEdit: {
      // main: {
      //   justifyContent:"center"
      // },
      card: {
        maxWidth: "1080px"
      }
    },
    RaShow: {
      main: {
        maxWidth: "1080px"
      }
    },
    RaList: {
      root: {
        maxWidth: "1080px"
      },

    },
    RaCreate: {
      card: {
        maxWidth: "1080px"
      }
    },
    RaFormInput: {
      input: {
        width: "320px"
      }
    },
    MuiTypography: {
      body2: {
        wordWrap: "break-word"
      }
    }
  },
});

// the plan:
/*
  RaLayout:{
    content: {
    --main - width: 1280px;
    padding - left: calc((100 % - var(--main - width)) /2);
    padding - right: calc((100 % - var(--main - width)) /2);
    }
  },
 RaDatagrid:{
  table:{
    table-layout: fixed;
  } 
}
*/
export default MainTheme;