
import React from 'react'

import { Box, Typography } from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import TheatersIcon from '@material-ui/icons/Theaters';
import DescriptionIcon from '@material-ui/icons/Description';

import { makeStyles } from '@material-ui/core';

export const useDropFieldStyles = makeStyles(theme => ({
    Placeholder: {  
        display: "inline-flex",
        flexDirection: "column",
        alignContent: "space-around",
        alignItems: "center",
        margin: "4px"
    },
    Icon: {
        display: "inline-flex",
        color:"#d7d7d7",
        fontSize: "3em",
        marginBottom: "8px"
    },
    Text: {
        color: "#9F9F9F"
    }

}));

export const DocumentPlaceholder = () =>{

    const styles = useDropFieldStyles();

    return (
        <Box className={styles.Placeholder}>
            <div className={styles.Icon}>
                <DescriptionIcon fontSize="inherit" />
            </div>
            <Typography className={styles.Text} variant="body2">
                Drop a document or click and select it. 
            </Typography>
        </Box>
    )
}


export const VideoPlaceholder = () =>{

    const styles = useDropFieldStyles();

    return (
        <Box className={styles.Placeholder}>
            <div className={styles.Icon}>
                <TheatersIcon fontSize="inherit" />
            </div>
            <Typography className={styles.Text} variant="body2">
                Drop a video or click and select it. 
            </Typography>
        </Box>
    )
}

export const ImagePlaceholder = () =>{

    const styles = useDropFieldStyles();

    return (
        <Box className={styles.Placeholder}>
            <div className={styles.Icon}>
                <InsertPhotoIcon fontSize="inherit" />
            </div>
            <Typography className={styles.Text} variant="body2">
                Drop an image or click and select it. 
            </Typography>
        </Box>
    )
}