  import React from 'react'
  import { SvgIcon } from '@material-ui/core'

  export const LogoutIcon = (props) => {
    return (
      <SvgIcon {...props}>
      <path d="M0,0h24v24H0V0z" fill="none" />
      <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
    </SvgIcon>
  )
}

export const TourIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.766,6.781H13.14L7.977,18.734h2.391l.873-2.391h5.427l.873,2.391H19.93L14.766,6.781m-2.845,7.77,2.032-5.379,2.032,5.379H11.921m8.487,9.443,1.913,1.913H16.344V19.93l2.391,2.391a9.727,9.727,0,0,0,4.781-8.367A9.563,9.563,0,1,0,9.172,22.225V24.9a11.967,11.967,0,1,1,11.236-.908Z"
        transform="translate(-2 -2)"
      ></path>
    </SvgIcon>
  )
}

