import React from "react";
import { Typography } from "@material-ui/core";

import { useRecordContext } from "ra-core";
import { useDetailPanelStyles } from "../../themes/DetailsPanelStyles"
import {S3RecordFileProvider} from "../Storage/S3FileProvider"

export const DetailsImageField = (props) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  return (
    <>
      <Typography
        className={styles.label}
        variant="caption"
      >
        {props.label}
      </Typography>

      {record && <S3RecordFileProvider
        record={record}
        source={props.source}
        destination="src"
      >
        <img className={styles.image}/>
      </S3RecordFileProvider>}
    </>
  )
}

export default DetailsImageField
