
import React from "react";
import * as _ from 'lodash';

import {
    Select, 
    FormControl, 
    MenuItem, 
    InputLabel,
} from "@material-ui/core";

import {useEditorStyles } from "../../themes/EditorStyles";

const LocaleSelection = (props) =>{

    const styles = useEditorStyles();
    const {localisations, selectedLocale, onSelectLocale, allowNone} = props;
  
    const renderChoices = (localisations) =>
    {
      const nullChoice = <MenuItem key="none" value=''><em>None</em></MenuItem>
      const choices = _.values(localisations).map(l =>
        <MenuItem key={l.id} value={l.id}>{l.displayName}</MenuItem>
        );
      if(allowNone) return [nullChoice,...choices];
      return choices;
    }

    const onSelectionChanged = (event) =>{
        onSelectLocale(event.target.value);
    }
  
    return (
      <div className={styles.field}>
        <FormControl className={styles.localeSelection}>    
          <InputLabel id="select-locale">Localized view</InputLabel>
          <Select
            labelId="select-locale"
            id="select-locale"
            value={selectedLocale}
            onChange={onSelectionChanged}          
          >
            {renderChoices(localisations)}
          </Select>
        </FormControl>
      </div>
    )
  }
  LocaleSelection.displayName = "LocaleSelection";

  export default LocaleSelection;