import React, { memo} from "react";
import { Link } from 'react-router-dom';
import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {
  List,
  Datagrid,
  TextField,
  ReferenceManyField,
  SimpleShowLayout,
  Show,
  TextInput,
  DeleteButton,
  DateField,
  useGetOne,
} from 'react-admin';


import { TranslationKeyReturnPaths } from "../../components/Navigation/NavigationPaths";
import HeuristicReturnButton from "../../components/Navigation/HeuristicReturnButton";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";

const TranslationKeysTitle = ({ record }) => {
  return <span>
    Translations - {record.key}
  </span>;
};


const CreateTranslationButton = (props) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: '/Translation/create',
        state: {
          record: { translationkeyID: props.record.id }
        },
      }}
      size='small'
      startIcon={<AddIcon />}
      color='primary'
    >
      ADD TRANSLATION
    </Button>
  );
}


const TranslationKeyFilters = [
  <TextInput
    label="Search keys"
    source="key.contains"
    alwaysOn
    key='searchFilter'
    parse={(v) => {
      v = v.toUpperCase();
      v = v.replace(" ", "_");
      return v;
    }
    }
  />
]


const LanguageNameField = ({ record, ...props }) => {
  const { data, loading, error } = useGetOne('Language', record.languageID);
  if (loading || error) return null;
  return (<TextField {...props} record={data} source="displayName" />);
}


export const TranslationKeysShow = (props) => {
  return (
    <Show
      {...props}
      title={<TranslationKeysTitle />}
    >
      <SimpleShowLayout>
        <HeuristicReturnButton {...TranslationKeyReturnPaths} />
        <TextField source='key' label='Translation key' />
        <DateField source='createdAt' label='Created at' />
        <ReferenceManyField
          fullWidth
          sort={{ field: 'languageID', order: 'DESC' }}
          label="Translations"
          reference="Translation"
          target="translationkeyID" >
          <Datagrid
            rowClick="edit"
          >
            <LanguageNameField label='Language' />
            {/* using component="pre" on the TextField will retain the linebreaks, won't hav a maxwidth thuough */}
            <TextField label="Translation text" source="value" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />

            <DeleteTranslationButton />
          </Datagrid>
        </ReferenceManyField>
        <CreateTranslationButton />
      </SimpleShowLayout>
    </Show >
  );
}

const DeleteTranslationButton = memo((props) => {

  const languageQuery = useGetOne("Language", props.record.languageID)
  const confirmTitle = "Delte Translation";
  const confirmContent = `Are you sure you want to remove the ${languageQuery.data?.displayName} translation?`;
  return (
    <DeleteButton
      {...props}
      undoable={false}
      label="Delete"
      confirmContent={confirmContent}
      confirmTitle={confirmTitle}
      redirect={false}
    />
  )
});
DeleteTranslationButton.displayName = "DeleteTranslationButton";



export const TranslationKeysList = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      hasCreate={false}
      exporter={false}
      filters={TranslationKeyFilters}
      pagination={<DefaultPagination/>}
      sort={{ field: 'key', order: 'ASC' }}
      perPage={PerPageDefaults.default}
    >
      <Datagrid rowClick="show">
        <TextField source="key" label="Translation key" />
      </Datagrid>
    </List >
  )
};
