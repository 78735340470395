import prettyoutput from 'prettyoutput';
import { Auth } from 'aws-amplify';

const authCommands = {
    signout: {
        description: 'signout',
        usage: 'signout <optional:global>',
        fn: async(terminal, global) =>{

            const globalLogout = global ? true : false;
            try {
                await Auth.signOut({global:globalLogout});
                terminal.log('signed out sucessfully');
            } catch (error) {
                console.error(error);
                return prettyoutput(error.message);
            }
            return;
        }
    },  
    signin: {
        description: 'sign in',
        usage: 'signin <username> <password>' ,
        fn: async(terminal, username, password) =>{

            if(username === 'postman') {
                username = 'postman@solidwhite.de';
                password = '6&c.+jD"P72k';
            }

            try {
                const user = await Auth.signIn(username, password);
                terminal.log('signed in as user:');
                terminal.log(prettyoutput(user));
            } catch (error) {
                console.error(error);
                return prettyoutput(error.message);
            }
            return;
        }
    },  
    curusr: {
        description: 'information about the current user',
        usage: 'curusr' ,
        fn: async(terminal, username, password) =>{
            try {
                const user = await Auth.currentAuthenticatedUser({bypassCache: true});
                terminal.log(prettyoutput(user));
            } catch (error) {
                console.error(error);
                return prettyoutput(error.message);
            }
            return;
        }
    }
}

export default authCommands;