import React from 'react';
import { Pagination } from 'react-admin';

const paginationOptions = [10,20,40];

const DefaultPagination = props => <Pagination rowsPerPageOptions={paginationOptions} {...props} />;

export const PerPageDefaults = {
    min: paginationOptions[0],
    default: paginationOptions[1],
    max: paginationOptions[2]
}


export default DefaultPagination;