import {buildDataProvider} from "react-admin-amplify";
import  DataStoreProvider from './datastoreProvider';
import  CognitoProvider from './cognitoProvider';

export function buildCustomDataProvider(operations, options) {

    const reactAdminProvider = buildDataProvider(operations,options);
    const datastoreProvider = new DataStoreProvider();

    let resourceProviderMap = new Map()
        .set('default', datastoreProvider)
        .set('cognitoGroups', reactAdminProvider)
        .set('cognitoUsers', {
            getList: reactAdminProvider.getList,
            getOne:  reactAdminProvider.getOne,        
            getMany: reactAdminProvider.getMany,           
            getManyReference: reactAdminProvider.getManyReference,
            update: reactAdminProvider.update,         
            updateMany: reactAdminProvider.updateMany,         
            create: reactAdminProvider.create,             
            delete: CognitoProvider.delete, // reactAdmin doesn't implement delete :(    
            deleteMany: reactAdminProvider.deleteMany            
        })        
        ;

    let provider = new ProviderSwitch(resourceProviderMap);
    return provider;
}

class ProviderSwitch
{
    constructor(providerMap){
        this.providerMap = providerMap;
    }

    resolveProvider  = (resource) => this.providerMap.get(resource) ?? this.providerMap.get('default');
    getList          = (resource, params) => this.resolveProvider(resource).getList(resource, params);
    getOne           = (resource, params) => this.resolveProvider(resource).getOne(resource, params);
    getMany          = (resource, params) => this.resolveProvider(resource).getMany(resource, params);
    getManyReference = (resource, params) => this.resolveProvider(resource).getManyReference(resource, params);
    update           = (resource, params) => this.resolveProvider(resource).update(resource, params);
    updateMany       = (resource, params) => this.resolveProvider(resource).updateMany(resource, params);
    create           = (resource, params) => this.resolveProvider(resource).create(resource, params);
    delete           = (resource, params) => this.resolveProvider(resource).delete(resource, params);
    deleteMany       = (resource, params) => this.resolveProvider(resource).deleteMany(resource, params);
}
