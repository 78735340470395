
import React from "react";
import {
  required,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  DateField,
  BulkDeleteButton,
  UrlField
} from 'react-admin';

import { LocalisationField, LocalisationInput } from "../../components/Associations/LocalisationField";
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import { DetailsPanel, DetailsDateField} from "../../components/DetailsPanel"
import useEditViewStyles from "../../themes/EditViewStyles";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";
import {validateUrl} from "../../components/Fields/UrlValidation";

const BadgeTitle = ({ record }) => {

  return <span>
    Badge - {record.text}
  </span>;
};

const BadgeFilters = [
  <TextInput
    label="Search text"
    source="text.contains"
    alwaysOn
    key='searchFilter'
  />
];

const BadgeActionButtons = props => (
  <>
    <BulkDeleteButton
      {...props}
      undoable={false}
    />
  </>
);

export const BadgeList = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      filters={BadgeFilters}
      bulkActionButtons={<BadgeActionButtons />}
      pagination={<DefaultPagination/>}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={PerPageDefaults.min}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField source="text" />
        <UrlField source="url" />
        <LocalisationField />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )
};

export const BadgeEdit = props => {

  const styles = useEditViewStyles(props);

  return (
    <Edit
      title={<BadgeTitle />}
      {...props}
      actions={false}
      mutationMode='pessimistic'
      aside={<BadgeDetails />}
      classes ={{card:styles.minHeightCard}}
    >
      <SimpleForm toolbar={<SaveCancelToolbar />}>
        <LocalisationInput />
        <TextInput source="text" validate={required()}  variant="standard" />
        <TextInput source="url"  validate={validateUrl} variant="standard" fullWidth/>
      </SimpleForm>
    </Edit>
  )

};


export const BadgeCreate = (props) => {

  return (
    <Create
      {...props}
    >
      <SimpleForm redirect="list" toolbar={<SaveCancelToolbar />}>
        <LocalisationInput />
        <TextInput source="text" validate={required()}  variant="standard" />
        <TextInput source="url"  validate={validateUrl} variant="standard" fullWidth/>
      </SimpleForm>
    </Create>

  );
}

const BadgeDetails = () => (
  <DetailsPanel>
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)



