import * as React from "react";

import {
  required,
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  DateField,
  ImageField,
  ImageInput,
  email,
  BulkDeleteButton
} from 'react-admin';


import { transformFileAttachment } from "../../dataprovider/datastoreProvider"
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import { ImagePlaceholder } from "../../components/Fields/FileDropField";
import {S3RecordFileProvider} from "../../components/Storage/S3FileProvider";
import { Avatar } from "@material-ui/core";
import {DetailsPanel, DetailsDateField, DetailsProfilePicture, DetailsFileNameField} from "../../components/DetailsPanel/index";
import {hasValidImageDimensions} from '../media/imageValidation'
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";


const ContactTitle = ({ record }) => {

  return <span>
    Contact Person - {record.name}
  </span>;

};

const ContactFileActionButtons = props => (
  <>
    <BulkDeleteButton
      {...props}
      undoable={false}
    />
  </>
);

const ContactFilters = [
  <TextInput
    label="Search names"
    source="name.contains"
    alwaysOn
    key='searchFilter'
  />
];


const validateForm = async (formValues) => {
  const {_imageStorageKey} = formValues;
  let error = {};
  if(_imageStorageKey){

    const {rawFile} = _imageStorageKey;
    const maxDimensions = {width:600, height:600};
    const {isValid:dimensionsValid, dimensions} = await hasValidImageDimensions(rawFile, maxDimensions);
    if(! dimensionsValid) {
      error._imageStorageKey = `Profile picture exceeds max. dimensions: ${dimensions.width}x${dimensions.height}px (allowed max.: ${maxDimensions.width}x${maxDimensions.height}px)`
    }

    const maxByteSize = 500 * 1024;
    if( rawFile.size > maxByteSize){
      error._imageStorageKey = `Profile picture exceeds max. size: ${(rawFile.size /1024).toPrecision(3)}KB (allowed max.: ${(maxByteSize/1024).toPrecision(3)}KB)`
    }
  }
  return error;
}


export const ContactPeopleList = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      filters={ContactFilters}
      bulkActionButtons={<ContactFileActionButtons />}
      pagination={<DefaultPagination/>}
      sort={{ field: 'email', order: 'ASC' }}
      perPage={PerPageDefaults.min}
    >
      <Datagrid
        rowClick="edit"
      >
        <S3RecordFileProvider label={""} source="imageStorageKey" destination="src">
          <Avatar/>      
        </S3RecordFileProvider>
        <TextField source="name"/>
        <TextField source="jobTitle" />
        <TextField source="email" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )

};


export const ContactPeopleEdit = (props) => {

  const transform = record => transformFileAttachment(record, ['_imageStorageKey'], { level: 'public' });

  return (
    <Edit
      title={<ContactTitle />}
      actions={false}
      transform={transform}
      mutationMode='pessimistic'
      aside={<ContactDetails/>}
      {...props}
    >
      <SimpleForm toolbar={<SaveCancelToolbar />} validate={validateForm}>
        <ImageInput
          label='Upload new profile picture'
          source='_imageStorageKey'
          accept='image/png,.jpg'
          placeholder={<ImagePlaceholder/>}
        >
          <ImageField source='_imageStorageKey'/>
        </ImageInput>
        <TextInput source="name"  variant="standard" validate={required()}/>
        <TextInput source="jobTitle"  variant="standard" />
        <TextInput source="email" validate={[required(), email()]}    variant="standard"/>
        <TextInput source="phone"  variant="standard" />
        <TextInput source="fax"   variant="standard"/>
      </SimpleForm>

    </Edit>
  )

};


export const ContactPeopleCreate = props => {

  const transform = record => transformFileAttachment(record, ['_imageStorageKey'], { level: 'public' });

  return (
    <Create
      {...props}
      undoable="false"
      transform={transform}
    >
      <SimpleForm redirect="list" toolbar={<SaveCancelToolbar />} validate={validateForm}>
        <TextInput source="name" validate={required()}  variant="standard"/>
        <ImageInput
          label='Upload profile picture'
          source='_imageStorageKey'
          accept='image/png,.jpg'
          validate={required()}
          placeholder={<ImagePlaceholder/>}
        >
          <ImageField source='_imageStorageKey'/>
        </ImageInput>
        <TextInput source="jobTitle"  variant="standard" />
        <TextInput source="email" validate={[required(), email()]} variant="standard"/>
        <TextInput source="phone"  variant="standard"/>
        <TextInput source="fax"  variant="standard"/>
      </SimpleForm>
    </Create >
  )
};

const ContactDetails = () =>(
  <DetailsPanel>
    <DetailsProfilePicture label="Profile picture" source="imageStorageKey" />
    <DetailsFileNameField label="File name" source="imageStorageKey" />
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)
