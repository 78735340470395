import prettyoutput from 'prettyoutput';
import dataProviderCommands from "./dataProviderCommands";

const translationKeysCommands = {
    create: {
        description: 'add a translation key',
        usage: 'create <key> ',
        fn: async(terminal, key) =>{
            try{
                let data ={ key:key }
                const result = await terminal.dataProvider.create('TranslationKey', {data: data});
                 terminal.log(prettyoutput(result));
                 return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list translation keys',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "TranslationKey",page,perPage)
    },
    get: {
        description: 'get translation key by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"TranslationKey",id)
    },
    delete: {
        description: 'delete translation key by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"TranslationKey",id)
    }
}

export default translationKeysCommands