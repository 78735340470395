import React from "react";
import {Card,CardContent} from "@material-ui/core";
import {useDetailPanelStyles} from "../../themes/DetailsPanelStyles"
import classnames from 'classnames';


const DetailsPanel = (props) => {

  const sizes=['small','large'];
  const styles = useDetailPanelStyles(props);

  return (
    <Card className={classnames({
        [styles.asideSmall]: props.size == 'small',
        [styles.asideLarge]: props.size == 'large',
        [styles.asideSmall]: !(props.size in sizes),
      })}>
      <CardContent>
        {props.children}
      </CardContent>
    </Card>
  )
}

export default DetailsPanel;