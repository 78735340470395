import React from "react";
import * as _ from "lodash";
import { Typography} from "@material-ui/core";

import { useRecordContext } from "ra-core";
import { useDetailPanelStyles } from "../../themes/DetailsPanelStyles"
import { splitStorageId } from "../../dataprovider/datastoreProvider"

export const DetailsFileNameField = (props) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  const [id,value] = record?splitStorageId(_.get(record, props.source)): ["",""];

  return (
    <>
      <Typography
        className={styles.label}
        variant="caption"
      >
        {props.label}
      </Typography>
      <Typography
        className={styles.field}
        variant="body2"
      >
        {value}
      </Typography>
    </>
  )
}

export default DetailsFileNameField
