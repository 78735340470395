
import React, { useState, useEffect } from "react";
import * as _ from 'lodash';


import {useGetList, useInput, useNotify} from "ra-core";
import {
  Typography, 
  Button, 
  TextField,
  FormControl, 
  Box, 
  CardContent
} from "@material-ui/core";

import { Autocomplete, createFilterOptions  } from "@material-ui/lab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { splitStorageId } from "../../dataprovider/datastoreProvider";
import { S3FileProvider } from "../../components/Storage/S3FileProvider";
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';

import {useEditorStyles, usePlaylistItemStyles} from "../../themes/EditorStyles";
import {PlaylistEditReturnPaths} from '../../components/Navigation/NavigationPaths'
import LocaleSelection from "../../components/EditorView/LocaleSelection";

const PlayListEditor = (props) => {

  const styles = useEditorStyles();
  const [locale, setLocale] = useState('');  //The ID of the selected language
  
  const localisations = useGetList("Language", {}, { field: 'displayName', order: 'ASC' });

  const onLocaleSelected = (locale) => {
    setLocale(locale);
  }

  return (
    <>
      <CardContent>
        <Typography className={styles.title} variant="h5">{props.record.name}</Typography>
        <LocaleSelection
          localisations = {
            localisations.data && !localisations.loading ? 
            _.values(localisations.data) : []
          } 
          selectedLocale = {locale}
          onSelectLocale = {onLocaleSelected}
          allowNone
        />
        <PlayListSequenceField locale={locale}/>
        <AddPlaylistItemControl/>
      </CardContent>
      <SaveCancelToolbar {...PlaylistEditReturnPaths} {...props}/>
    </>
  );

}
PlayListEditor.displayName = "PlaylistEditor";
 
const PlayListSequenceField = (props) =>{

  const styles = useEditorStyles();
  const notify = useNotify();

  const [mediaLookup, setMediaLookup] = useState(new Map()); // mapping all existing videos and images to their id

  const {input} = useInput({source:'sequence', ...props})
  const {data:images, loaded:imagesLoaded } = useGetList('ImageFile',{})
  const {data:videos, loaded:videosLoaded } = useGetList('VideoFile',{})

  useEffect( () =>{
    if(input && imagesLoaded && videosLoaded)
    {
      const lookup = buildMediaLookup(_.values(images),_.values(videos));
      setMediaLookup(lookup);

      const [updated, sanitizedSequence] = sanitizeSequence(input.value, lookup);
      if(updated)
      {
        console.log("sanitized");
        input.onChange(sanitizedSequence);
        notify("Videos or images were deleted.\nThe playlist has been updated.", { type: 'info' });
      }
    }
  },
  [images, videos, imagesLoaded, videosLoaded])

  const buildMediaLookup = (imageFiles, videoFiles) => {
    const lookup = new Map();
    imageFiles.forEach(img => lookup
      .set(img.id,
        {
          id:img.id, 
          thumbnailKey:img.imageStorageKey, 
          fileKey:img.imageStorageKey,
          title:titleFromKey(img.imageStorageKey),
          type:'image',
          localisations: img.ImageFileLanguages
        })
      );
    
    videoFiles.forEach(vdo => lookup
      .set(vdo.id,
        {
          id:vdo.id, 
          thumbnailKey:vdo.posterStorageKey,
          fileKey:vdo.videoStorageKey,
          title:vdo.title,
          type:'video',
          localisations: vdo.languages
        })
      );
    return lookup;
  }

  const sanitizeSequence = (sequence, mediaLookup) => {
    const sanitizedSequence = sequence.filter(id => mediaLookup.get(id));  
    const updated = sanitizedSequence.length != sequence.length;

    if(updated) {
      console.log("original:",sequence,"sanitized:",sanitizedSequence, updated);
    }
    return [updated, sanitizedSequence];
  }

  const rearangeList = (result) => {
    const {source, destination, id:draggableId} = result;
    const {value, onChange} = input;

    if(source && destination){
      if(source.index === destination.index) return;
      let newList = moveListItem(value,source.index,destination.index);
      onChange(newList);
    }
    return;
  }

  const moveListItem = (list,source,destination) =>
  {
    const item = list[source]; 
    const removed = [...list.slice(0,source), ...list.slice(source+1, list.length)];
    const inserted = [...removed.slice(0,destination),item, ...removed.slice(destination, removed.length)];
    return inserted;
  }

  const titleFromKey = (key) =>{
    let [id, filename] = splitStorageId(key);
    filename = filename.split('.').slice(0, -1).join('.'); // remove extension
    return filename.replace(/[^0-9a-zA-ZÄÖÜäöüß]+/gi, " ")        // remove special character
                    .replace(/([a-z](?=[A-Z]))/g, '$1 ');   // split camel case
  }

  return (
    <div className={styles.formControl}> 
      <Typography className={styles.label} variant="caption">Playlist</Typography>
      <DragDropContext onDragEnd={rearangeList}>
        <PlaylistContainer locale={props.locale} mediaLookup={mediaLookup}/>
      </DragDropContext>
    </div>
  )
}
PlayListSequenceField.displayName = "PlayListSequenceField"
  
const PlaylistContainer = (props) =>
{
  const styles = useEditorStyles();
  const {input} = useInput({source:'sequence', ...props})

  return (
    <Droppable droppableId="content-sequence">
      {(provided, snapshot) => (
        <div className={styles.playlistContainer} ref={provided.innerRef} {...provided.draggableProps}>
          {
            input.value
              .filter(id => props.mediaLookup.get(id))
              .map(id => props.mediaLookup.get(id))
              .map((item, index) =>
                (
                  <PlaylistItem key={item.id} item={item} index={index} locale={props.locale}/>
                ))
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
  
const PlaylistItem = (props) => {

  const styles = usePlaylistItemStyles();
  const {input:sequenceInput} = useInput({source:'sequence', ...props})
  const {item, index} = props;

  const removeItem = (event) => {
    const index = Number(event.target.value);
    const {value:sequence, onChange:onSequenceChange} = sequenceInput;
    const removed = [...sequence.slice(0,index), ...sequence.slice(index+1, sequence.length)];
    onSequenceChange(removed);
  }

  const getListItemStyle = (index, snapshot) =>{
    let itemStyle = [styles.item];
    if(!snapshot.isDragging){
      itemStyle.push(styles.itemTop)
      if(index == (sequenceInput.value.length-1)) itemStyle.push(styles.itemBottom)
    }
    return itemStyle.join(' ')
  }

  const getLocalisedStyle = (item, classes) =>
  {
    if(props.locale && !item.localisations.includes(props.locale)){
        classes.push(styles.disabled);     
    }
    return classes.join(' ');
  }
  
  const formatKey = (key) =>{
    const [id, filename] = splitStorageId(key);
    return filename;
  }

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div 
          className={getListItemStyle(index, snapshot)} 
          ref={provided.innerRef} 
          {...provided.dragHandleProps} 
          {...provided.draggableProps}
        >
          <div className={styles.itemContent} >
            <DragIndicatorIcon className={styles.dragIndicator}/>
            <div className={styles.thumbnail}>
              <S3FileProvider s3key={item.thumbnailKey}>
                  <img className={getLocalisedStyle(item,[styles.image])}/>        
              </S3FileProvider>
              {item.type === 'video' && <LocalMoviesIcon className={styles.overlayIcon}/>}
              {item.type === 'image' && <ImageIcon className={styles.overlayIcon}/>}
            </div>
            <div>
              <Typography
                variant="body2"
                className={getLocalisedStyle(item,[])}
              >
                {item.title}
              </Typography>
              <Typography 
                className={getLocalisedStyle(item,[styles.filename])} 
                variant="caption"
              >
                {formatKey(item.fileKey)}
              </Typography>
            </div>
            <Box flex="auto"/>
            <Button 
              className={styles.removeButton}
              onClick={removeItem} 
              value={index} 
              startIcon={<RemoveCircleIcon/>}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
    </Draggable>
  )
}
  
const AddPlaylistItemControl = (props) =>{

  const styles = useEditorStyles();

  const {input:sequenceInput} = useInput({source:'sequence', ...props});
  const {input:videoListInput} = useInput({source:'videoFiles', ...props});
  const {input:imageListInput} = useInput({source:'imageFiles', ...props});

  const [choices, setChoices] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [selectionIsEmpty, setSelectionIsEmpty] = useState(true);
  const [maxListSizeReached, setMaxListSizeReached] = useState(true);

  const {data:images, loaded:imagesLoaded } = useGetList('ImageFile', {});
  const {data:videos, loaded:videosLoaded } = useGetList('VideoFile', {});

  const formatKey = (key) =>{
    const [id, filename] = splitStorageId(key);
    return filename;
  }

  //limit options list to 100 entries;
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => 
    defaultFilterOptions(options, state).slice(0, 100);

  useEffect( () => 
  {
    if(imagesLoaded && videosLoaded)
    {
      const videoChoices = _.values(videos)
        .filter(v => !sequenceInput.value.includes(v.id))
        .map(v => {return {id: v.id, name: formatKey(v.videoStorageKey), type:'video'};});
      const imageChoices = _.values(images)
        .filter(v => !sequenceInput.value.includes(v.id))
        .map(i => {return {id: i.id, name: formatKey(i.imageStorageKey), type:'image'};});
      const choices = _.sortBy([...videoChoices, ...imageChoices], (c) => c.name);

      setChoices([null,...choices]);
    }
  }, 
  [sequenceInput.value, images, videos, imagesLoaded, videosLoaded]);
  

  useEffect( () => {
    setMaxListSizeReached( sequenceInput.value.length > 100);
  },
  [sequenceInput.value]);
  
  const addSelection = (event) =>{
    const addToInputList = (input, value) =>  input.onChange([...input.value, value])
    if(autoCompleteValue && autoCompleteValue.id)
    {
      // add to video list    
      if(autoCompleteValue.type === 'video') addToInputList(videoListInput, autoCompleteValue.id);      
      // add to image list      
      if(autoCompleteValue.type === 'image') addToInputList(imageListInput, autoCompleteValue.id);  
      addToInputList(sequenceInput, autoCompleteValue.id)
    }
    setAutoCompleteValue(null); 
    setSelectionIsEmpty(true);
  }

  const onSelectionChange = (event, value, reason) =>{
    if(_.isEmpty(value)){
      setAutoCompleteValue(null);
      setSelectionIsEmpty(true);
    }
    else{
      setAutoCompleteValue(value);
      setSelectionIsEmpty(false);
    }
  }

  const renderOption = (option, state) =>{
    return (
      option ? (
        <div className={styles.addOption}>
          {option.type === 'video' && <LocalMoviesIcon className={styles.optionIcon}/>}
          {option.type === 'image' && <ImageIcon className={styles.optionIcon}/>}
          <Box width="4px"/>
          <Typography variant="body1">{option.name}</Typography>
        </div> 
      ) : (
        <div className={styles.addOption}>
        <Typography variant="body1"><em>None</em></Typography>
        </div>
      )
    )
  }

  return (
    <div className={styles.field}>
      <FormControl className={[styles.formControl, styles.combinedControl].join(" ")}>  
        <Autocomplete
          id="add-media"
          options={choices}
          value={autoCompleteValue}
          filterOptions={filterOptions}
          onChange={onSelectionChange}
          getOptionLabel={(option) => _.isEmpty(option) ? "" : option.name}
          style={{ minWidth: 320 }}
          renderInput={(params) => <TextField {...params} label="Add media" variant="standard" />}
          renderOption={renderOption}
          disabled = {maxListSizeReached}
        />
        <Box width="8px"/>
        <Button 
          onClick={addSelection} 
          disabled={maxListSizeReached || selectionIsEmpty}
          startIcon={<AddIcon/>}
          color="primary"
        >
          Add
        </Button>
      </FormControl>
    </div>
  );
}

// will ensure that the playlist is only associated with
// videos and images in the playlist sequence before 
// the playlist is updated.
export const sanitizePlaylist = (record) => 
{
  record.videoFiles = record.videoFiles.filter(vf => record.sequence.includes(vf));
  record.imageFiles = record.imageFiles.filter(vf => record.sequence.includes(vf));
  return record;
}

export default PlayListEditor;