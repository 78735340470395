
export const isURL = (url) => {
	try {
		return !!new URL(url);
	} catch {
		return false;
	}
};

export const validateUrl = (value, form ) =>
{
	const message = 'must be an url'
	console.log('validateUrl', value, form);
	if(isURL(value)) return undefined;
	else return message;
};
    
