import React from "react";
import * as _ from "lodash";
import { Typography, Box} from "@material-ui/core";
import { useDetailPanelStyles } from "../../themes/DetailsPanelStyles"
import {S3RecordFileProvider} from "../../components/Storage/S3FileProvider";
import { useRecordContext } from "ra-core";
import { splitStorageId } from "../../dataprovider/datastoreProvider";


export const DetailsFileField = (props) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  return (
    <>
      <Typography
        className={styles.label}
        variant="caption"
      >
        {props.label}
      </Typography>
      {record && 
        <S3RecordFileProvider record={record} source={props.source} destination="_src">
          <FilePreviewLink record={record} source={props.source}/>
        </S3RecordFileProvider>
      }
    </>
  )
}

const FilePreviewLink = (props) =>{

  const [id,fileName] = splitStorageId(_.get(props.record, props.source));
  const styles = useDetailPanelStyles();

  return (
    <Box className={styles.field}>
      <a
        href={props._src}
        title={fileName}
        target="_blank"
        rel="noreferrer"
      >
        {fileName}
      </a>
    </Box>
  )
}

export default DetailsFileField
