import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Hub } from '@aws-amplify/core'

import { BroadcastChannel } from "broadcast-channel";
import { channels,events } from "./LogoutHandler";

const SessionExpiredDialog = () => {

  const [sessionExpired, setSessionExpired] = useState(false);
  const [authChannel, setAuthChannel] = useState();

  // channel to broadcast other tabs to signout aswell
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(channels.AUTH_CHANNEL);
    setAuthChannel(broadcastChannel);
    return async () => { await broadcastChannel.close(); };
  }, []);

  useEffect(() => {
    //console.log('SESSION WARNING token refresh failure received');
    const removeListener = Hub.listen("auth", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      if (event === "tokenRefresh_failure") {
        setSessionExpired(true);
      }
    });

    return async () => {
      removeListener();
    };
  }, []);

  // broadcast logout to other channels so they can logout at the same time
  const sendSignout = () => {
    authChannel.postMessage(events.auth.USER_SIGNOUT);
  };
  
  return (
    <>
      <Dialog
        open={sessionExpired}
        keepMounted
        aria-labelledby="sessionexpired-dialog-slide-title"
        aria-describedby="sessionexpired-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
              Session expired
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              'Your session has expired. Please sign in again.'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={sendSignout} color="primary" disableRipple={true}>
            return to sign in 
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default SessionExpiredDialog

