
import React, { useState, useEffect } from "react";
import * as _ from 'lodash';


import {useGetList, useInput, useNotify} from "ra-core";
import {
  Typography, 
  Button, 
  TextField,
  FormControl, 
  Box, 
  CardContent
} from "@material-ui/core";

import { Autocomplete, createFilterOptions  } from "@material-ui/lab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddIcon from '@material-ui/icons/Add';

import {useEditorStyles, usePlaylistItemStyles} from "../../themes/EditorStyles";
import {TourEditReturnPaths } from '../../components/Navigation/NavigationPaths'

const TourEditor = (props) => {

  const styles = useEditorStyles();

  return (
    <>
      <CardContent>
        <Typography className={styles.title} variant="h5">{props.record.origin}</Typography>
        <TourScheduleField/>
        <AddScheduleItemControl />
      </CardContent>
      <SaveCancelToolbar {...TourEditReturnPaths } {...props}/>
    </>
  );

}
TourEditor.displayName = "TourEditor";
 
const TourScheduleField = (props) =>{

  const styles = useEditorStyles();

  const {input} = useInput({source:'schedule', ...props})

  const rearangeList = (result) => {
    const {source, destination} = result;
    const {value, onChange} = input;

    if(source && destination){
      if(source.index === destination.index) return;
      let newList = moveListItem(value,source.index,destination.index);
      onChange(newList);
    }
    return;
  }

  const moveListItem = (list,source,destination) =>
  {
    const item = list[source]; 
    const removed = [...list.slice(0,source), ...list.slice(source+1, list.length)];
    const inserted = [...removed.slice(0,destination),item, ...removed.slice(destination, removed.length)];
    return inserted;
  }


  return (
    <div className={styles.formControl}> 
      <Typography className={styles.label} variant="caption">Tour</Typography>
      <DragDropContext onDragEnd={rearangeList}>
        <TourScheduleContainer/>
      </DragDropContext>
    </div>
  )
}
TourScheduleField.displayName = "TourScheduleField"
  
const TourScheduleContainer = (props) =>
{
  const styles = useEditorStyles();
  const {input} = useInput({source:'schedule', ...props})

  return (
    <Droppable droppableId="content-sequence">
      {(provided, snapshot) => (
        <div className={styles.playlistContainer} ref={provided.innerRef} {...provided.draggableProps}>
          {
            input.value
              .map((item, index) => ( <TourItem key={item} item={item} index={index}/>))
          }
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
  
const TourItem = (props) => {

  const styles = usePlaylistItemStyles();
  const {input} = useInput({source:'schedule', ...props})
  const {item, index} = props;

  const removeItem = (event) => {
    const index = Number(event.target.value);
    const {value:schedule, onChange:onScheduleChange} = input;
    const removed = [...schedule.slice(0,index), ...schedule.slice(index+1, schedule.length)];
    onScheduleChange(removed);
  }

  const getListItemStyle = (index, snapshot) =>{
    let itemStyle = [styles.item];
    if(!snapshot.isDragging){
      itemStyle.push(styles.itemTop)
      if(index == (input.value.length-1)) itemStyle.push(styles.itemBottom)
    }
    return itemStyle.join(' ')
  }

  return (
    <Draggable key={item} draggableId={item} index={index}>
      {(provided, snapshot) => (
        <div 
          className={getListItemStyle(index, snapshot)} 
          ref={provided.innerRef} 
          {...provided.dragHandleProps} 
          {...provided.draggableProps}
        >
          <div className={styles.itemContent} >
            <DragIndicatorIcon className={styles.dragIndicator}/>
            <div>
              <Typography
                variant="body2"
              >
                {item}
              </Typography>
            </div>
            <Box flex="auto"/>
            <Button 
              className={styles.removeButton}
              onClick={removeItem} 
              value={index} 
              startIcon={<RemoveCircleIcon/>}
            >
              Remove
            </Button>
          </div>
        </div>
      )}
    </Draggable>
  )
}

const AddScheduleItemControl = (props) =>{

  const styles = useEditorStyles();

  const {input} = useInput({source:'schedule', ...props});

  const [choices, setChoices] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [selectionIsEmpty, setSelectionIsEmpty] = useState(true);
  const [maxListSizeReached, setMaxListSizeReached] = useState(true);

  const {data:pages, loaded:pagesLoaded} = useGetList('ContentPage', {},{field:'uniqueName', order:'ASC'});

  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => 
    defaultFilterOptions(options, state).slice(0, 100);

  useEffect( () => 
  {
    if( pages && pagesLoaded)
    {
      console.log("TOUR", pages);
      const choices = _.values(pages).map(p => p.uniqueName);
      setChoices(["Übersicht",...choices]);
    }
  }, 
  [input.value, pages, pagesLoaded]);
  

  useEffect( () => {
    setMaxListSizeReached(input.value.length > 100);
  },
  [input.value]);
  
  const addSelection = (event) =>{
    const addToInputList = (input, value) =>  input.onChange([...input.value, value])
    if(autoCompleteValue)
    {
      addToInputList(input, autoCompleteValue)
    }
    setAutoCompleteValue(null); 
    setSelectionIsEmpty(true);
  }

  const onSelectionChange = (event, value, reason) =>{
    if(_.isEmpty(value)){
      setAutoCompleteValue(null);
      setSelectionIsEmpty(true);
    }
    else{
      setAutoCompleteValue(value);
      setSelectionIsEmpty(false);
    }
  }

  const renderOption = (option, state) =>{
    return (
      option ? (
        <div className={styles.addOption}>
          <Typography variant="body1">{option}</Typography>
        </div> 
      ) : (
        <div className={styles.addOption}>
        <Typography variant="body1"><em>None</em></Typography>
        </div>
      )
    )
  }

  return (
    <div className={styles.field}>
      <FormControl className={[styles.formControl, styles.combinedControl].join(" ")}>  
        <Autocomplete
          id="add-media"
          options={choices}
          value={autoCompleteValue}
          filterOptions={filterOptions}
          onChange={onSelectionChange}
          getOptionLabel={(option) => _.isEmpty(option) ? "" : option}
          style={{ minWidth: 320 }}
          renderInput={(params) => <TextField {...params} label="Add destination" variant="standard" />}
          renderOption={renderOption}
          disabled = {maxListSizeReached}
        />
        <Box width="8px"/>
        <Button 
          onClick={addSelection} 
          disabled={maxListSizeReached || selectionIsEmpty}
          startIcon={<AddIcon/>}
          color="primary"
        >
          Add
        </Button>
      </FormControl>
    </div>
  );
}

//// will ensure that the playlist is only associated with
//// videos and images in the playlist sequence before 
//// the playlist is updated.
//export const sanitizePlaylist = (record) => 
//{
  //record.videoFiles = record.videoFiles.filter(vf => record.sequence.includes(vf));
  //record.imageFiles = record.imageFiles.filter(vf => record.sequence.includes(vf));
  //return record;
//}

export default TourEditor;
