import prettyoutput from 'prettyoutput';
import dataProviderCommands from "./dataProviderCommands";

const badgeCommands = {
    create: {
        description: 'add a badge',
        usage: 'create <text> <url>',
        fn: async(terminal, text, url) =>{
            try{
                let data ={
                    value:text,
                    text:text,
                    url:url
                }
                const result = await terminal.dataProvider.create('Badge', {data: data});
                 terminal.log(prettyoutput(result));
                 return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list badges',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "Badge",page,perPage)
    },
    get: {
        description: 'get badge by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"Badge",id)
    },
    delete: {
        description: 'delete badge by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"Badge",id)
    },
    listlang:
    {
        description: 'list languages of a badge',
        usage: 'listlang <badgeId>',
        fn: async (terminal, badgeId) => {
            try
            {
                const {data:BadgeLanguageList} = await terminal.dataProvider.getList('BadgeLanguage', {});
                const BadgeLanguages = BadgeLanguageList.filter(bl => bl.badge.id === badgeId);
                terminal.log(prettyoutput(BadgeLanguages));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addlang:
    {
        description: 'associate an badge with a language.',
        usage: 'addlang <badgeId> <languageId>',
        fn: async (terminal, badgeId, languageId) => 
        {
            try
            {
                const {data:badge} = await terminal.dataProvider.getOne('Badge', {id:badgeId});
                const {data:language} = await terminal.dataProvider.getOne('Language',  {id:languageId});
                const data = {
                    badge: badge,
                    language: language
                }
                const result = await terminal.dataProvider.create('BadgeLanguage', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remlang: 
    {
        description: 'remove a language association',
        usage: 'remlang <BadgeLanguageId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"BadgeLanguage",id)
    }
}

export default badgeCommands