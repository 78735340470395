import React from "react";


import {Admin, Resource, Menu, MenuItemLink , Layout, AppBar  } from 'react-admin';


// Custom Resources
import { LanguageList, LanguageCreate} from "./internationalisation/languages";
import { TranslationKeysList, TranslationKeysShow } from "./internationalisation/translationKeys";
import { TranslationCreate, TranslationEdit } from "./internationalisation/translations";
import { ContactPeopleList, ContactPeopleCreate, ContactPeopleEdit } from "./contacts/contacts";
import { ImageFileList, ImageFileCreate,  ImageFileEdit  } from "./media/imageFile";
import { VideoFileList, VideoFileCreate, VideoFileEdit  } from "./media/videoFiles";
import { DocumentFileList, DocumentFileCreate, DocumentFileEdit } from "./media/documentFiles";
import { AnnouncementList, AnnouncementCreate, AnnouncementEdit} from "./announcements/announcements";
import { BadgeList, BadgeCreate, BadgeEdit } from "./badges/badges";
import { UserList } from "./users/users";
import { PlaylistList, PlaylistEdit } from "./media/playlists";
import { TourList, TourEdit } from './tour/tour'
import { ContentPageList, ContentPageEdit } from "./contentpage/contentPage";
import { Dashboard } from "./dashboard/dashboard";

// History 
import HistoryRecorder from "../components/Navigation/HistoryRecorder";

// Icons
import ImageIcon from '@material-ui/icons/Image';
import DescriptionIcon from '@material-ui/icons/Description';
import LanguageIcon from '@material-ui/icons/Language';
import TranslateIcon from '@material-ui/icons/Translate';
import TheatersIcon from '@material-ui/icons/Theaters';
import PeopleIcon from '@material-ui/icons/People';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import LabelIcon from '@material-ui/icons/Label';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { TourIcon } from "../icons/CustomIcons";

// Theme and Layout
import Theme from '../themes/MainTheme';
import { Box } from "@material-ui/core"; 

// Routes
import customRoutes from '../routes/customRoutes';
import LogoutButton from "../components/Authentication/LogoutButton";
import LegalFooter from '../components/LegalText/LegalFooter';

// Redux
import historyReducer from "../redux/reducer";

// API 
import {buildCustomDataProvider} from '../dataprovider/dataprovider';

import awsExports from "../aws-exports";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

// Authorization
import UnauthorizedDialog from "../components/Authentication/UnAuthWarning";
import LogoutHandler from "../components/Authentication/LogoutHandler";
import SessionExpiredDialog from "../components/Authentication/SessionExpiredWarning";
// import awsConfig from './aws-exports'
// import { AuthModeStrategyType } from 'aws-amplify';

//DEBUG
// Amplify.configure(
//   {
//     ...awsConfig,
//     DataStore: {
//       authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
//     }
//   }
// );

// configure dataprovider
const dataProvider = buildCustomDataProvider(
  {
    queries,
    mutations,
  },
  {
    storageBucket: awsExports.aws_user_files_s3_bucket,
    storageRegion: awsExports.aws_user_files_s3_bucket_region,
    enableAdminQueries: true,
  }
);


const ControlPanel = (props) => {

  return (
    <>
      <LogoutHandler/>
      <UnauthorizedDialog/>
      <SessionExpiredDialog/>
      <Admin
        customRoutes={customRoutes}
        customReducers={{"history": historyReducer}}
        dashboard={Dashboard}
        loginPage={false}
        dataProvider={dataProvider}
        theme={Theme}
        layout={AppLayout}
      >

        <HistoryRecorder/> 

        <Resource 
          name='ContentPage'
          list={ContentPageList}
          edit={ContentPageEdit}
        />
       <Resource
          name="Playlist"
          list={PlaylistList}
          edit={PlaylistEdit}
        />

        <Resource
          name="Tour"
          list={TourList}
          edit={TourEdit}
        />

        <Resource
          name="ImageFile"
          list={ImageFileList}
          create={ImageFileCreate}
          edit={ImageFileEdit}
        />

        <Resource
          name="VideoFile"
          list={VideoFileList}
          create={VideoFileCreate}
          edit={VideoFileEdit}
        />

        <Resource
          name="DocumentFile"
          list={DocumentFileList}
          create={DocumentFileCreate}
          edit={DocumentFileEdit}
        />

        <Resource
          name="Badge"
          list={BadgeList}
          create={BadgeCreate}
          edit={BadgeEdit}
        />

        <Resource
          name="Announcement"
          list={AnnouncementList}
          create={AnnouncementCreate}
          edit={AnnouncementEdit}
        />

        <Resource
          name="Contact"
          list={ContactPeopleList}
          create={ContactPeopleCreate}
          edit={ContactPeopleEdit}
        />

        <Resource
          name="Language"
          list={LanguageList}
          create={LanguageCreate}
        />

        <Resource
          name="TranslationKey"
          list={TranslationKeysList}
          show={TranslationKeysShow}
        />

        <Resource
          name="Translation"
          create={TranslationCreate}
          edit={TranslationEdit}
        /> 

        <Resource name="cognitoUsers"
          list={UserList}  
        />
        <Resource name="cognitoGroups"/> 
      </Admin>
    </>
  )
};

export const AppLayout = (props) => {

  return(
   <Layout {...props} menu={MenuDrawer} appBar={MinimalAppBar}>
     <div>{props.children}</div>
     <Box flex="auto" />
     <LegalFooter legalPath="/imprint" hideOnPaths={["/imprint"]}/>
    </Layout>
  );
}

const MinimalAppBar = props => <AppBar {...props} userMenu={false}/>;

const MenuDrawer = (props) => {

  return(
    <Menu {...props}>
      <MenuItemLink to="/ContentPage" primaryText="Pages" leftIcon={<ChromeReaderModeIcon />} />
      <MenuItemLink to="/Playlist" primaryText="Playlists" leftIcon={<SlideshowIcon />} />
      <MenuItemLink to="/Tour" primaryText="Tours" leftIcon={<TourIcon/>} />
      <MenuItemLink to="/ImageFile" primaryText="Images" leftIcon={<ImageIcon />} />
      <MenuItemLink to="/VideoFile" primaryText="Videos" leftIcon={<TheatersIcon />} />
      <MenuItemLink to="/DocumentFile" primaryText="Documents" leftIcon={<DescriptionIcon />} />
      <MenuItemLink to="/Badge" primaryText="Badges" leftIcon={<LabelIcon />} />
      <MenuItemLink to="/Announcement" primaryText="Announcements" leftIcon={<AnnouncementIcon />} />
      <MenuItemLink to="/Contact" primaryText="Contact Persons" leftIcon={<ContactMailIcon />} />
      <MenuItemLink to="/Language" primaryText="Languages" leftIcon={<LanguageIcon />} />
      <MenuItemLink to="/TranslationKey" primaryText="Translations" leftIcon={<TranslateIcon />} />
      <MenuItemLink to="/cognitoUsers" primaryText="Users" leftIcon={<PeopleIcon />} />
      <LogoutButton />
    </Menu>
  );
};

export default ControlPanel;
