import prettyoutput from 'prettyoutput';
import dataProviderCommands from "./dataProviderCommands";
import * as models from "../../models";
import { DataStore } from "@aws-amplify/datastore";

const contentpageCommands = {
    create: {
        description: 'create a content page',
        usage: 'create <name> <titleTranslationKey> <sloganTranslationKey> <descriptionTranslationKey> <url> <contactId>',
        fn: async(terminal, name, titlekey, slogankey, descriptionkey, url, contactId) => {
            try{
                let data ={
                    uniqueName:name,
                    titleTranslationKey:titlekey,
                    sloganTranslationKey:slogankey,
                    descriptionTranslationKey:descriptionkey,
                    url:url,
                    contactID:contactId
                }
                const result = await terminal.dataProvider.create('ContentPage', {data: data});
                 terminal.log(prettyoutput(result));
                 return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list contentpages',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "ContentPage",page,perPage)
    },
    get: {
        description: 'get contentpage key by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"ContentPage",id)
    },
    delete: {
        description: 'delete contentpage key by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"ContentPage",id)
    },
    listbadges:
    {
        description: 'list badges of the page',
        usage: 'listbadges <contentPageId>',
        fn: async (terminal, pageId) => {
            try
            {
                const {data:contentPageBadgeList} = await terminal.dataProvider.getList('ContentPageBadge', {});
                const contentPageBadges = contentPageBadgeList.filter(cpb => cpb.contentpage.id === pageId);
                terminal.log(prettyoutput(contentPageBadges));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }

    },
    addbadge:
    {
        description: 'add a badge to the page',
        usage: 'addbadge <contentPageId> <badgeId>',
        fn: async (terminal, pageId, badgeId) => 
        {
            try
            {
                const {data:page} = await terminal.dataProvider.getOne('ContentPage', {id:pageId});
                const {data:badge} = await terminal.dataProvider.getOne('Badge',  {id:badgeId});
                const data = {
                    contentpage: page,
                    badge: badge
                }
                const result = await terminal.dataProvider.create('ContentPageBadge', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    rembadge: 
    {
        description: 'remove a badge association',
        usage: 'remlang <contentPageBadgeId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"ContentPageBadge",id)
    },
    listdocs:
    {
        description: 'list documents of the page',
        usage: 'listdocs <contentPageId>',
        fn: async (terminal, pageId) => {
            try
            {
                const {data:contentPageDocumentFileList} = await terminal.dataProvider.getList('ContentPageDocumentFile', {});
                const contentPageDocumentFiles = contentPageDocumentFileList.filter(cpd => cpd.contentpage.id === pageId);
                terminal.log(prettyoutput(contentPageDocumentFiles));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }     
    },
    adddoc:
    {
        description: 'add a document to the page',
        usage: 'adddoc <contentPageId> <documentFileId>',
        fn: async (terminal, pageId, documentId) => 
        {
            try
            {
                const {data:page} = await terminal.dataProvider.getOne('ContentPage', {id:pageId});
                const {data:document} = await terminal.dataProvider.getOne('DocumentFile', {id:documentId});
                const data = {
                    contentpage: page,
                    documentfile: document
                }
                const result = await terminal.dataProvider.create('ContentPageDocumentFile', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remdoc: 
    {
        description: 'remove a documentfile association',
        usage: 'remdoc <contentPageDocumentFileId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"ContentPageDocumentFile",id)
    },
    listvideo:
    {
        description: 'list documents of the page',
        usage: 'listdocs <contentPageId>',
        fn: async (terminal, pageId) => {
            try
            {
                const {data:contentPageVideoFileList} = await terminal.dataProvider.getList('ContentPageVideoFile', {});
                const contentPageVideoFiles = contentPageVideoFileList.filter(cpd => cpd.contentpage.id === pageId);
                terminal.log(prettyoutput(contentPageVideoFiles));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }     
    },
    addvideo:
    {
        description: 'add a video to the page',
        usage: 'adddoc <contentPageId> <videoFileId>',
        fn: async (terminal, pageId, videoId) => 
        {
            try
            {
                const {data:page} = await terminal.dataProvider.getOne('ContentPage', {id:pageId});
                const {data:video} = await terminal.dataProvider.getOne('VideoFile', {id:videoId});
                const data = {
                    contentpage: page,
                    videofile: video
                }
                const result = await terminal.dataProvider.create('ContentPageVideoFile', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remvideo: 
    {
        description: 'remove a videofile association',
        usage: 'remdoc <contentPageVideoFile>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"ContentPageVideoFile",id)
    }
}

export default contentpageCommands
