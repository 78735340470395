import {disableUser, deleteUser} from "./adminApi"
import { HttpError} from 'react-admin';


const _delete = async (resource, params) =>
{
    console.log("resource", resource);
    console.log("params", params); 

    if(resource != 'cognitoUsers') throw 'Resource is not supported!'

    try {
        await disableUser(params.id);
        await deleteUser(params.id);
    }
    catch(error)
    {
        console.error('DataStore.delete failed\n' + error);
        throw new HttpError('DataStore delete instance of resource "' + resource + '" failed.', 500);
    }
    return { data: params.previousData }; 
}


export default  
{
    delete : _delete 
}