import { NotImplemented } from 'http-errors';
import prettyoutput from 'prettyoutput';
import dataProviderCommands from "./dataProviderCommands";

const announcementCommands = {
    create: {
        description: 'add a announcement',
        usage: 'create <text> <url>',
        fn: async(terminal, text, startdate, enddate, url) =>{
            return "not implemented yet";
            // try{
            //     let data ={
            //         value:text,
            //         text:text,
            //         url:url
            //     }
            //     const result = await terminal.dataProvider.create('Announcement', {data: data});
            //      terminal.log(prettyoutput(result));
            //      return
            // }
            // catch(error){
            //     console.error(error);
            //     return error.message;
            // }
        }
    },
    list: {
        description: 'list announcement',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "Announcement",page,perPage)
    },
    get: {
        description: 'get announcement by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"Announcement",id)
    },
    delete: {
        description: 'delete announcement by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"Announcement",id)
    },
    listlang:
    {
        description: 'list languages of an announcement',
        usage: 'listlang <announcementId>',
        fn: async (terminal, announcementId) => {
            try
            {
                const {data:AnnouncementLanguageList} = await terminal.dataProvider.getList('AnnouncementLanguage', {});
                const BadgeLanguages = AnnouncementLanguageList.filter(al => al.announcement.id === announcementId);
                terminal.log(prettyoutput(BadgeLanguages));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addlang:
    {
        description: 'associate an announcement with a language.',
        usage: 'addlang <announcementId> <languageId>',
        fn: async (terminal, badgeId, languageId) => 
        {
            try
            {
                const {data:announcement} = await terminal.dataProvider.getOne('Announcement', {id:badgeId});
                const {data:language} = await terminal.dataProvider.getOne('Language',  {id:languageId});
                const data = {
                    announcement: announcement,
                    language: language
                }
                const result = await terminal.dataProvider.create('AnnouncementLanguage', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remlang: 
    {
        description: 'remove a language association',
        usage: 'remlang <announcementLanguageId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"AnnouncementLanguage",id)
    }
}

export default announcementCommands