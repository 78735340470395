
import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemIcon, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import { LogoutIcon } from "../../icons/CustomIcons";
import { BroadcastChannel } from "broadcast-channel";
import { channels,events } from "./LogoutHandler";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: "MenuLogoutButton" }
);

const LogoutButton = (props) => {
  const [authChannel, setAuthChannel] = useState();
  const styles = useStyles(props);

  // channel to broadcast other tabs to signout aswell
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(channels.AUTH_CHANNEL);
    setAuthChannel(broadcastChannel);
    return async () => { await broadcastChannel.close(); };
  }, []);

  // broadcast logout to other channels so they can logout at the same time
  const sendSignout = () => {
    authChannel.postMessage(events.auth.USER_SIGNOUT);
  };

  return (
    <MenuItem
      onClick={sendSignout}
      className={classnames(styles.root, props.className)}
    >
      <ListItemIcon className={styles.icon}>
        <LogoutIcon />
      </ListItemIcon>
      <Typography variant="inherit">Sign out</Typography>
    </MenuItem>
  );
};
LogoutButton.displayName = "LogoutButton";

export default LogoutButton;
