import * as React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { useRecordContext } from 'ra-core';

import { splitStorageId } from "../../dataprovider/datastoreProvider"

const S3FileNameField =  (props) =>{
    const { basePath, addLabel, className, source, emptyText, ...rest } = props;
    const record = useRecordContext(props);

    const [id,value] = splitStorageId(get(record, source));
            
    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            
            {...rest}
        >
            {value != null && typeof value !== 'string'
                ? JSON.stringify(value)
                : value || emptyText}
        </Typography>
    );
};

S3FileNameField.displayName = "S3FileNameField";
S3FileNameField.defaultProps = {addLabel: true};
export default S3FileNameField