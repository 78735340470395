import React from "react";
import { Typography} from "@material-ui/core";

import { useRecordContext } from "ra-core";
import { useDetailPanelStyles } from "../../themes/DetailsPanelStyles"
import {S3RecordFileProvider} from "../Storage/S3FileProvider"
import ReactPlayer from 'react-player'

export const DetailsVideoField = (props) => {

  const styles = useDetailPanelStyles();
  const record = useRecordContext();

  return (
    <>
      <Typography
        className={styles.label}
        variant="caption"
      >
        {props.label}
      </Typography>

      {record && <S3RecordFileProvider
        record={record}
        source={props.source}
        destination="src"
      >
        <VideoPlayer />
      </S3RecordFileProvider>}
    </>
  )
}

const VideoPlayer = (props) => {
  const styles = useDetailPanelStyles();
  
  return (
    <div className={styles.video}>
        <ReactPlayer width="100%" height="100%" url={props.src} controls={true} />
    </div>
  )
}
export default DetailsVideoField
