import React, {useEffect, useState, useMemo} from 'react';
import useSignOut from './useSignOut';
import { BroadcastChannel } from 'broadcast-channel';
import {Hub} from '@aws-amplify/core';

//enums for naming
export const channels = { AUTH_CHANNEL: 'auth' }
export const events = { auth: { USER_SIGNOUT: 'usersignout', }, }

const LogoutHandler = () => {
  const [authChannel, setAuthChannel] = useState(); // broadcast channel
  const [signOutReceived,setSignOutReceived]= useState(false); // flag set to force signout

  // signout function from authenticator
  const signOut = useSignOut();

  // instantiate broadcastchannel
  useEffect(() => {
    //console.log('LOGOUTHANDLER opening AUTH_CHANNEL');
    // comm channel between tabs to sync auth events
    const broadcastChannel = new BroadcastChannel(channels.AUTH_CHANNEL);
    setAuthChannel(broadcastChannel);

    return async () => {
      //console.log('LOGOUTHANDLER closing AUTH_CHANNEL');
      await broadcastChannel.close();
    }
  },[])

  useEffect(() => {
    if (authChannel) {
      //console.log('LOGOUTHANDLER subscribed to auth broadcast channel');

      authChannel.onmessage = msg => {
        //console.log(`LOGOUTHANDLER received message on auth channel: ${msg}`);
        if (msg === events.auth.USER_SIGNOUT) setSignOutReceived(true);
     };
    }
  }, [authChannel])

  // if the session expired flag or the signout flag is set sign out
  useEffect(() => {
    if (signOutReceived && signOut) {
      //console.log(`LOGOUTHANDLER flag set: signout(${signOutReceived}) -> signing out.`,signOut);
      signOut();
    }
  }, [signOutReceived]);

  return null;
}

export default LogoutHandler;
