import { Auth, API } from 'aws-amplify';

export const addUserToGroup = async (username, groupname) => 
{ 
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let params = {
      body: {
        "username" : username,
        "groupname": groupname
      }, 
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      } 
  }
  return await API.post(apiName, path, params);
}

export const removeUserFromGroup = async (username, groupname) => 
{ 
  let apiName = 'AdminQueries';
  let path = '/removeUserFromGroup';
  let params = {
      body: {
        "username" : username,
        "groupname": groupname
      }, 
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      } 
  }
  return await API.post(apiName, path, params);
}

export const listGroupsForUser = async (username) => 
{ 
  let apiName = 'AdminQueries';
  let path = '/listGroupsForUser';
  let params = {
    queryStringParameters: {
      "username": username
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.get(apiName, path, params);
}

export const disableUser = async (username) => 
{ 
  let apiName = 'AdminQueries';
  let path = '/disableUser';
  let params = {
    body: {
      "username": username
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, params);
}

export const enableUser = async (username) => 
{ 
  let apiName = 'AdminQueries';
  let path = '/enableUser';
  let params = {
    body: {
      "username": username
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.post(apiName, path, params);
}

export const deleteUser = async (username) =>
{
  let apiName = 'AdminQueries';
  let path = '/deleteUser';
  let params = {
    body: {
      "username": username
    },
    headers: {
      'Content-Type' : 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    }
  }
  return await API.del(apiName, path, params);
}