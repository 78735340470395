export const RECORD_LOCATION = "RECORD_LOCATION";
export const CLEAR_HISTORY = "CLEAR_HISTORY";

export const recordLocation = (location) => ({
   type: RECORD_LOCATION,
   payload:{
       location: location
   }
})

export const clearHistory = () => ({
    type: CLEAR_HISTORY,
    payload: {}
})