import React from "react";

import { SaveButton } from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Button } from "@material-ui/core";
import classnames from 'classnames';
import useHeuristicReturnPath from "../Navigation/useHeuristicReturnPath";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      //marginTop: theme.spacing(2),
    },
    defaultToolbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    }
  })
);

const SaveCancelToolbar = (props) => {

  const classes = useStyles(props);
  
  // these will be injected by the Form
  const {
    classes: classesOverride,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    saving,
    alwaysEnableSaveButton,
    basePath,
    children,
    className,
    handleSubmit,
    record,
    redirect,
    resource,
    submitOnEnter,
    undoable,
    mutationMode,
    validating,
    width,
    origins,
    defaultPath,
    ...rest
  } = props;
  
  const returnPath = useHeuristicReturnPath(origins, defaultPath);
  
  return (
    <Toolbar
      className={classnames(classes.toolbar, classes.defaultToolbar)}
      role="toolbar"
      {...rest}
    >
      <SaveButton
        disabled={pristine}
        handleSubmitWithRedirect={handleSubmitWithRedirect}
        invalid={invalid}
        saving={saving}
        redirect={returnPath}
      />
      <CancelReturnButton
        redirect={returnPath}
      />
    </Toolbar>
  )
}

export const CancelReturnButton = (props) => {

  const { onCancel,  children, redirect} = props;

  return (
  <Button 
      component={Link}
      to={redirect}
      color='primary'
      startIcon={<CloseIcon/>}
      size='small'
  >
   Cancel 
  </Button>
  )
}


export default SaveCancelToolbar;