import React, { useState } from "react";
import { isURL } from "../../components/Fields/UrlValidation";
import {
  required,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  useRedirect,
  ReferenceInput,
  SelectInput,
  ReferenceField
} from 'react-admin';

import { useDataProvider} from "ra-core";
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import {DetailsPanel, DetailsDateField, DetailsTextField} from "../../components/DetailsPanel/index";
import useEditViewStyles from "../../themes/EditViewStyles";

const TranslationTitle = () => {
  return <span>
    Translation
  </span>;
};


/**
 * validates translations of ContentPage Linkouts 
 * these must contain a valid URL
 *
 * @async
 * @param dataProvider - react admin DataProviderInstance
 * @param values - simple form inputs
 * @param errors - the error object
 */
const validateLinkOut = async (dataProvider, values, errors) => {
  try {
    const response = await dataProvider.getOne('TranslationKey', {id: values.translationkeyID});
    if (response.data.key.includes('_LINKOUT') && !isURL(values.value) ){
      errors.value = 'must be a valid URL';
    }
  } catch (error) {
    console.log(error)
  }
}

export const TranslationEdit = props => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const onSuccess = (response) => redirect('show', '/TranslationKey', response.data.translationkeyID);

  const styles = useEditViewStyles(props);
  const validateTranslationEdit = async (values) => {
    const errors = {};
    await validateLinkOut(dataProvider, values, errors);
    return errors;
  }

  return (
    <Edit
      title={<TranslationTitle />}
      mutationMode='pessimistic'
      onSuccess={onSuccess}
      aside={<TranslationDetails />}
      {...props}  
      classes ={{card:styles.minHeightCard}}
    >
      <SimpleForm  validate={validateTranslationEdit} toolbar={<SaveCancelToolbar />}>
        <TextInput
          fullWidth
          source="value"
          multiline={true}
          minRows="3"
          label='Translation'
          variant="standard"
        />
      </SimpleForm>
    </Edit>
  );
}

export const TranslationCreate = props => {
  const [errorState, setErrorState] = useState(false);
  const dataProvider = useDataProvider();

  const redirect = useRedirect();
  const onSuccess = (response) => redirect('show', '/TranslationKey', response.data.translationkeyID);

  const validateTranslationCreation = async (values) => {
    const errors = {};

    await validateLinkOut(dataProvider, values, errors);

    const translations = await dataProvider.getList(
      'Translation',
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'translationkeyID', order: 'DESC' },
        filter: {
          translationkeyID: { eq: values.translationkeyID },
          languageID: { eq: values.languageID }
        }
      }
    );

    if (translations.total > 0) {
      errors.languageID = 'translation already exists';
      setErrorState(true);
    }
    else {
      setErrorState(false);
    }
    return errors
  };

  return (
    <Create
      {...props}
      udoable="false"
      onSuccess={onSuccess}
    >
      <SimpleForm validate={validateTranslationCreation} toolbar={<SaveCancelToolbar />}>
        <ReferenceInput
          label="Translation key"
          source="translationkeyID"
          reference="TranslationKey"
          variant="standard"
        >
           <SelectInput optionText="key" disabled={true} />
        </ReferenceInput>

        <ReferenceInput
          label="Language"
          source="languageID"
          reference="Language"
          variant="standard"
          validate={required()}
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <TextInput
          fullWidth
          source="value"
          multiline={true}
          label="Translation"
          variant="standard"
          disabled={errorState}
        />
      </SimpleForm>
    </Create>
  );
}

const TranslationDetails = (props) => {

  return (
    <DetailsPanel size="large">
      <ReferenceField
        source="translationkeyID"
        reference="TranslationKey"
        link={false}
      >
        <DetailsTextField label="Translation key" source="key"/>
      </ReferenceField>
      <ReferenceField
        source="languageID"
        reference="Language"
        link={false}
      >
        <DetailsTextField label="Language" source="displayName"/>
      </ReferenceField>
      <DetailsDateField label="Created at" source="createdAt"/>
      <DetailsDateField label="Updated at" source="updatedAt"/>
    </DetailsPanel>
  )
}
