
import React, { useEffect, useState } from "react"
import _ from 'lodash';

import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import {
  EditButton,
  useGetList,
  ShowButton,
  useGetOne,
  SelectInput,
  ReferenceInput,
  useInput
} from 'react-admin';

import SlideshowIcon from '@material-ui/icons/Slideshow';
import TranslateIcon from '@material-ui/icons/Translate';
import DescriptionIcon from '@material-ui/icons/Description';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { splitStorageId } from "../../dataprovider/datastoreProvider";

import classnames from 'classnames'
import { ExpandMore } from "@material-ui/icons"
import { S3FileProvider } from "../../components/Storage/S3FileProvider";
import { S3RecordFileProvider } from "../../components/Storage/S3FileProvider";
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import LocaleSelection from "../../components/EditorView/LocaleSelection";
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions
} from '../../components/Accordion/AccordionWithStyles';

import {
  Box, Button,
  CardContent,
  FormControl,
  Typography,
  TextField,
  Avatar,
  Chip,
  List,
  Divider
} from "@material-ui/core";

import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useEditorStyles, usePlaylistItemStyles } from "../../themes/EditorStyles";
import { ContentPageEditReturnPaths } from '../../components/Navigation/NavigationPaths';


export const ContentPageEditor = (props) => {

  const styles = useEditorStyles();

  const [locale, setLocale] = useState('');  //The ID of the selected language
  const localisations = useGetList("Language", {}, { field: 'displayName', order: 'ASC' });

  const onLocaleSelected = (locale) => {
    setLocale(locale);
  }

  return (
    <>
      <Accordion>
        <CardContent>
          <Typography className={styles.title} variant="h5">{props.record.uniqueName}</Typography>
        </CardContent>
      </Accordion>
      <ReferenceEditBar record={props.record} />
      <BadgesEdit
        locale={locale}
        localisations={localisations}
        onLocaleSelected={onLocaleSelected}
      />
      <VideoListEdit
        locale={locale}
        localisations={localisations}
        onLocaleSelected={onLocaleSelected}
      />
      <DocumentListEdit
        locale={locale}
        localisations={localisations}
        onLocaleSelected={onLocaleSelected}
      />
      <ContactEdit />
      <Accordion>
        <SaveCancelToolbar {...props} {...ContentPageEditReturnPaths} />
      </Accordion>
    </>
  )
}

const ReferenceEditBar = ({ record }) => {

  return (
    <Accordion>
      <AccordionActions>
        <PlaylistEditButton record={record} label="Playlist" />
        <TranslationEditButton record={record} source="descriptionTranslationKey" label="Description" />
        <TranslationEditButton record={record} source="sloganTranslationKey" label="Slogan" />
        <TranslationEditButton record={record} source="titleTranslationKey" label="Title" />
        <TranslationEditButton record={record} source="linkOutTranslationKey" label="Link Out" icon={<LinkIcon/>} />
      </AccordionActions>
    </Accordion>
  )
}


const PlaylistEditButton = ({ record, label }) => {
  const { data, loading, error } = useGetOne('Playlist', record.playlist.id)

  return (
    <EditButton
      disabled={loading || error}
      basePath="/Playlist"
      label={label}
      record={data}
      icon={<SlideshowIcon />}
    />);
}


const TranslationEditButton = ({ record, source, label, icon }) => {
  const { data, loading, error } = useGetList("TranslationKey", {}, {}, { key: { eq: _.get(record, source) } });
  return (<ShowButton
    disabled={loading || error}
    basePath="/TranslationKey"
    label={label}
    record={_.values(data)[0]}
    icon={icon ?? <TranslateIcon />}
  />)
}

const BadgesEdit = ({ locale, onLocaleSelected, localisations, ...props }) => {


  const { input } = useInput({ source: 'badges', ...props })
  const { data: badges, loading, error } = useGetList("Badge", {}, { field: "createdAt", order: "ASC" }, {});

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="badges-content"
        id="badges-header"
      >
        <Typography>Badges</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <LocaleSelection
          allowNone
          localisations={
            localisations.data && !localisations.loading ?
              _.values(localisations.data) : []
          }
          selectedLocale={locale}
          onSelectLocale={onLocaleSelected}
        />
        <BadgeList badges={badges} input={input} locale={locale} />
        <AddBadgeControl badges={badges} input={input} />
      </AccordionDetails>
    </Accordion>
  )
}

const BadgeList = ({ locale, badges, input, ...props }) => {

  const styles = useEditorStyles();

  const removeBadge = (badge) => () => {
    input.onChange(input.value.filter(id => id !== badge.id));
  }

  const getLocalisedStyle = (item, classes) => {
    if (locale && !item.languages.includes(locale)) {
      classes.push(styles.disabled);
    }
    return classnames(classes);
  }

  // Badges should appear in the order of the input array
  // this should ensure that new badges appear at the 
  // beginning of the list
  const sortByAppearance = (input, badges) => {
    const badgeDict = new Map(
      _.values(badges)
        .filter(badge => input.value.includes(badge.id))
        .map(badge => [badge.id,badge])
    );

    const orderedBadges = input.value
      .filter(iv => badgeDict.has(iv))
      .map(iv => badgeDict.get(iv));

    return orderedBadges;
  }

  return (
    <Box flexDirection="column" className={styles.field}>
      <Typography variant="caption" className={styles.label}>Badges</Typography>
      <div>
        {input.value && badges && sortByAppearance(input, badges)
            .map(badge => {
              return (
                <Chip
                  className={getLocalisedStyle(badge, [styles.badgeChip])}
                  key={badge.id}
                  label={badge.text}
                  onDelete={removeBadge(badge)}
                  color="secondary"
                />
              );
            })
        }
      </div>
    </Box>
  )
}

const AddBadgeControl = ({ badges, input, ...props }) => {
  const styles = useEditorStyles();

  const mapItemToOption = (item) => ({ name: item.text });
  const getOptionLabel = (option) => _.isEmpty(option) ? "" : option.name
  const renderOption = (option, state) =>
  (
    <div className={styles.addOption}>
      <Typography variant="body1">{option.name}</Typography>
    </div>
  );

  return (<AddItemControl
    label="Add Badge"
    items={badges}
    input={input}
    mapItemToOption={mapItemToOption}
    getOptionLabel={getOptionLabel}
    renderOption={renderOption}
  />)
}

const VideoListEdit = ({ locale, onLocaleSelected, localisations, ...props }) => {

  const { input } = useInput({ source: 'videoFiles', ...props })
  const { data: videos, loading, error } = useGetList("VideoFile", {}, { field: "title", order: "ASC" }, {});

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="badges-content"
        id="badges-header"
      >
        <Typography>Video list</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <LocaleSelection
          allowNone
          localisations={
            localisations.data && !localisations.loading ?
              _.values(localisations.data) : []
          }
          selectedLocale={locale}
          onSelectLocale={onLocaleSelected}
        />
        <VideoList videos={videos} input={input} locale={locale} />
        <AddVideoControl videos={videos} input={input} />
      </AccordionDetails>
    </Accordion>
  )
}

const VideoList = ({ locale, videos, input, ...props }) => {

  const editorStyles = useEditorStyles();
  const listStyles = usePlaylistItemStyles();

  const formatKey = (key) => {
    const [id, filename] = splitStorageId(key);
    return filename;
  }

  const getLocalisedStyle = (item, classes) => {
    if (locale && !item.languages.includes(locale)) {
      classes.push(listStyles.disabled);
    }
    return classes.join(' ');
  }

  const removeItem = (event)  => {
    input.onChange(input.value.filter(id => id !== event.target.value));
  }

  return (
    <div className={editorStyles.fullwidth}>
      <Typography variant="caption" className={editorStyles.label}>
        Video list
      </Typography>
      <div className={editorStyles.field}>
        <List>
          {input.value && videos &&
            _.values(videos)
              .filter(video => input.value.includes(video.id))
              .map( (item, index)  => {
              return (
                <div key={item.id}>
                  {index>0 && <Divider/>}
                  <div className={listStyles.itemContent}>
                    <div className={listStyles.thumbnail}>
                      <S3FileProvider s3key={item.posterStorageKey}>
                        <img className={getLocalisedStyle(item,[listStyles.image])}/>        
                      </S3FileProvider>
                      <LocalMoviesIcon className={listStyles.overlayIcon}/>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        className={getLocalisedStyle(item,[])}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        className={getLocalisedStyle(item,[listStyles.filename])}
                        variant="caption"
                      >
                        {formatKey(item.videoStorageKey)}
                      </Typography>
                    </div>
                    <Box flex="auto" />
                    <Button
                      className={listStyles.removeButton}
                      onClick={removeItem} 
                      value={item.id} 
                      startIcon={<RemoveCircleIcon />}
                    >
                      Remove
                    </Button>
                  </div>                
                </div>
              );
            })
          }
        </List>
      </div>
    </div>
  )
}

const AddVideoControl = ({ videos, input, ...props }) => {
  const styles = useEditorStyles();

  const formatKey = (key) => {
    const [id, filename] = splitStorageId(key);
    return filename;
  }

  const mapItemToOption = (item) => {
    return { name: formatKey(item.videoStorageKey) }
  };
  const getOptionLabel = (option) => _.isEmpty(option) ? "" : option.name
  const renderOption = (option, state) =>
  (
    <div className={styles.addOption}>
      <Typography variant="body1">{option.name}</Typography>
    </div>
  );

  return (<AddItemControl
    label="Add video"
    items={videos}
    input={input}
    mapItemToOption={mapItemToOption}
    getOptionLabel={getOptionLabel}
    renderOption={renderOption}
  />)
}

const DocumentListEdit = ({ locale, onLocaleSelected, localisations, ...props }) => {

  const styles = useEditorStyles();

  const { input } = useInput({ source: 'documentFiles', ...props })
  const { data: documents } = useGetList("DocumentFile", {}, { field: "title", order: "ASC" }, {});

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="badges-content"
        id="badges-header"
      >
        <Typography>Document list</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <LocaleSelection
          allowNone
          localisations={
            localisations.data && !localisations.loading ?
              _.values(localisations.data) : []
          }
          selectedLocale={locale}
          onSelectLocale={onLocaleSelected}
        />
        <DocumentList documents={documents} input={input} locale={locale}/>
        <AddDocumentControl documents={documents} input={input}/>
      </AccordionDetails>
    </Accordion>
  )
}

const DocumentList = ({ locale, documents, input, ...props }) => {

  const editorStyles = useEditorStyles();
  const listStyles = usePlaylistItemStyles();

  const formatKey = (key) => {
    const [id, filename] = splitStorageId(key);
    return filename;
  }

  const getLocalisedStyle = (item, classes) => {
    if (locale && !item.languages.includes(locale)) {
      classes.push(listStyles.disabled);
    }
    return classes.join(' ');
  }

  const removeItem = (event)  => {
    input.onChange(input.value.filter(id => id !== event.target.value));
  }

  return (
    <div className={editorStyles.fullwidth}>
      <Typography variant="caption" className={editorStyles.label}>
        Video list
      </Typography>
      <div className={editorStyles.field}>
        <List>
          {input.value && documents &&
            _.values(documents)
              .filter(document => input.value.includes(document.id))
              .map( (item, index)  => {
              return (
                <div key={item.id}>
                  {index>0 && <Divider/>}
                  <div className={listStyles.itemContent}>
                    <div className={listStyles.icon}>
                      <DescriptionIcon fontSize="inherit" className={getLocalisedStyle(item,[])}/>
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        className={getLocalisedStyle(item,[])}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        className={getLocalisedStyle(item,[listStyles.filename])}
                        variant="caption"
                      >
                        {formatKey(item.documentStorageKey)}
                      </Typography>
                    </div>
                    <Box flex="auto" />
                    <Button
                      className={listStyles.removeButton}
                      onClick={removeItem} 
                      value={item.id} 
                      startIcon={<RemoveCircleIcon />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              );
            })
          }
        </List>
      </div>
    </div>
  )
}


const AddDocumentControl = ({ documents, input, ...props }) => {
  const styles = useEditorStyles();

  const formatKey = (key) => {
    const [id, filename] = splitStorageId(key);
    return filename;
  }

  const mapItemToOption = (item) => {
    return { name: formatKey(item.documentStorageKey) }
  };
  const getOptionLabel = (option) => _.isEmpty(option) ? "" : option.name
  const renderOption = (option, state) =>
  (
    <div className={styles.addOption}>
      <Typography variant="body1">{option.name}</Typography>
    </div>
  );

  return (<AddItemControl
    label="Add document"
    items={documents}
    input={input}
    mapItemToOption={mapItemToOption}
    getOptionLabel={getOptionLabel}
    renderOption={renderOption}
  />)
}

const AddItemControl = (
  {
    label,
    items,
    input,
    mapItemToOption,
    getOptionLabel,
    renderOption,
    ...props
  }) => {

  const styles = useEditorStyles();

  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [selectionIsEmpty, setSelectionIsEmpty] = useState(true);
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const choices = _.values(items)
      .filter(item => !input.value.includes(item.id))
      .map(item => { return { id: item.id, ...mapItemToOption(item) }; });
    setChoices([null, ...choices]);
  }, [input.value, items, mapItemToOption]);

  const addSelection = (event) => {
    if (autoCompleteValue && autoCompleteValue.id) {
      input.onChange([autoCompleteValue.id, ...input.value])
    }
    setAutoCompleteValue(null);
    setSelectionIsEmpty(true);
  }

  const onSelectionChange = (event, value, reason) => {
    if (_.isEmpty(value)) {
      setAutoCompleteValue(null);
      setSelectionIsEmpty(true);
    }
    else {
      setAutoCompleteValue(value);
      setSelectionIsEmpty(false);
    }
  }

  //limit options list to 100 entries;
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => 
    defaultFilterOptions(options, state).slice(0, 100);

  const renderOptionOrDefault = (option, state) => {
    return (
      option ? (
        renderOption(option, state)
      ) : (
        <div className={styles.addOption}>
          <Typography variant="body1"><em>None</em></Typography>
        </div>
      )
    )
  }

  return (
    <div className={styles.field}>
      <FormControl className={[styles.formControl, styles.combinedControl].join(" ")}>
        <Autocomplete
          id="add-badge"
          options={choices}
          value={autoCompleteValue}
          filterOptions={filterOptions}
          onChange={onSelectionChange}
          getOptionLabel={getOptionLabel}
          style={{ minWidth: 320 }}
          renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
          renderOption={renderOptionOrDefault}
        />
        <Box width="8px" />
        <Button
          onClick={addSelection}
          disabled={selectionIsEmpty}
          startIcon={<AddIcon />}
          color="primary"
        >
          Add
        </Button>
      </FormControl>
    </div>
  );

}

const ContactEdit = (props) => {

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="contact-content"
        id="contact-header"
      >
        <Typography>Contact</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ReferenceInput
          allowEmpty
          label="Contact"
          source="contactID"
          reference="Contact"
          link="false"
          variant="standard"
        >
          <SelectInput
            optionText={<ContactOption />}
            allowEmpty
            emptyValue={null}
            emptyText={<em>None</em>}
          />
        </ReferenceInput>
      </AccordionDetails>
    </Accordion>
  )
}

const ContactOption = ({ record }) => {
  return (
    <Box display="flex" flexDirection="row" margin="8px" alignItems="center">
      <S3RecordFileProvider record={record} label={""} source="imageStorageKey" destination="src">
        <Avatar />
      </S3RecordFileProvider>
      <Box display="flex" flexDirection="column" paddingLeft="12px">
        <Typography variant="body1">{record.name}</Typography>
        <Typography variant="caption">{record.jobTitle}</Typography>
      </Box>
    </Box>
  )
}
