import React from "react";
import * as _ from 'lodash';

import {
    SingleFieldList,
    ChipField, 
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceArrayField,   
} from 'react-admin';

import PropTypes from 'prop-types';


export const LocalisationField = (props) => 
(
    <ReferenceArrayField 
        label={_.get(props,'label','Localisation')}
        reference='Language' 
        source={_.get(props,'source','languages')}
        sort= { {
            field: 'displayName',
            order: 'ASC' 
            } }
        {...props}
    >
        <SingleFieldList  
            linkType={false} 
            onClick={(e) => {e.stopPropagation();}}
        >
            <ChipField 
                source="displayName" 
                variant='outlined'
            />
        </SingleFieldList>  
    </ReferenceArrayField>
);

LocalisationField.defaultProps = {
    addLabel:true,
    label: 'Localisation'
};

LocalisationField.propTypes = {
    label: PropTypes.string
};


export const  LocalisationInput = (props) =>
(

    <ReferenceArrayInput 
        label='Localisation'
        source={_.get(props,'source','languages')}
        reference="Language"
        {...props}
    >
        <SelectArrayInput 
            optionText="displayName" 
            variant='standard'
        />                
    </ReferenceArrayInput>
 
)

