import React from "react";

import {
  List,
  Datagrid,
  DateField,
  TextField,
  SelectInput,
  Create,
  SimpleForm,
  DeleteButton,
  useMutation,
  useDataProvider,
  useRedirect
} from 'react-admin';

import { Switch } from '@material-ui/core';
import { languages, getDisplayName, getNativeName } from './languageCodes';
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import FormTextField from "../../components/FormFields/FormTextField";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";


const RemoveLanguageButton = (props) => {
  const displayName = languages[props.record.locale]?.displayName ?? props.record.locale;
  const confirmTitle = `Delete ${displayName} language?`;
  const confirmContent = 'Are you sure you want to delete this language? All translations for this language will be deleted.'

  return (
    <DeleteButton
      {...props}
      label='Delete'
      undoable={false}
      confirmTitle={confirmTitle}
      confirmContent={confirmContent}
    />
  )
}

const PublishLanguageSwitch = ({ record }) => {

  const [mutate, { loading }] = useMutation();
  const toggleLanguagePublished = (event) => {
    event.stopPropagation();
    mutate({
      type: 'update',
      resource: 'Language',
      payload: {
        id: record.id,
        data: { published: !record.published }
      },
    });
  }
  return (
    <Switch
      checked={record.published ?? false}
      onClick={toggleLanguagePublished} disabled={loading}
    />);
}

export const LanguageList = (props) => {

  return (
    <List {...props} 
      exporter={false} 
      bulkActionButtons={false}
      pagination={<DefaultPagination/>}    
      sort={{ field: 'displayName', order: 'ASC' }}
      perPage={PerPageDefaults.min}
    >
      <Datagrid hover={false}>
        <TextField source="displayName" label="Name" />
        <TextField source="nativeName" label="Native Name" />
        <TextField source="locale" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <PublishLanguageSwitch label='Published' />
        <RemoveLanguageButton />
      </Datagrid>

    </List >

  )

};


export const LanguageCreate = props => {
  const dataProvider = useDataProvider();

  const redirect = useRedirect();
  const onSuccess = (response) => redirect('list', '/Language');

  const transform = (record) => {
    record.nativeName = getNativeName(record.locale);
    record.displayName = getDisplayName(record.locale);
    record.published = false;
    return { ...record };
  }

  const langChoice =
    ["de", "en", "fr", "nl", "it", "es", "pt", "pl", "ru", "ja", "zh-Hans", "ko"]
      .map(lng => {
        return {
          id: lng,
          name: languages[lng].displayName
        }
      }
      );

  const validateLanguageCreation = async (values) => {
    const errors = {};
    const languages = await dataProvider.getList(
      'Language',
      {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'locale', order: 'DESC' },
        filter: { locale: { eq: values.locale } }
      }
    )
    if (languages.total > 0) {
      errors.locale = 'language already exists';
    }
    console.log('errors', languages, errors)
    return errors
  };


  return (
    <Create
      {...props}
      transform={transform}
      onSuccess={onSuccess}
    >
      <SimpleForm validate={validateLanguageCreation} toolbar={<SaveCancelToolbar/>}>
        <SelectInput label="Select Language" choices={langChoice} source="locale" variant="standard" />
        <FormTextField source="locale" label="Locale" />
        <FormTextField source="locale" label="Native name" transform={getNativeName} />
      </SimpleForm>
    </Create>
  )
};
