/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://t3opph4ywbenblpewdx4nj2ddq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://vajkfklad2.execute-api.eu-central-1.amazonaws.com/main",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:56efa2b6-72c4-4acf-83be-b92e7f8afef7",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_n4XJoA2lZ",
    "aws_user_pools_web_client_id": "3p2iqupauvc457fp9s2nbmjgde",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fhl-media143814-main",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
