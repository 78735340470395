
import React from "react";

import {
  required,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  ImageField,
  ImageInput,
  TextInput,
  TextField,
  DateField,
  FileInput,
  FileField,
  BulkDeleteButton
} from 'react-admin';


import {S3RecordFileProvider} from "../../components/Storage/S3FileProvider";
import S3FileNameField from "../../components/Storage/S3FileNameField";
import { transformFileAttachment } from "../../dataprovider/datastoreProvider"
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import { LocalisationField, LocalisationInput } from "../../components/Associations/LocalisationField";
import useListViewStyles from "../../themes/ListViewStyles";
import { VideoPlaceholder, ImagePlaceholder } from "../../components/Fields/FileDropField"
import { DetailsPanel, DetailsDateField, DetailsVideoField, DetailsImageField, DetailsFileNameField } from "../../components/DetailsPanel";
import useEditViewStyles from "../../themes/EditViewStyles";
import { hasValidImageDimensions } from "./imageValidation";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";

const MediaFilesTitle = ({ record }) => {

  return <span>
    Videos - {record.title}
  </span>;
};

const VideoFileFilters = [
  <TextInput
    label="Search title"
    source="title.contains"
    alwaysOn
    key='searchFilter'
  />
];

const VideoFileActionButtons = props => (
  <>
    <BulkDeleteButton
      {...props}
      undoable={false}
    />
  </>
);

const validateForm = async (formValues) => {
  const {_posterStorageKey} = formValues;
  let error = {};
  if(_posterStorageKey){

    const {rawFile} = _posterStorageKey;
    const maxDimensions = {width:1920, height:1080};
    const {isValid:dimensionsValid, dimensions} = await hasValidImageDimensions(rawFile, maxDimensions);
    if(! dimensionsValid) {
      error._posterStorageKey = `Poster exceeds max. dimensions: ${dimensions.width}x${dimensions.height}px (allowed max.: ${maxDimensions.width}x${maxDimensions.height}px)`
    }

    const maxByteSize = 2.5 * 1048576;
    if( rawFile.size > maxByteSize){
      error._posterStorageKey = `Poster exceeds max. size: ${(rawFile.size /1048576).toPrecision(3)}MB (allowed max.: ${(maxByteSize/1048576).toPrecision(2)}MB)`
    }
  }
  return error;
}

export const VideoFileList = (props) => {
  const styles = useListViewStyles();

  return (
    <List
      {...props}
      exporter={false}
      filters={VideoFileFilters}
      bulkActionButtons={<VideoFileActionButtons />}
      pagination={<DefaultPagination/>}
      perPage={PerPageDefaults.min}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <S3RecordFileProvider label={""} source="posterStorageKey" destination="_image" appendRecord sortable={false}>
          <ImageField source="_image" classes={styles} />
        </S3RecordFileProvider>
        <TextField source='title' />
        <S3FileNameField label="Video file name" source="videoStorageKey" />
        <LocalisationField source='languages' sortable={false}/>
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )
};

export const VideoFileEdit = props => {

  const transform = record => transformFileAttachment(record, ['_posterStorageKey', '_videoStorageKey'], { level: 'public' });
  const styles = useEditViewStyles();

  return (

    <Edit
      title={<MediaFilesTitle />}
      {...props}
      transform={transform}
      actions={false}
      mutationMode='pessimistic'
      aside={<VideoFileDetails />}
      classes={{ card: styles.minHeightCard }}
    >
      <SimpleForm toolbar={<SaveCancelToolbar />} validate={validateForm}>
        <LocalisationInput />
        <TextInput label="Title" source="title" variant="standard" />
        <ImageInput
          label='Upload new poster'
          source='_posterStorageKey'
          accept='image/png,.jpg'
          placeholder={<ImagePlaceholder />}
        >
          <ImageField source='_posterStorageKey' />
        </ImageInput>
        <FileInput
          label="Upload new video"
          source="_videoStorageKey"
          accept="video/mp4"
          placeholder={<VideoPlaceholder />}
        >
          <FileField source="_videoStorageKey" title="title" src="_videoStorageKey" target="_blank" />
        </FileInput>
      </SimpleForm>
    </Edit>
  )

};


export const VideoFileCreate = (props) => {

  const transform = record => transformFileAttachment(record, ['_posterStorageKey', '_videoStorageKey'], { level: 'public' });

  return (
    <Create
      {...props}
      transform={transform}
    >
      <SimpleForm redirect="list" toolbar={<SaveCancelToolbar />} validate={validateForm}>
        <LocalisationInput />
        <TextInput
          source="title"
          validate={required()}
          variant="standard"
        />
        <ImageInput
          label='Upload video poster'
          source='_posterStorageKey'
          accept='image/png,.jpg'
          validate={required()}
          placeholder={<ImagePlaceholder />}
        >
          <ImageField source='_posterStorageKey'/>
        </ImageInput>

        <FileInput
          label="Upload video"
          source="_videoStorageKey"
          accept="video/mp4"
          validate={required()}
          placeholder={<VideoPlaceholder />}
        >
          <FileField
            source="_videoStorageKey"
            title="title"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Create>

  );
}


const VideoFileDetails = () => (
  <DetailsPanel size="large">
    <DetailsImageField label="Poster" source="posterStorageKey" />
    <DetailsFileNameField label="Poster file name" source="posterStorageKey" />
    <DetailsVideoField label="Video" source="videoStorageKey" />
    <DetailsFileNameField label="Video file name" source="videoStorageKey" />
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)
