import { DataStore } from "@aws-amplify/datastore";

const dataStoreCommands = {
    stop:{
        description: 'stop datastore sync.',
        usage: 'stop',
        fn: async () => {
            await DataStore.stop();
            return 'datastore stopped'
        }
    },
    clear:{
        description: 'clear datastor cache',
        usage: 'clear',
        fn: async () => {
            await DataStore.clear();
            return 'datastore cleared'
        }
    },
    start:{
        description: 'start datastore sync.',
        usage: 'start',
        fn: async () => {
            await DataStore.start();
            return 'datastore started'
        }
    },
    reset:{
        description: 'stop, clear and restart datastore sync.',
        usage: 'reset',
        fn: async () => {
            await DataStore.stop();
            await DataStore.clear();
            await DataStore.start();
            return 'datastore reset'
        }
    }    
}


export default dataStoreCommands;