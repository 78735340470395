import prettyoutput from 'prettyoutput';
import { Storage } from '@aws-amplify/storage'

const storageCommands = {
    get:{
        description: 'fetches the presigend url for the key',
        usage: 'get <key> <public|protected|private>',
        fn: async (terminal, key, accessLevel) =>{
            try{
                const url = await Storage.get(key, {level:accessLevel});
                terminal.log(url);
                return;           
            }
            catch (error)  {
                console.error(error);
                return error.message;
            }
        }
    },
    list:{
        description: 'list available storage keys',
        usage: 'list <public|protected|private> <filter>',
        fn: async (terminal, accessLevel, filter) =>{
            try{
                filter = filter ? filter : ""
                const keylist = await Storage.list(filter,{level:accessLevel});
                terminal.log(prettyoutput(keylist));
                return;
            }
            catch (error)  {
                console.error(error);
                return error.message;
            }
        }
    }       
}

export default storageCommands;