
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ReactMarkdown from 'react-markdown'

import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw';

const Markdown = (props) => {
  return (
    <ReactMarkdown
      linkTarget='_blank'
      components={{
      h2: ({ node, ...props }) => <Typography variant='h4' gutterBottom {...props} />,
      h3: ({ node, ...props }) => <Typography variant='h5' gutterBottom {...props} />,
      h4: ({ node, ...props }) => <Typography variant='h6' gutterBottom {...props} />,
      p: ({ node, ...props }) => <Typography variant='body2' gutterBottom  {...props} />,
      br: ({ node, ...props }) => <Box height='1.5rem' {...props} />,
    }} 
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    >{props.children}</ReactMarkdown>
  )
}

export default Markdown;
