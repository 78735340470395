import Amplify, {Hub} from "aws-amplify";
import prettyoutput from 'prettyoutput';
import * as _ from 'lodash';

const amplifyCommands = {
    loglevel: {
        description: 'set the amplify log level',
        usage: 'loglevel <VERBOSE|DEBUG|INFO|WARN|ERROR>',
        fn: async (terminal, logLevel) =>{
            try{
                Amplify.Logger.LOG_LEVEL = logLevel;
                return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    send: {
        description: 'send a hub event',
        usage: 'send auth tokenRefresh_failure',
        fn: async (terminal, channel, event, data) => {
            if (!channel) {
                const channels = [
                  'core',
                  'auth',
                  'api',
                  'analytics',
                  'interactions',
                  'pubsub',
                  'storage',
                  'xr',
                  'datastore',
                ]
                terminal.log(prettyoutput(channels));
            }
            if (channel && !event) {
              const events = {
                auth: [
                  'signIn',
                  'signUp',
                  'signOut',
                  'signIn_failure',
                  'tokenRefresh',
                  'tokenRefresh_failure',
                  'configured',
                ],
                datastore: [
                  'networkStatus {active: bool}',
                  'subscriptionsEstablished',
                  'syncQueriesReady',
                  'ready',
                  'outboxStatus {isEmpty:bool}',
                ],
              }
                if (_.has(events,channel)) terminal.log(prettyoutput(_.get(events,channel)))
                else terminal.log(`No events implemented for ${channel} channel.`)
            }
            if (channel && event) {
                try {
                    Hub.dispatch(channel, {event: event, data: data ? JSON.parse(data) : {}});
                    return
                }
                catch (error) {
                    console.error(error);
                    return error.message;
                }
            }
        }
    }
}

export default amplifyCommands;
