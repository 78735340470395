import React, {useEffect, useState, useRef} from "react";

import {Auth} from 'aws-amplify';
import {AmplifyFilter} from "react-admin-amplify";
import {Box, Switch, CircularProgress} from '@material-ui/core';

import {
    List,Datagrid,TextField,DeleteButton,
    EmailField,TextInput,
    DateField, 
} from 'react-admin';

import QuickFilter from "../../components/QuickFilter";
import {addUserToGroup, removeUserFromGroup, listGroupsForUser} from  "../../dataprovider/adminApi"
import DefaultPagination from "../../components/ListView/DefaultPagination";


const GroupMemberSwitch = (props) => 
{
    const mountedRef = useRef(true)
    const [isGroupMember, setIsGroupMember] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isSelf, setIsSelf] = useState(false);

    useEffect( () => { 
        const waitForUserData = async ()=>{
            if(props.currentUser && isLoading) 
            {
                try{
                    await refreshState();
                    if(mountedRef.current){
                        setIsLoading(false);
                        setIsSelf(props.currentUser.attributes.email == props.record.email);
                    }
                }
                catch (error){
                    console.error(error);
                }
            }
        }
        mountedRef.current = true;
        waitForUserData();    
        return () => {
            mountedRef.current = false;
        }
    }, 
    [props.currentUser]);

    const refreshState = async () =>
    {
        const {Groups} = await listGroupsForUser(props.record.email);
        const isGroupMember = Groups.map(g => g.GroupName).some(gn => gn == props.group);
        if(mountedRef.current){
            setIsGroupMember(isGroupMember);
        }
    }

    const toggleMembership = async (event) =>{
        event.stopPropagation();      
        setIsUpdating(true);
        setIsGroupMember(!isGroupMember);
        try{
            !isGroupMember ?
            await addUserToGroup(props.record.email, props.group):
            await removeUserFromGroup(props.record.email, props.group);
            await refreshState();
            
        } catch (error){
            console.error(error);
        }
        if(mountedRef.current){
            setIsUpdating(false);
        }
    }

    if(isLoading){
        return (
            <Box sx={{height:38}}>
                <CircularProgress color="secondary" size={20}/>
            </Box>
        );
    }
    else{
        return (<Switch checked={isGroupMember} disabled={isUpdating || isSelf} onClick={toggleMembership}/>);
    }
}

const DeleteUserButton = ({currentUser,...props}) => 
{
    const [isLoading, setIsLoading] = useState(true);
    const [isSelf, setIsSelf] = useState(false);

    const confirmTitle = `Delete user ${props.record.email}`;
    const confirmContent = 'Are you sure you want to delete this user?'

    useEffect( () => { 
        if(currentUser && isLoading) 
        {
            try{
                setIsSelf(currentUser.attributes.email == props.record.email);
                setIsLoading(false);
            }
            catch (error){
                console.error(error);
            }
        }
    }, 
    [currentUser]);

    return (
        <DeleteButton 
            {...props} 
            disabled={isLoading || isSelf} 
            undoable={false} 
            confirmTitle={confirmTitle} 
            confirmContent={confirmContent} 
        />
    )
}

const UserListFilter = (props) => (
<AmplifyFilter {...props} defaultQuery={'listCognitoUsers'}>
    <TextInput
      source="getUser.username"
      label="email (exact match)"
      resettable     
    />
    <QuickFilter label="admins only" source="listUsersInGroup.groupname" defaultValue={"admins"} /> 
</AmplifyFilter>
);

export const UserList = props => {

    const [userIdentity, setUserIdentity] = useState(null);
    const mountedRef = useRef(true)
    
    useEffect( () => {
        const setIdentity = async () =>{
            try{
                const identity = await Auth.currentUserInfo();
                if (!mountedRef.current) return null;
                setUserIdentity(identity);
            }
            catch (error)
            {
                console.error(error);
            }
        }
        setIdentity();
        return () => {mountedRef.current = false;}
    }, []);

    return (
        <List {...props}  
            filters={<UserListFilter/>} 
            exporter={false} 
            bulkActionButtons={false}
            pagination={<DefaultPagination/>}
        >
            <Datagrid hover={false}>
                <EmailField source="email" sortable={false}/>
                <TextField source="UserStatus" label="Status" sortable={false}/>
                <DateField source="UserCreateDate" label="Sign-up Date" sortable={false}/>
                <GroupMemberSwitch currentUser={userIdentity} group='admins' label='Admin' sortable={false}/>
                <DeleteUserButton currentUser={userIdentity}/>
            </Datagrid>
        </List>)
}

