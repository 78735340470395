// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Tour, Playlist, PlaylistImageFile, ImageFile, ImageFileLanguage, Language, VideoFileLanguage, VideoFile, ContentPageVideoFile, ContentPage, ContentPageBadge, Badge, BadgeLanguage, Announcement, AnnouncementLanguage, ContentPageDocumentFile, DocumentFile, DocumentFileLanguage, PlaylistVideoFile, Translation, Contact, TranslationKey } = initSchema(schema);

export {
  Tour,
  Playlist,
  PlaylistImageFile,
  ImageFile,
  ImageFileLanguage,
  Language,
  VideoFileLanguage,
  VideoFile,
  ContentPageVideoFile,
  ContentPage,
  ContentPageBadge,
  Badge,
  BadgeLanguage,
  Announcement,
  AnnouncementLanguage,
  ContentPageDocumentFile,
  DocumentFile,
  DocumentFileLanguage,
  PlaylistVideoFile,
  Translation,
  Contact,
  TranslationKey
};