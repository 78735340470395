
import React  from "react";

import {
  List,
  Datagrid,
  Edit,
  TextField,
  DateField,
  TextInput
} from 'react-admin';

import PlayListEditor, {sanitizePlaylist} from "./playlistEditor"
import { FormWithRedirect } from "ra-core";
import { DetailsDateField, DetailsPanel } from "../../components/DetailsPanel";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";

const PlaylistTitle = ({ record }) => {
  return <span>
    Playlist - {record.name}
  </span>;
};

const PlaylistFilters = [
  <TextInput
    label="Search name"
    source="name.contains"
    alwaysOn
    key='searchFilter'
  />
];



export const PlaylistList = (props) => {

  return (
    <List
      {...props}
      exporter={false}
      filters={PlaylistFilters}
      bulkActionButtons={false}
      pagination={<DefaultPagination/>}
      perPage={PerPageDefaults.default}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField source="name"/>
        <DateField source="createdAt"/>
        <DateField source="updatedAt"/>
      </Datagrid>
    </List >
  )
};


export const PlaylistEdit = props => {

  return (
    <Edit
      {...props}
      title={<PlaylistTitle />}
      actions={false}
      mutationMode='pessimistic'
      aside={<PlaylistDetails />}     
      transform={sanitizePlaylist}
    >
      <FormWithRedirect
        resource="Playlist"
        render={({
          handleSubmitWithRedirect,
          pristine,
          saving,
          record
        }) => (
          <PlayListEditor 
            record={record}  
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            pristine={pristine}
            saving={saving}
          />
        )}
      />
    </Edit>
  )

};

const PlaylistDetails = () => (
  <DetailsPanel size="small">
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)




