import dataProviderCommands from "./dataProviderCommands";
import {addUserToGroup, removeUserFromGroup, listGroupsForUser, enableUser, disableUser, deleteUser} from  "../../dataprovider/adminApi"
import prettyoutput from 'prettyoutput';

const cognitoCommands = {
    list: {
        description: 'list users',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "cognitoUsers",page,perPage)
    },
    get: {
        description: 'get one users data, using email or id',
        usage: 'get <username>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"cognitoUsers",id)
    },
    groups:{
        description: 'returns a list of CongitoGroups the user belongs to',
        usage: 'groups <username>',
        fn: async (terminal, username) =>{
            try{
                const result = await listGroupsForUser(username);
                terminal.log(prettyoutput(result));
                return 
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    addToGroup:{
        description: 'adds the user to the group',
        usage: 'addToGroup <username> <groupname>',    
        fn: async (terminal, username, groupname) =>{
            try{
                const result = await addUserToGroup(username, groupname);
                terminal.log(prettyoutput(result));
                return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    removeFromGroup:{
        description: 'removes the user from the group',
        usage: 'removeFromGroup <username> <groupname>',    
        fn: async (terminal, username, groupname) =>{
            try{
                const result = await removeUserFromGroup(username, groupname);
                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    enable:{
        description: 'enables a cognito user',
        usage: 'enable <username>',    
        fn: async (terminal,username) =>{
            try{
                const result = await enableUser(username);
                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }   
    },
    disable:{
        description: 'disables a cognito user',
        usage: 'disable <username>',    
        fn: async (terminal, username) =>{
            try{
                const result = await disableUser(username);
                terminal.log(prettyoutput(result));
                return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    }, 
    delete:{
        description: 'deletes a cognito user',
        usage: 'delete <username>',    
        fn: async (terminal,username) =>{
            try{
                const result = await deleteUser(username);
                terminal.log(prettyoutput(result));
                return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    }
}

export default cognitoCommands;