/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTour = /* GraphQL */ `
  query GetTour($id: ID!) {
    getTour(id: $id) {
      id
      origin
      schedule
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTours = /* GraphQL */ `
  query ListTours(
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        origin
        schedule
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTours = /* GraphQL */ `
  query SyncTours(
    $filter: ModelTourFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTours(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        origin
        schedule
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlaylist = /* GraphQL */ `
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      id
      name
      sequence
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      imageFiles {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listPlaylists = /* GraphQL */ `
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlaylists = /* GraphQL */ `
  query SyncPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlaylists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoFile = /* GraphQL */ `
  query GetVideoFile($id: ID!) {
    getVideoFile(id: $id) {
      id
      title
      posterStorageKey
      videoStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listVideoFiles = /* GraphQL */ `
  query ListVideoFiles(
    $filter: ModelVideoFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoFiles = /* GraphQL */ `
  query SyncVideoFiles(
    $filter: ModelVideoFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        posterStorageKey
        videoStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getContentPage = /* GraphQL */ `
  query GetContentPage($id: ID!) {
    getContentPage(id: $id) {
      id
      uniqueName
      titleTranslationKey
      sloganTranslationKey
      descriptionTranslationKey
      contactID
      linkOutTranslationKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      playlist {
        id
        name
        sequence
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imageFiles {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            playlistID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      announcements {
        items {
          id
          text
          startDate
          endDate
          url
          contentpageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentFiles {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videoFiles {
        items {
          id
          contentpageID
          videofileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listContentPages = /* GraphQL */ `
  query ListContentPages(
    $filter: ModelContentPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentPages = /* GraphQL */ `
  query SyncContentPages(
    $filter: ModelContentPageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentPages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        uniqueName
        titleTranslationKey
        sloganTranslationKey
        descriptionTranslationKey
        contactID
        linkOutTranslationKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        announcements {
          items {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentFiles {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videoFiles {
          items {
            id
            contentpageID
            videofileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getBadge = /* GraphQL */ `
  query GetBadge($id: ID!) {
    getBadge(id: $id) {
      id
      text
      url
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          badgeID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listBadges = /* GraphQL */ `
  query ListBadges(
    $filter: ModelBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBadges = /* GraphQL */ `
  query SyncBadges(
    $filter: ModelBadgeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBadges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        url
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            badgeID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID!) {
    getAnnouncement(id: $id) {
      id
      text
      startDate
      endDate
      url
      contentpageID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        startDate
        endDate
        url
        contentpageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAnnouncements = /* GraphQL */ `
  query SyncAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnnouncements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        startDate
        endDate
        url
        contentpageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      name
      jobTitle
      phone
      fax
      email
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      contentPages {
        items {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        jobTitle
        phone
        fax
        email
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        contentPages {
          items {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        jobTitle
        phone
        fax
        email
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        contentPages {
          items {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocumentFile = /* GraphQL */ `
  query GetDocumentFile($id: ID!) {
    getDocumentFile(id: $id) {
      id
      title
      documentStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      languages {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      contentpages {
        items {
          id
          contentpageID
          documentfileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          contentpage {
            id
            uniqueName
            titleTranslationKey
            sloganTranslationKey
            descriptionTranslationKey
            contactID
            linkOutTranslationKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listDocumentFiles = /* GraphQL */ `
  query ListDocumentFiles(
    $filter: ModelDocumentFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocumentFiles = /* GraphQL */ `
  query SyncDocumentFiles(
    $filter: ModelDocumentFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocumentFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        documentStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        languages {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        contentpages {
          items {
            id
            contentpageID
            documentfileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getImageFile = /* GraphQL */ `
  query GetImageFile($id: ID!) {
    getImageFile(id: $id) {
      id
      imageStorageKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ImageFileLanguages {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      playlists {
        items {
          id
          playlistID
          imagefileID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listImageFiles = /* GraphQL */ `
  query ListImageFiles(
    $filter: ModelImageFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImageFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncImageFiles = /* GraphQL */ `
  query SyncImageFiles(
    $filter: ModelImageFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImageFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        imageStorageKey
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        ImageFileLanguages {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        playlists {
          items {
            id
            playlistID
            imagefileID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTranslationKey = /* GraphQL */ `
  query GetTranslationKey($id: ID!) {
    getTranslationKey(id: $id) {
      id
      key
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listTranslationKeys = /* GraphQL */ `
  query ListTranslationKeys(
    $filter: ModelTranslationKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranslationKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTranslationKeys = /* GraphQL */ `
  query SyncTranslationKeys(
    $filter: ModelTranslationKeyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranslationKeys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        key
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($id: ID!) {
    getLanguage(id: $id) {
      id
      locale
      nativeName
      displayName
      published
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      translations {
        items {
          id
          translationkeyID
          languageID
          value
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      imagefiles {
        items {
          id
          imagefileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imagefile {
            id
            imageStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      videofiles {
        items {
          id
          videofileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          videofile {
            id
            title
            posterStorageKey
            videoStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      documentfiles {
        items {
          id
          documentfileID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          documentfile {
            id
            title
            documentStorageKey
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      announcements {
        items {
          id
          announcementID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          announcement {
            id
            text
            startDate
            endDate
            url
            contentpageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
      badges {
        items {
          id
          badgeID
          languageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          badge {
            id
            text
            url
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          language {
            id
            locale
            nativeName
            displayName
            published
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        locale
        nativeName
        displayName
        published
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        translations {
          items {
            id
            translationkeyID
            languageID
            value
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        imagefiles {
          items {
            id
            imagefileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        videofiles {
          items {
            id
            videofileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        documentfiles {
          items {
            id
            documentfileID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        announcements {
          items {
            id
            announcementID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
        badges {
          items {
            id
            badgeID
            languageID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTranslation = /* GraphQL */ `
  query GetTranslation($id: ID!) {
    getTranslation(id: $id) {
      id
      translationkeyID
      languageID
      value
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTranslations = /* GraphQL */ `
  query ListTranslations(
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranslations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        translationkeyID
        languageID
        value
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTranslations = /* GraphQL */ `
  query SyncTranslations(
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranslations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        translationkeyID
        languageID
        value
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncImageFileLanguages = /* GraphQL */ `
  query SyncImageFileLanguages(
    $filter: ModelImageFileLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImageFileLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        imagefileID
        languageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        imagefile {
          id
          imageStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          ImageFileLanguages {
            nextToken
            startedAt
          }
          playlists {
            nextToken
            startedAt
          }
        }
        language {
          id
          locale
          nativeName
          displayName
          published
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          translations {
            nextToken
            startedAt
          }
          imagefiles {
            nextToken
            startedAt
          }
          videofiles {
            nextToken
            startedAt
          }
          documentfiles {
            nextToken
            startedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoFileLanguages = /* GraphQL */ `
  query SyncVideoFileLanguages(
    $filter: ModelVideoFileLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoFileLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videofileID
        languageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        videofile {
          id
          title
          posterStorageKey
          videoStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
          playlists {
            nextToken
            startedAt
          }
        }
        language {
          id
          locale
          nativeName
          displayName
          published
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          translations {
            nextToken
            startedAt
          }
          imagefiles {
            nextToken
            startedAt
          }
          videofiles {
            nextToken
            startedAt
          }
          documentfiles {
            nextToken
            startedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocumentFileLanguages = /* GraphQL */ `
  query SyncDocumentFileLanguages(
    $filter: ModelDocumentFileLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocumentFileLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        documentfileID
        languageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        documentfile {
          id
          title
          documentStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
        }
        language {
          id
          locale
          nativeName
          displayName
          published
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          translations {
            nextToken
            startedAt
          }
          imagefiles {
            nextToken
            startedAt
          }
          videofiles {
            nextToken
            startedAt
          }
          documentfiles {
            nextToken
            startedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAnnouncementLanguages = /* GraphQL */ `
  query SyncAnnouncementLanguages(
    $filter: ModelAnnouncementLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAnnouncementLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        announcementID
        languageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        announcement {
          id
          text
          startDate
          endDate
          url
          contentpageID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
        }
        language {
          id
          locale
          nativeName
          displayName
          published
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          translations {
            nextToken
            startedAt
          }
          imagefiles {
            nextToken
            startedAt
          }
          videofiles {
            nextToken
            startedAt
          }
          documentfiles {
            nextToken
            startedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBadgeLanguages = /* GraphQL */ `
  query SyncBadgeLanguages(
    $filter: ModelBadgeLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBadgeLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        badgeID
        languageID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        badge {
          id
          text
          url
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
        }
        language {
          id
          locale
          nativeName
          displayName
          published
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          translations {
            nextToken
            startedAt
          }
          imagefiles {
            nextToken
            startedAt
          }
          videofiles {
            nextToken
            startedAt
          }
          documentfiles {
            nextToken
            startedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentPageBadges = /* GraphQL */ `
  query SyncContentPageBadges(
    $filter: ModelContentPageBadgeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentPageBadges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        contentpageID
        badgeID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        contentpage {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        badge {
          id
          text
          url
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentPageDocumentFiles = /* GraphQL */ `
  query SyncContentPageDocumentFiles(
    $filter: ModelContentPageDocumentFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentPageDocumentFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        contentpageID
        documentfileID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        contentpage {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        documentfile {
          id
          title
          documentStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentPageVideoFiles = /* GraphQL */ `
  query SyncContentPageVideoFiles(
    $filter: ModelContentPageVideoFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentPageVideoFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        contentpageID
        videofileID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        videofile {
          id
          title
          posterStorageKey
          videoStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
          playlists {
            nextToken
            startedAt
          }
        }
        contentpage {
          id
          uniqueName
          titleTranslationKey
          sloganTranslationKey
          descriptionTranslationKey
          contactID
          linkOutTranslationKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          playlist {
            id
            name
            sequence
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          announcements {
            nextToken
            startedAt
          }
          badges {
            nextToken
            startedAt
          }
          documentFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlaylistImageFiles = /* GraphQL */ `
  query SyncPlaylistImageFiles(
    $filter: ModelPlaylistImageFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlaylistImageFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        playlistID
        imagefileID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        imagefile {
          id
          imageStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          ImageFileLanguages {
            nextToken
            startedAt
          }
          playlists {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlaylistVideoFiles = /* GraphQL */ `
  query SyncPlaylistVideoFiles(
    $filter: ModelPlaylistVideoFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlaylistVideoFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        playlistID
        videofileID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        playlist {
          id
          name
          sequence
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          imageFiles {
            nextToken
            startedAt
          }
          videoFiles {
            nextToken
            startedAt
          }
        }
        videofile {
          id
          title
          posterStorageKey
          videoStorageKey
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          languages {
            nextToken
            startedAt
          }
          contentpages {
            nextToken
            startedAt
          }
          playlists {
            nextToken
            startedAt
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
