
import React from "react";

import {
  required,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  FileInput,
  TextInput,
  TextField,
  DateField,
  BulkDeleteButton,
  FileField
} from 'react-admin';

import S3FileNameField from "../../components/Storage/S3FileNameField";
import { transformFileAttachment } from "../../dataprovider/datastoreProvider"
import SaveCancelToolbar from "../../components/Toolbars/SaveCancelToolbar";
import { LocalisationField, LocalisationInput } from "../../components/Associations/LocalisationField";
import { DetailsPanel, DetailsDateField, DetailsFileField } from "../../components/DetailsPanel";
import { DocumentPlaceholder } from "../../components/Fields/FileDropField";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";

const DocumentFilesTitle = ({ record }) => {
  return <span>
    Documents - {record.title}
  </span>;
};

const DocumentFileFilters = [
  <TextInput
    label="Search titles"
    source="title.contains"
    alwaysOn
    key='searchFilter'
  />
];

const DocumentFileActionButtons = props => (
  <>
    <BulkDeleteButton
      {...props}
      undoable={false}
    />
  </>
);

export const DocumentFileList = (props) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={DocumentFileFilters}
      bulkActionButtons={<DocumentFileActionButtons />}
      pagination={<DefaultPagination/>}   
      perPage={PerPageDefaults.min}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField source="title" />
        <S3FileNameField label="File name" source="documentStorageKey" />
        <LocalisationField label='Localisation' sortable={false} />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Datagrid>
    </List >
  )
};

export const DocumentFileEdit = props => {

  const transform = record => transformFileAttachment(record, ['_documentStorageKey'], { level: 'public' });

  return (

    <Edit
      title={<DocumentFilesTitle />}
      {...props}
      transform={transform}
      actions={false}
      mutationMode='pessimistic'
      aside={<DocumentFileDetails />}
    >
      <SimpleForm toolbar={<SaveCancelToolbar />}>
        <LocalisationInput />
        <TextInput source="title" variant="standard" />
        <FileInput
          label='Upload new document'
          source='_documentStorageKey'
          accept='application/pdf,.doc'
          placeholder={<DocumentPlaceholder/>}
        >
          <FileField
            source="_documentStorageKey"
            title="title"
            src="_documentStorageKey"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Edit>
  )

};




export const DocumentFileCreate = (props) => {

  const transform = record => transformFileAttachment(record, ['_documentStorageKey'], { level: 'public' });

  return (
    <Create
      {...props}
      transform={transform}
    >
      <SimpleForm redirect="list" toolbar={<SaveCancelToolbar />}>
        <LocalisationInput />
        <TextInput source="title" validate={required()} variant="standard" />
        <FileInput
          label='Upload document'
          source='_documentStorageKey'
          accept='application/pdf,.doc'       
          placeholder={<DocumentPlaceholder/>}
          validate={required()}
        >
          <FileField
            source="_documentStorageKey"
            title="title"
            src="_documentStorageKey"
            target="_blank"
          />
        </FileInput>
      </SimpleForm>
    </Create>

  );
}


const DocumentFileDetails = () => (
  <DetailsPanel>
    <DetailsFileField label="Document" source="documentStorageKey"/>
    <DetailsDateField label="Created at" source="createdAt" />
    <DetailsDateField label="Updated at" source="updatedAt" />
  </DetailsPanel>
)



