// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { RECORD_LOCATION } from "../../redux/action";


const HistoryRecorder = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) dispatch({type:RECORD_LOCATION, payload:location});
  }, [location.pathname])

  return null;
}

export default HistoryRecorder;