import React from 'react';
import { Box, Divider } from '@material-ui/core';

import Imprint from './Imprint';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';

const LegalText = () => {

  return (
    <>
      <Box marginX="24px" marginTop="36px" marginBottom="16px" maxWidth="1080px">
        <Imprint/>
        <Box height = '1.5em'/>
        {/*<Divider/>*/}
        <Box height = '1em'/>
        <PrivacyPolicy/>
        <Box height = '1.5em'/>
        {/*<Divider/>
        <Box height = '1em'/>
        <TermsOfUse/>*/}
      </Box>
    </>
  )
}

export default LegalText;
