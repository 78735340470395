
import React from 'react';
import Markdown from './LegalMarkdown';

const imprint = 
`
### Imprint
[Impressum](https://www.leistungszentrum-mikronano.de/de/impressum.html)
<br>
`;

const Imprint = () => <Markdown>{imprint}</Markdown>

export default Imprint;
