import prettyoutput from 'prettyoutput';
import { Storage } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import * as models from '../../models'
import * as _ from 'lodash';
import { languages as languageNames } from '../../resources/internationalisation/languageCodes';
import { attachStorageId, splitStorageId } from '../../dataprovider/datastoreProvider';
import Papa from 'papaparse';
const csvPickerOptions =
{
  types: [{ description: 'CSV', accept: { 'text/*': ['.csv'] } },],
  excludeAcceptAllOption: true,
  multiple: false
};

const jsonPickerOptions =
{
  types: [{ description: 'JSON', accept: { 'application/json': ['.json'] } },],
  excludeAcceptAllOption: true,
  multiple: false
};

const imagesPickerOptions =
{
  types: [{ description: 'Images', accept: { 'image/*': ['.png', '.jpeg', '.jpg'] } },],
  excludeAcceptAllOption: true,
  multiple: true
};



const abbreviateListForLog = (list) => {
  if (list.length < 16) return list;
  return [
    ...list.slice(0, 5),
    '...',
    `... (${list.length - 10} more) `,
    '...',
    ...list.slice(-5)]
}

const getFolderContents = async (dirHandle) => {

  const isImage = (filename) => ['jpg', 'png'].includes(...filename.split('.').slice(-1));
  const isJson = (filename) => ['json'].includes(...filename.split('.').slice(-1));
  const isVideo = (filename) => ['mp4'].includes(...filename.split('.').slice(-1));
  const isDocument = (filename) => ['pdf'].includes(...filename.split('.').slice(-1))

  const data = [];
  const images = [];
  const videos = [];
  const documents = [];
  for await (let [name, handle] of dirHandle) {
    if (handle.kind === 'file' && isImage(handle.name)) images.push(handle);
    if (handle.kind === 'file' && isJson(handle.name)) data.push(handle);
    if (handle.kind === 'file' && isVideo(handle.name)) videos.push(handle);
    if (handle.kind === 'file' && isDocument(handle.name)) documents.push(handle);
  }
  return { data, images, videos, documents };
}

const uploadFile = async (fileHandle) => {
  const name = fileHandle.name;
  const remotename = attachStorageId(name);
  const rawFile = await fileHandle.getFile();

  const remoteFileName = await Storage.put(
    remotename,
    rawFile,
    { level: 'public' }
  );

  return { name: name, key: remoteFileName.key };
}

const applicationCommands = {
  getdict: {
    description: 'get the translation dictionary',
    usage: 'getdict',
    fn: async (terminal) => {

      const languages = (await DataStore.query(models.Language)).filter(l => l.published);
      const translation = await DataStore.query(models.Translation);
      const translationkeys = await DataStore.query(models.TranslationKey);

      const language_id_map = new Map(languages.map(l => [l.id, l]));
      const translationkey_id_map = new Map(translationkeys.map(t => [t.id, t]));

      let dictionary = {}
      languages.forEach(l => dictionary[l.locale] = {});
      translation
        .filter(t => translationkey_id_map.has(t.translationkeyID))
        .filter(t => language_id_map.has(t.languageID))
        .forEach(t => {
          const locale = language_id_map.get(t.languageID).locale;
          const key = translationkey_id_map.get(t.translationkeyID).key;
          dictionary[locale][key] = t.value;
        });

      console.log(dictionary);
      terminal.log(prettyoutput(dictionary));
      return;
    }
  },
  listS3: {
    description: 'saves a json containng all currently used s3 resources',
    usage: 'listS3 ',
    fn: async (terminal) => {

      const allfiles = await Storage.list('')
      const fileMap = new Map(allfiles.map(af => [af.key, af]));

      const images = await DataStore.query(models.ImageFile);
      const videos = await DataStore.query(models.VideoFile);
      const documents = await DataStore.query(models.DocumentFile);
      const contacs = await DataStore.query(models.Contact);

      let files = {};
      images.forEach(i => files[i.imageStorageKey] = fileMap.get(i.imageStorageKey));
      videos.forEach(v => {
        files[v.posterStorageKey] = fileMap.get(v.posterStorageKey);
        files[v.videoStorageKey] = fileMap.get(v.videoStorageKey);
        });
      documents.forEach(d => files[d.documentStorageKey] = fileMap.get(d.documentStorageKey));
      contacs.forEach(c => files[c.imageStorageKey] = fileMap.get(c.imageStorageKey));

      terminal.log(`Currently ${_.keys(files).length} of ${allfiles.length} files are in use.`)
      const filenames = _.keys(files).map(fn => splitStorageId(fn)[1])
      terminal.log(prettyoutput(filenames));
      console.log("S3 Files", filenames);
      return;
    }
  },
  deletei18n: {
    description: 'remove all translationKeys listed in the csv',
    usage: 'deletei18n',
    fn: async (terminal, whatif) => {
      try {
        let [fileHandle] = await window.showOpenFilePicker(csvPickerOptions);
        const fileData = await fileHandle.getFile();
        const csv = await fileData.text();
        const table = Papa.parse(csv);

        const translationKeys = await DataStore.query(models.TranslationKey);
        const translationKeys_keyMap = new Map(translationKeys.map(t => [t.key, t]));

        const keys = table.data.slice(1).map(row => row[0]).filter(k => k);

        const existing = keys.filter(k => translationKeys_keyMap.has(k)).map(k => translationKeys_keyMap.get(k));

        if (!whatif) {
          const result = await Promise.all(existing.map(k => DataStore.delete(k)));
          terminal.log(`Deleted ${result.length} translation keys.`)
        }
        else {
          terminal.log(`Would have deleted ${existing.length} translation keys.`)
        }

      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  updatei18n: {
    description: 'update the translations from the google spreadsheet. add whatif to test without actual changes',
    usage: 'updatei18n (whatif)',
    fn: async (terminal, whatif) => {
      try {
        let [fileHandle] = await window.showOpenFilePicker(csvPickerOptions);
        const fileData = await fileHandle.getFile();
        const csv = await fileData.text();
        const table = Papa.parse(csv);

        const languages = await DataStore.query(models.Language);
        const languages_localeMap = new Map(languages.map(l => [l.locale, l]));
        const languages_idMap = new Map(languages.map(l => [l.id, l]));

        const translationKeys = await DataStore.query(models.TranslationKey);
        const translationKeys_keyMap = new Map(translationKeys.map(t => [t.key, t]));
        const translationKeys_idMap = new Map(translationKeys.map(t => [t.id, t]));

        const translations = await DataStore.query(models.Translation);

        const concatIds = (id1, id2) => `${id1}-${id2}`;
        const translations_langKeyIdMap = new Map(translations.map(t => [concatIds(t.languageID, t.translationkeyID), t]));


        const updateLanguages = async (locales) => {

          let result = [];
          const new_locales = locales.filter(l => !languages_localeMap.has(l));
          const new_languages = new_locales
            .filter(l => languageNames[l])
            .map(l => {
              return new models.Language({
                locale: l,
                nativeName: languageNames[l].nativeName,
                displayName: languageNames[l].displayName,
                published: false
              })
            });

          if (!_.isEmpty(new_locales)) {
            terminal.log('# New locales found:');
            terminal.log(prettyoutput(new_locales));
          }

          if (!_.isEmpty(new_languages)) {
            terminal.log('# Adding languages:');
            terminal.log(prettyoutput(abbreviateListForLog(new_languages)));

            if (whatif) {
              result = new_languages;
              terminal.log(`# Would have added ${new_languages.length} language(s).`);
            }
            else {
              result = await Promise.all(new_languages.map(l => DataStore.save(l)));
              terminal.log(`# Added ${result.length} language(s).`);
            }

          }
          else terminal.log('# Languages up to date.');

          return result;
        }

        const updateTranslationKeys = async (translationKeys) => {
          let result = [];
          const new_translationKeys = translationKeys
            .filter(t => !translationKeys_keyMap.has(t))
            .map(k => new models.TranslationKey({ key: k }));

          if (!_.isEmpty(new_translationKeys)) {
            terminal.log("# Adding translation keys:");
            const loglist = abbreviateListForLog(new_translationKeys.map(tk => tk.key));
            terminal.log(prettyoutput(loglist));

            if (whatif) {
              result = new_translationKeys;
              terminal.log(`# Would have added ${new_translationKeys.length} translation key(s).`);
            }
            else {
              result = await Promise.all(new_translationKeys.map(tk => DataStore.save(tk)));
              terminal.log(`# Added ${result.length} translation key(s).`);
            }
          }
          else terminal.log('# Translation keys up to date.');
          return result;
        }

        const updateTranslations = async (table) => {

          let toUpdate = [];
          let toAdd = [];

          for (let keyIndex = 1; keyIndex < table.data.length; keyIndex++) {
            const key = table.data[keyIndex][0];

            if (!translationKeys_keyMap.has(key)) continue;
            const keyId = translationKeys_keyMap.get(key).id;
            for (let localeIdex = 1; localeIdex < table.data[0].length; localeIdex++) {
              const locale = table.data[0][localeIdex];
              if (!languages_localeMap.has(locale)) continue;
              const languageId = languages_localeMap.get(locale).id;
              const value = table.data[keyIndex][localeIdex];

              if (translations_langKeyIdMap.has(concatIds(languageId, keyId))) {
                const translation = translations_langKeyIdMap.get(concatIds(languageId, keyId));
                if (value === translation.value) continue;
                const updated = models.Translation.copyOf(translation, updated => Object.assign(updated, { value: value }));
                toUpdate.push(updated);
              }
              else {
                const translation = new models.Translation(
                  {
                    translationkeyID: keyId,
                    languageID: languageId,
                    value: value
                  });
                toAdd.push(translation);
              }
            }
          }

          if (!_.isEmpty(toUpdate)) {
            terminal.log("# Updating translations:");
            const loglist = abbreviateListForLog(toUpdate.map(l =>
              `${translationKeys_idMap.get(l.translationkeyID).key} | ${languages_idMap.get(l.languageID).locale} -> ${l.value.slice(25)}${l.value.length > 25 ? '...' : ''}`
            ));
            terminal.log(prettyoutput(loglist));

            if (whatif) {
              terminal.log(`# Would have updated ${toUpdate.length} translation(s).`);
            }
            else {
              const result = await Promise.all(toUpdate.map(t => DataStore.save(t)));
              terminal.log(`# Update ${result.length} translation(s).`);
            }
          }

          if (!_.isEmpty(toAdd)) {
            terminal.log("# Adding translations:");
            console.log('toAdd', toAdd);
            const loglist = abbreviateListForLog(toAdd.map(l =>
              `${translationKeys_idMap.get(l.translationkeyID).key} | ${languages_idMap.get(l.languageID).locale} -> ${l.value.slice(25)} ${l.value.length > 25 ? '...' : ''}`
            ));
            terminal.log(prettyoutput(loglist));
            if (whatif) {
              terminal.log(`# Would have added ${toAdd.length} translation(s).`);
            }
            else {
              const result = await Promise.all(toAdd.map(t => DataStore.save(t)));
              terminal.log(`# Added ${result.length} translation(s).`);
            }
          }

          if (_.isEmpty(toAdd) && _.isEmpty(toUpdate)) {
            terminal.log('# Translations up to date.');
          }
        }

        //update languages from csv header
        const locales = table.data[0].slice(1).filter(l => l);
        const newLanguages = await updateLanguages(locales);
        if (!_.isEmpty(newLanguages)) {
          newLanguages.forEach(new_lang => {
            languages.push(new_lang);
            languages_localeMap.set(new_lang.locale, new_lang);
            languages_idMap.set(new_lang.id, new_lang);
          });
        }

        // update translation keys from 1st column of csv
        const keys = table.data.slice(1).map(row => row[0]).filter(k => k);
        const newKeys = await updateTranslationKeys(keys)
        if (!_.isEmpty(newKeys)) {
          newKeys.forEach(new_key => {
            translationKeys.push(new_key);
            translationKeys_keyMap.set(new_key.key, new_key);
            translationKeys_idMap.set(new_key.id, new_key);
          });
        }

        await updateTranslations(table);
        terminal.log('# Import completed.')

        return;
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  updatepages: {
    description: 'add or update content pages from json description',
    usage: 'updatepages',
    fn: async (terminal, whatif) => {
      try {
        let [fileHandle] = await window.showOpenFilePicker(jsonPickerOptions);
        const fileData = await fileHandle.getFile();
        const pages_data = JSON.parse(await fileData.text());

        const contentPages = await DataStore.query(models.ContentPage);
        const contentPages_nameMap = new Map(contentPages.map(cp => [cp.uniqueName, cp]));

        // add missing playlists
        const playlists = await DataStore.query(models.Playlist);
        const playlists_nameMap = new Map(playlists.map(p => [p.name, p]));

        const new_playlists = pages_data
          .filter(p => !playlists_nameMap.has(p.uniqueName))
          .map(p => new models.Playlist({ name: p.uniqueName, sequence: [] }))

        if (!_.isEmpty(new_playlists)) {
          terminal.log('# Adding playlists:')
          const loglist = abbreviateListForLog(new_playlists.map(p => p.name));
          terminal.log(prettyoutput(loglist));

          let result = []
          if (!whatif) {
            result = await Promise.all(new_playlists.map(np => DataStore.save(np)));
            terminal.log(`# Added ${result.length} playlist(s).`);
          }
          else {
            result = new_playlists;
            terminal.log(`# Would have added ${result.length} playlist(s).`);
          }

          result.forEach(p => {
            playlists_nameMap.set(p.name, p);
          });
        }
        else {
          terminal.log('# Playlists up to date.')
        }

        // add or update pages
        const toAdd = pages_data
          .filter(p => !contentPages_nameMap.has(p.uniqueName))
          .filter(p => playlists_nameMap.has(p.uniqueName))
          .map(p => {
            try {
              const model = new models.ContentPage(
                {
                  uniqueName: p.uniqueName,
                  titleTranslationKey: p.titleTranslationKey,
                  sloganTranslationKey: p.sloganTranslationKey,
                  descriptionTranslationKey: p.descriptionTranslationKey,
                  url: p.url,
                  playlist: playlists_nameMap.get(p.uniqueName)
                });
              return model;
            }
            catch (err) {
              console.error(`model creation failed: ${err.message}`, {
                uniqueName: p.uniqueName,
                titleTranslationKey: p.titleTranslationKey,
                sloganTranslationKey: p.sloganTranslationKey,
                descriptionTranslationKey: p.descriptionTranslationKey,
                url: p.url,
                playlist: playlists_nameMap.get(p.uniqueName)
              });
            }
          });

        const toUpdate = pages_data
          .filter(p => contentPages_nameMap.has(p.uniqueName))
          .filter(p => playlists_nameMap.has(p.uniqueName))
          .map(p => {
            const original = contentPages_nameMap.get(p.uniqueName);
            try {
              const updated = models.ContentPage.copyOf(
                original,
                updated => Object.assign(updated,
                  {
                    uniqueName: p.uniqueName,
                    titleTranslationKey: p.titleTranslationKey,
                    sloganTranslationKey: p.sloganTranslationKey,
                    descriptionTranslationKey: p.descriptionTranslationKey,
                    url: p.url,
                    playlist: playlists_nameMap.get(p.uniqueName)
                  })
              );
              return updated;
            }
            catch (err) {
              console.error(`model update failed: ${err.message}`, {
                uniqueName: p.uniqueName,
                titleTranslationKey: p.titleTranslationKey,
                sloganTranslationKey: p.sloganTranslationKey,
                descriptionTranslationKey: p.descriptionTranslationKey,
                url: p.url,
                playlist: playlists_nameMap.get(p.uniqueName)
              });
            }
          }
          );

        if (!_.isEmpty(toUpdate)) {
          terminal.log('# Updating contentpages:')
          const loglist = abbreviateListForLog(toUpdate.map(tu => tu.uniqueName));
          terminal.log(prettyoutput(loglist));

          if (!whatif) {
            const result = await Promise.all(toUpdate.map(tu => DataStore.save(tu)));
            terminal.log(`# Updated ${result.length} contentpages.`);
          }
          else {
            terminal.log(`# Would have updated ${toUpdate.length} contentpages.`);
          }
        }

        if (!_.isEmpty(toAdd)) {
          terminal.log('# Adding contentpages:')
          const loglist = abbreviateListForLog(toAdd.map(ta => ta.uniqueName));
          terminal.log(prettyoutput(loglist));

          if (!whatif) {
            const result = await Promise.all(toAdd.map(ta => DataStore.save(ta)));
            terminal.log(`# ADded ${result.length} contentpages.`);
          }
          else {
            terminal.log(`# Would have added ${toAdd.length} contentpages.`);
          }
        }

        if (_.isEmpty(toUpdate) && _.isEmpty(toAdd)) {
          terminal.log('# ContentPages up to date.');
        }

      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  addbadges: {
    description: 'add badges from json description',
    usage: 'addbadges (whatif)',
    fn: async (terminal, whatif) => {
      try {
        let [fileHandle] = await window.showOpenFilePicker(jsonPickerOptions);
        const fileData = await fileHandle.getFile();
        const badges_data = JSON.parse(await fileData.text());

        const contentPages = await DataStore.query(models.ContentPage);
        const contentPage_nameMap = new Map(contentPages.map(cp => [cp.uniqueName, cp]));

        const languages = await DataStore.query(models.Language);
        const language_localeMap = new Map(languages.map(l => [l.locale, l]));

        const newBadges = badges_data.map(bd => {
          const languageIds = bd._locales
            .filter(l => language_localeMap.has(l))
            .map(l => language_localeMap.get(l).id);

          const pageIds = bd._contentPageNames
            .filter(n => contentPage_nameMap.has(n))
            .map(n => contentPage_nameMap.get(n).id);

          if (languageIds.length != bd._locales.length) {
            terminal.log(`Not all localisation were associated with Badge: ${bd.text}`);
          }

          if (pageIds.length != bd._contentPageNames.length) {
            terminal.log(`Not all contentpages were associated with Badge: ${bd.text}`);
          }

          let record = {
            text: bd.text,
            languages: languageIds,
            contentpages: pageIds,
          };
          if (bd.url != "") record['url'] = bd.url;

          return record;
        });

        terminal.log('# Adding badges:')
        const loglist = abbreviateListForLog(newBadges.map(nb => nb.text));
        terminal.log(prettyoutput(loglist));

        if (!whatif) {

          const result = await Promise.all(newBadges.map(nb => terminal.dataProvider.create("Badge", { data: nb })));
          terminal.log(`# Added ${result.length} badges`);
        }
        else {
          terminal.log(`# Would have added ${newBadges.length} badges`);
        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  purgebadges: {
    description: 'remove all badges',
    usage: 'purgebadges (whatif)',
    fn: async (terminal, whatif) => {
      try {

        const badges = await DataStore.query(models.Badge);

        terminal.log('# Deleting badges:');
        const loglist = abbreviateListForLog(badges.map(b => b.text));
        terminal.log(prettyoutput(loglist));

        if (!whatif) {
          const result = await Promise.all(badges.map(b => DataStore.delete(b)));
          terminal.log(`# Deleted ${result.length} badges.`);
        }
        else {
          terminal.log(`# Would have deleted ${badges.length} badges.`);
        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  addcontacts: {
    description: 'add contacts from folder with json description and images',
    usage: 'addcontacts (whatif)',
    fn: async (terminal, whatif) => {
      try {

        const contentPages = await DataStore.query(models.ContentPage);
        const contentPage_nameMap = new Map(contentPages.map(cp => [cp.uniqueName, cp]));

        const uploadContacts = async (contact_data, imagekey) => {
          const { _contentPageNames, _imageFileName, ...record } = contact_data;
          const result = await DataStore.save(new models.Contact({ ...record, imageStorageKey: imagekey }));
          return { contact: result, pages: _contentPageNames };
        }

        const dirHandle = await window.showDirectoryPicker();
        const { data, images } = await getFolderContents(dirHandle);
        if (data.length > 1) throw new Error("# ERROR Folder may only contain one contact definition.");

        const fileData = await data[0].getFile();
        const contacts_data = JSON.parse(await fileData.text());

        terminal.log('# Adding contacts:')
        const loglist = abbreviateListForLog(contacts_data.map(cd => `${cd.name} - ${cd.jobTitle}`));
        terminal.log(prettyoutput(loglist));

        terminal.log(`# Uploading ${images.length} images.`)
        const imagefile_nameKeyMap = new Map();
        if (!whatif) {
          const result = await Promise.all(images.map(i => uploadFile(i)));
          result.forEach(r => imagefile_nameKeyMap.set(r.name, r.key));
          terminal.log(`# Uploaded ${result.length} images.`)
        }
        else {
          terminal.log(`# Would have uploaded ${images.length} images.`)
          images.map(i => { return { name: i.name, key: attachStorageId(i.name) } }).forEach(n => imagefile_nameKeyMap.set(n.name, n.key));
        }

        let contacts_pages = [];
        terminal.log(`# Uploading ${contacts_data.length} contacts.`)
        if (!whatif) {
          contacts_pages = await Promise.all(
            contacts_data
              .filter(cd => imagefile_nameKeyMap.has(cd._imageFileName))
              .map(cd => uploadContacts(cd, imagefile_nameKeyMap.get(cd._imageFileName)))
          );
          terminal.log(`# Uploaded ${contacts_pages.length} contacts.`);
        }
        else {
          contacts_pages = contacts_data
            .filter(cd => imagefile_nameKeyMap.has(cd._imageFileName))
            .map(cd => {
              const { _contentPageNames, _imageFileName, ...record } = cd;
              return { contact: { ...record, imageStorageKey: imagefile_nameKeyMap.get(cd._imageFileName) }, pages: cd._contentPageNames }
            })
          terminal.log(`# Would have uploaded ${contacts_data.length} contacts.`);
        }

        terminal.log('# Updating contentpages:')
        let toUpdate = []

        contacts_pages.forEach(cp => {
          cp.pages
            .filter(p => contentPage_nameMap.has(p))
            .forEach(p => {
              const page = contentPage_nameMap.get(p);
              const updatedPage = models.ContentPage.copyOf(page, updated => { updated.contactID = cp.contact.id });
              toUpdate.push(updatedPage);
            });
        });


        if (!whatif) {
          const results = await Promise.all(toUpdate.map(cp => DataStore.save(cp)));
          terminal.log(`# Updated ${results.length} contentpages.`);
        }
        else {
          terminal.log(`# Would have updated ${toUpdate.length} contentpages.`);
        }
        terminal.log('# Contact import completed.')

      }
      catch (error) {
        console.error(error);

        return error.message;
      }
    }
  },
  purgecontacts: {
    description: 'remove all contacts',
    usage: 'purgecontacts (whatif)',
    fn: async (terminal, whatif) => {
      try {

        const contacts = await DataStore.query(models.Contact);

        terminal.log('# Deleting contacts:');
        const loglist = abbreviateListForLog(contacts.map(b => b.email));
        terminal.log(prettyoutput(loglist));

        if (!whatif) {
          const result = await Promise.all(contacts.map(c => DataStore.delete(c)));
          terminal.log(`# Deleted ${result.length} contacts.`);
        }
        else {
          terminal.log(`# Would have deleted ${contacts.length} contacts.`);
        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  addimgs: {
    description: 'add images from folder with json description and image files',
    usage: 'addimgs (whatif)',
    fn: async (terminal, whatif) => {
      try {
        const uploadImageFile = async (image_data, image_key) => {
          const result = await DataStore.save(new models.ImageFile({ imageStorageKey: image_key }));
          return { imageFile: result, playlists: image_data.playlists, languages: image_data.locales };
        }
        const playlists = await DataStore.query(models.Playlist);
        const playlist_nameMap = new Map(playlists.map(pl => [pl.name, pl]));

        const languages = await DataStore.query(models.Language);
        const languages_localeMap = new Map(languages.map(l => [l.locale, l]));

        const dirHandle = await window.showDirectoryPicker();
        const { data, images } = await getFolderContents(dirHandle);
        if (data.length > 1) throw new Error("# ERROR Folder may only contain one contact definition.");

        const fileData = await data[0].getFile();
        const images_data = JSON.parse(await fileData.text());

        console.log(images_data);
        terminal.log('# Adding images:')
        const loglist = abbreviateListForLog(images_data.map(id => `${id.filename}`));
        terminal.log(prettyoutput(loglist));

        const imagefileSet = new Set(images_data.map(id => id.filename));
        terminal.log(`# Found ${images.length} images uploading ${images.filter(fh => imagefileSet.has(fh.name)).length} files.`)
        const imagefile_nameKeyMap = new Map();
        if (!whatif) {
          const result = await Promise.all(
            images
              .filter(fh => imagefileSet.has(fh.name))
              .map(i => uploadFile(i))
          );
          result.forEach(r => imagefile_nameKeyMap.set(r.name, r.key));
          terminal.log(`# Uploaded ${result.length} images files.`)
        }
        else {
          terminal.log(`# Would have uploaded ${images.length} images files.`)
          images.map(i => { return { name: i.name, key: attachStorageId(i.name) } }).forEach(n => imagefile_nameKeyMap.set(n.name, n.key));
        }

        let image_connections = [];
        terminal.log(`# Uploading ${images_data.length} image data sets.`)
        if (!whatif) {
          image_connections = await Promise.all(
            images_data
              .filter(id => imagefile_nameKeyMap.has(id.filename))
              .map(id => uploadImageFile(id, imagefile_nameKeyMap.get(id.filename)))
          );
          terminal.log(`# Uploaded ${image_connections.length} image data sets.`);
        }
        else {
          image_connections = images_data
            .filter(id => imagefile_nameKeyMap.has(id.filename))
            .map(id => {
              const { playlists, locales, filename } = id;
              return { imageFile: { imageStorageKey: imagefile_nameKeyMap.get(filename) }, playlists: playlists, languages: locales }
            })
          terminal.log(`# Would have uploaded ${image_connections.length} image data sets.`);
        }

        let image_languages = [];
        let plyalist_images = [];
        image_connections.forEach(ic => {
          const imageFile = ic.imageFile;
          ic.playlists.filter(playlist => playlist_nameMap.has(playlist))
            .forEach(pl => {
              const playlist = playlist_nameMap.get(pl);
              const playlistImage = new models.PlaylistImageFile({ playlist: playlist, imagefile: imageFile });
              plyalist_images.push(playlistImage);
            });
          ic.languages.filter(locale => languages_localeMap.has(locale))
            .forEach(locale => {
              const language = languages_localeMap.get(locale);
              const imageFileLanguage = new models.ImageFileLanguage({ language: language, imagefile: imageFile });
              image_languages.push(imageFileLanguage);
            });
        });

        console.log("Playlist Images", plyalist_images); //DEBUG #################
        console.log("Image Languages", image_languages); //DEBUG #################

        terminal.log(`# Setting localisations for ${image_languages.length} images.`);
        if (!whatif) {
          const result = await Promise.all(image_languages.map(il => DataStore.save(il)));
          terminal.log(`# Set localisations for ${result.length} images.`);
        }
        else {
          terminal.log(`# Would have set localisations for ${image_languages.length} images.`);
        }

        terminal.log(`# Adding ${plyalist_images.length} images to playlists.`);
        if (!whatif) {
          const result = await Promise.all(plyalist_images.map(pi => DataStore.save(pi)));
          terminal.log(`# Added ${result.length} images to playlists.`);
        }
        else {
          terminal.log(`# Would have added ${plyalist_images.length} images to playlists.`);
        }

        if (!whatif) {
          terminal.log(`# Updating playlists sequences.`);
          const playlists = await DataStore.query(models.Playlist);
          const playlistVideo = await DataStore.query(models.PlaylistVideoFile);
          const playlistImage = await DataStore.query(models.PlaylistImageFile)

          const updates = playlists.map(pl =>{
            const video_ids = playlistVideo.filter(pv => pv.playlist.id === pl.id).map(pv => pv.videofile.id);
            const image_ids = playlistImage.filter(pi => pi.playlist.id === pl.id).map(pi => pi.imagefile.id);

            const sequence = [...video_ids, ...image_ids];
            const updated = models.Playlist.copyOf(pl, updated => Object.assign(updated, { sequence: sequence }));
            return updated;
          })
          const result = await Promise.all(updates.map(updated => DataStore.save(updated))); 

        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  purgeimgs: {
    description: 'add images from folder with json description and image files',
    usage: 'purgeimgs (whatif)',
    fn: async (terminal, whatif) => {
      try {
        const images = await DataStore.query(models.ImageFile);

        terminal.log('# Deleting images:');
        const loglist = abbreviateListForLog(images.map(b => b.imageStorageKey));
        terminal.log(prettyoutput(loglist));

        if (!whatif) {
          const result = await Promise.all(images.map(c => DataStore.delete(c)));
          terminal.log(`# Deleted ${result.length} images.`);
        }
        else {
          terminal.log(`# Would have deleted ${images.length} images.`);
        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  adddocs: {
    description: 'add documents from folder with json description and document files',
    usage: 'adddocs (whatif)',
    fn: async (terminal, whatif) => {
      try {

        const uploadDocumentFile = async (document_data, document_key) => {
          const result = await DataStore.save(new models.DocumentFile({ documentStorageKey: document_key, title: document_data.title }));
          return { documentFile: result, pages: document_data.contentPageNames, languages: document_data.locales };
        }

        const languages = await DataStore.query(models.Language);
        const languages_localeMap = new Map(languages.map(l => [l.locale, l]));

        const contentPages = await DataStore.query(models.ContentPage);
        const contentPage_nameMap = new Map(contentPages.map(cp => [cp.uniqueName, cp]));

        const dirHandle = await window.showDirectoryPicker();
        const { data, documents } = await getFolderContents(dirHandle);
        if (data.length > 1) throw new Error("# ERROR Folder may only contain one contact definition.");

        const fileData = await data[0].getFile();
        const documents_data = JSON.parse(await fileData.text());

        console.log(documents_data);
        terminal.log('# Adding documents:')
        const loglist = abbreviateListForLog(documents_data.map(dd => `${dd.title} | ${dd.filename}`));
        terminal.log(prettyoutput(loglist));

        const documentFileSet = new Set(documents_data.map(id => id.filename));
        terminal.log(`# Found ${documents.length} documents uploading ${documents.filter(fh => documentFileSet.has(fh.name)).length} files.`)

        const documentfile_nameKeyMap = new Map();
        if (!whatif) {
          const result = await Promise.all(
            documents
              .filter(fh => documentFileSet.has(fh.name))
              .map(d => uploadFile(d))
          );
          result.forEach(r => documentfile_nameKeyMap.set(r.name, r.key));
          terminal.log(`# Uploaded ${result.length} document files.`)
        }
        else {
          terminal.log(`# Would have uploaded ${documents.length} images files.`)
          documents
            .map(d => { return { name: d.name, key: attachStorageId(d.name) } })
            .forEach(n => documentfile_nameKeyMap.set(n.name, n.key));
        }

        let document_connections = []
        terminal.log(`# Uploading ${documents_data.length} document data sets.`)

        if (!whatif) {
          document_connections = await Promise.all(
            documents_data
              .filter(dd => documentfile_nameKeyMap.has(dd.filename))
              .map(dd => uploadDocumentFile(dd, documentfile_nameKeyMap.get(dd.filename)))
          );
          terminal.log(`# Uploaded ${document_connections.length} document data sets.`);
        }
        else {
          document_connections = documents_data
            .filter(dd => documentfile_nameKeyMap.has(dd.filename))
            .map(dd => {
              const { contentPageNames, title, locales, filename } = dd;
              return {
                documentFile: {
                  documentStorageKey: documentfile_nameKeyMap.get(filename),
                  title: title
                },
                pages: contentPageNames,
                languages: locales
              }
            })
          terminal.log(`# Would have uploaded ${document_connections.length} document data sets.`);
        }

        let document_languages = [];
        let contentPage_documents = [];
        document_connections.forEach(dc => {
          const { documentFile } = dc;
          dc.pages.filter(pageName => contentPage_nameMap.has(pageName))
            .forEach(pageName => {
              const contentPage = contentPage_nameMap.get(pageName);
              const contentPageDocument = new models.ContentPageDocumentFile(
                {
                  contentpage: contentPage,
                  documentfile: documentFile
                });
              contentPage_documents.push(contentPageDocument);
            });
          dc.languages.filter(locale => languages_localeMap.has(locale))
            .forEach(locale => {
              const language = languages_localeMap.get(locale);
              const documentFileLanguage = new models.DocumentFileLanguage({ language: language, documentfile: documentFile });
              document_languages.push(documentFileLanguage);
            });
        });

        terminal.log(`# Setting localisations for ${document_languages.length} documents.`);
        if (!whatif) {
          const result = await Promise.all(document_languages.map(dl => DataStore.save(dl)));
          terminal.log(`# Set localisations for ${result.length} documents.`);
        }
        else {
          terminal.log(`# Would have set localisations for ${document_languages.length} documents.`);
        }

        terminal.log(`# Adding documents to ${contentPage_documents.length} content pages.`);
        if (!whatif) {
          const result = await Promise.all(contentPage_documents.map(cd => DataStore.save(cd)));
          terminal.log(`# Added documents to ${result.length} content pages.`);
        }
        else {
          terminal.log(`# Would have added documents to ${contentPage_documents.length} content pages.`);
        }

      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  purgedocs: {
    description: 'remove all documentfiles',
    usage: 'purgedocs (whatif)',
    fn: async (terminal, whatif) => {
      try {
        const documents = await DataStore.query(models.DocumentFile);

        terminal.log('# Deleting documents:');
        const loglist = abbreviateListForLog(documents.map(d => d.title));
        terminal.log(prettyoutput(loglist));

        if (!whatif) {
          const result = await Promise.all(documents.map(c => DataStore.delete(c)));
          terminal.log(`# Deleted ${result.length} documents.`);
        }
        else {
          terminal.log(`# Would have deleted ${documents.length} documents.`);
        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  /*
    {
      "contentPageNames":["IPMS - Nahinfrarot-Spektrometer"],
      "locales":["de","en"],
      "title":"IPMS - Spektroskopie",
      "video_filename":"Spektroskopie-Heinrich-Grüger.mp4",
      "video_poster_filename":"Spektroskopie-Heinrich-Grüger.jpg"
    },
  */
  addvids: {
    description: 'add videos from folder with json description image and video  files',
    usage: 'addvids (whatif)',
    fn: async (terminal, whatif) => {
      try {
        
        const uploadVideoFile = async (video_data, poster_key, video_key) => {
          const result = await DataStore.save(new models.VideoFile({ title: video_data.title, posterStorageKey: poster_key, videoStorageKey: video_key }));
          return { videofile: result, pages: video_data.contentPageNames, languages: video_data.locales };
        }
        const pagelist = await DataStore.query(models.ContentPage);
        const pagelist_nameMap = new Map(pagelist.map(pl => [pl.uniqueName, pl]));

        const playlists = await DataStore.query(models.Playlist);
        const playlist_nameMap = new Map(playlists.map(pl => [pl.name, pl]));

        const languages = await DataStore.query(models.Language);
        const languages_localeMap = new Map(languages.map(l => [l.locale, l]));

        const dirHandle = await window.showDirectoryPicker();
        const { data, images, videos } = await getFolderContents(dirHandle);
        if (data.length > 1) throw new Error("# ERROR Folder may only contain one contact definition.");

        const fileData = await data[0].getFile();
        const videos_data = JSON.parse(await fileData.text());

        console.log(videos_data);
        terminal.log('# Adding images:')
        const loglist = abbreviateListForLog(videos_data.map(vd => `${vd.title} | ${vd.videoStorageKey}`));
        terminal.log(prettyoutput(loglist));

        const posterfileSet = new Set(videos_data.map(vd => vd.video_poster_filename));
        const videofileSet = new Set(videos_data.map(vd => vd.video_filename));

        terminal.log(`# Found ${images.length} posters, uploading ${images.filter(fh => posterfileSet.has(fh.name)).length} files.`)
        const poster_nameKeyMap = new Map();
        if (!whatif) {
          const result = await Promise.all(
            images
              .filter(fh => posterfileSet.has(fh.name))
              .map(i => uploadFile(i))
          );
          result.forEach(r => poster_nameKeyMap.set(r.name, r.key));
          terminal.log(`# Uploaded ${result.length} poster files.`)
        }
        else {
          terminal.log(`# Would have uploaded ${images.length} poster files.`)
          images.map(i => { return { name: i.name, key: attachStorageId(i.name) } }).forEach(n => poster_nameKeyMap.set(n.name, n.key));
        }

        terminal.log(`# Found ${videos.length} videos, uploading ${videos.filter(fh => videofileSet.has(fh.name)).length} files.`)
        const video_nameKeyMap = new Map();
        if (!whatif) {
          const result = await Promise.all(
            videos
              .filter(fh => videofileSet.has(fh.name))
              .map(i => uploadFile(i))
          );
          result.forEach(r => video_nameKeyMap.set(r.name, r.key));
          terminal.log(`# Uploaded ${result.length} video files.`)
        }
        else {
          terminal.log(`# Would have uploaded ${videos.length} video files.`)
          videos.map(v => { return { name: v.name, key: attachStorageId(v.name) } }).forEach(n => video_nameKeyMap.set(n.name, n.key));
        }

        let video_connections = [];
        terminal.log(`# Uploading ${videos_data.length} video data sets.`)
        if (!whatif) {
          video_connections = await Promise.all(
            videos_data
              .filter(vd => poster_nameKeyMap.has(vd.video_poster_filename))
              .filter(vd => video_nameKeyMap.has(vd.video_filename))
              .map(vd => uploadVideoFile(vd, poster_nameKeyMap.get(vd.video_poster_filename), video_nameKeyMap.get(vd.video_filename)))
          );
          terminal.log(`# Uploaded ${video_connections.length} video data sets.`);
        }
        else {
          video_connections = 
            videos_data
              .filter(vd => poster_nameKeyMap.has(vd.video_poster_filename))
              .filter(vd => video_nameKeyMap.has(vd.video_filename))
              .map(vd => {
                const { title, contentPageNames, locales, video_filename, video_poster_filename} = vd;
                return { 
                  videofile: { 
                    title: title,
                    posterStorageKey: poster_nameKeyMap.get(video_poster_filename),
                    videoStorageKey: video_nameKeyMap.get(video_filename),             
                  }, 
                  pages: contentPageNames,
                  languages: locales }
              })
          terminal.log(`# Would have uploaded ${video_connections.length} video data sets.`);
        }

        let video_languages = [];
        let playlist_videos = [];
        let contentpage_videos = [];
        video_connections.forEach(vc => {
          const videofile = vc.videofile;
          vc.pages.filter(pg => pagelist_nameMap.has(pg))
            .forEach(pg => {
              const page = pagelist_nameMap.get(pg);
              const contentPageVideo = new models.ContentPageVideoFile({ contentpage: page, videofile: videofile });
              contentpage_videos.push(contentPageVideo);
            });
          vc.pages.filter(pg => playlist_nameMap.has(pg))
            .forEach(pg => {
              const playlist = playlist_nameMap.get(pg);
              const playlistVideo = new models.PlaylistVideoFile({ playlist: playlist, videofile: videofile });
              playlist_videos.push(playlistVideo);
            });
          vc.languages.filter(lc => languages_localeMap.has(lc))
            .forEach(lc => {
              const language = languages_localeMap.get(lc)
              const videoLanguage = new models.VideoFileLanguage({language: language, videofile: videofile });
              video_languages.push(videoLanguage);
            })
        });


        terminal.log(`# Setting localisations for ${video_languages.length} videos.`);
        if (!whatif) {
          const result = await Promise.all(video_languages.map(vl => DataStore.save(vl)));
          terminal.log(`# Set localisations for ${result.length} videos.`);
        }
        else {
          terminal.log(`# Would have set localisations for ${video_languages.length} videos.`);
        }

        terminal.log(`# Adding videos to ${contentpage_videos.length} content pages.`);
        if (!whatif) {
          const result = await Promise.all(contentpage_videos.map(cv => DataStore.save(cv)));
          terminal.log(`# Added videos to ${result.length} content pages.`);
        }
        else {
          terminal.log(`# Would have added videos to ${contentpage_videos.length} content pages.`);
        }

        terminal.log(`# Adding videos to ${playlist_videos.length} playlists.`);
        if (!whatif) {
          const result = await Promise.all(playlist_videos.map(pv => DataStore.save(pv)));
          terminal.log(`# Added videos to ${result.length} playlistst.`);
        }
        else {
          terminal.log(`# Would have added videos to ${playlist_videos.length} playlists.`);
        }

        if (!whatif) {
          terminal.log(`# Updating playlist sequences.`);
          const playlists = await DataStore.query(models.Playlist);
          const playlistVideo = await DataStore.query(models.PlaylistVideoFile);
          const playlistImage = await DataStore.query(models.PlaylistImageFile)

          const updates = playlists.map(pl =>{
            const video_ids = playlistVideo.filter(pv => pv.playlist.id === pl.id).map(pv => pv.videofile.id);
            const image_ids = playlistImage.filter(pi => pi.playlist.id === pl.id).map(pi => pi.imagefile.id);

            const sequence = [...video_ids, ...image_ids];
            const updated = models.Playlist.copyOf(pl, updated => Object.assign(updated, { sequence: sequence }));
            return updated;
          })
          const result = await Promise.all(updates.map(updated => DataStore.save(updated))); 

        }

      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  purgevids: {
    description: 'add videos from folder with json description image and video  files',
    usage: 'addvids (whatif)',
    fn: async (terminal, whatif) => {
      try {
        const videos = await DataStore.query(models.VideoFile);

        terminal.log('# Deleting videos:');
        const loglist = abbreviateListForLog(videos.map(d => d.title));
        terminal.log(prettyoutput(loglist));

        if (!whatif) {
          const result = await Promise.all(videos.map(c => DataStore.delete(c)));
          terminal.log(`# Deleted ${result.length} videos.`);
        }
        else {
          terminal.log(`# Would have deleted ${videos.length} videos.`);
        }
      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  },
  verifyi18n: {
    description: 'verfiy translations exist',
    usage: 'verifyi18n',
    fn: async (terminal, whatif) => {
      try {
        let [fileHandle] = await window.showOpenFilePicker(csvPickerOptions);
        const fileData = await fileHandle.getFile();
        const csv = await fileData.text();
        const table = Papa.parse(csv);

        const languages = await DataStore.query(models.Language);
        const languages_localeMap = new Map(languages.map(l => [l.locale, l]));
        const languages_idMap = new Map(languages.map(l => [l.id, l]));

        const translationKeys = await DataStore.query(models.TranslationKey);
        const translationKeys_keyMap = new Map(translationKeys.map(t => [t.key, t]));
        const translationKeys_idMap = new Map(translationKeys.map(t => [t.id, t]));

        const translations = await DataStore.query(models.Translation);

        const concatIds = (id1, id2) => `${id1}-${id2}`;
        const translations_langKeyIdMap = new Map(translations.map(t => [concatIds(t.languageID, t.translationkeyID), t]));


        //check for csv parsing errors
        if(table.errors.length >0){
          terminal.log("❌ Errors occurred during csv parsing:")
          terminal.log(prettyoutput(table.errors));
        }

        //check whether all languages have been created
        const imported_locale = table.data[0].slice(1);
        const missing_locale = imported_locale.filter(l=> !languages_localeMap.has(l));
        if(missing_locale.length>0)
        {
          terminal.log("❌ Languages are missing:")
          terminal.log(prettyoutput(missing_locale));
        }
        else
        {
          terminal.log("✔ Languages are all present.")
        }

        //check wheter all translation keys have been created
        const imported_keys = table.data.slice(1).map(row => row[0]).filter(k => k!="");
        const existing_keys = translationKeys.map(tk => tk.key);
        const missing_keys = _.difference(imported_keys, existing_keys);
        const surplus_keys = _.difference(existing_keys, imported_keys);
        if(missing_keys.length>0){
          terminal.log("❌ Translation keys are missing:")
          terminal.log(prettyoutput(missing_keys));          
        }
        else{
          terminal.log("✔ Translation keys are all present.")
        }
        if(surplus_keys.length>0){
          terminal.log("❌ Translation keys are obsolete:")
          terminal.log(prettyoutput(surplus_keys));          
        }

        //check whether all translations exist and are correct
        const missing_translations = [];
        const erreroneous_translations = [];
        const trans = table.data;
        for(let i =1; i < trans.length; i++)
        {
           const key = trans[i];
           if(translationKeys_keyMap.has(key)){
             const key_id = translationKeys_keyMap.get(key).id;
              for(let j = 1; j< trans[i].length; j++)
              {
                const locale = trans[0][j];
                if(languages_localeMap.has(locale))
                {
                  const lang_id = languages_localeMap(locale).id;
                  const text_lookup_key = concatIds(lang_id, key_id); 
                  const text = trans[i][j];

                  const nfo = {
                    locale: locale,
                    key: key,
                    text: text
                  }

                  if(translations_langKeyIdMap.has(text_lookup_key))
                  {
                    const existing_text = translations_langKeyIdMap.get(text_lookup_key);
                    if(text !== existing_text) erreroneous_translations.push(nfo);              
                  }
                  else missing_translations.push(nfo);
                  
                }   
              }
           }
        }

        if(missing_translations.length>0)
        {
          terminal.log("❌ Translations are missing:")
          terminal.log(prettyoutput(missing_translations.map(nfo => `${nfo.locale} | ${nfo.key} | ${nfo.text}`)));      
        }
        else terminal.log("✔ Translations are all present.")

        if(erreroneous_translations.length>0)
        {
          terminal.log("❌ Translations are outdated:")
          terminal.log(prettyoutput(erreroneous_translations.map(nfo => `${nfo.locale} | ${nfo.key} | ${nfo.text}`)));      
        }
        else terminal.log("✔ Translations are up to date.")

      }
      catch (error) {
        console.error(error);
        return error.message;
      }
    }
  }
}




export default applicationCommands
