import React from 'react';

import { Button, Flex, Card, View, AmplifyProvider } from '@aws-amplify/ui-react';
import PrivacyPolicy from '../LegalText/PrivacyPolicy';
import Imprint from '../LegalText/Imprint';

const TermsAndConditions = ({ returnToAuth }) => {

    return (
      <AmplifyProvider>
        <Flex className='terms-condition-flex'>
          <View>
            <Card className='terms-condition-card' variation='elevated'>
              {/* <Button className='terms-condition-top-button' isFullWidth variation="link" onClick={returnToAuth}>Return to Sign Up</Button>*/}
                <Imprint/>
                <PrivacyPolicy/>
                <Button className='terms-condition-bottom-button' isFullWidth variation="link" onClick={returnToAuth}>Return to Sign Up</Button>
            </Card>
          </View>
        </Flex>
      </AmplifyProvider>
    )
  }

export default TermsAndConditions
