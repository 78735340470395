import React from 'react';
import Markdown from './LegalMarkdown';

const privacyPolicy = 
`
### Privacy Policy
[Datenschutzerklärung](https://www.leistungszentrum-mikronano.de/de/datenschutzerklaerung.html)
`;

const PrivacyPolicy = () => <Markdown>{privacyPolicy}</Markdown>

export default PrivacyPolicy;
