// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  withStyles,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionActions as MuiAccordionActions,
  AccordionDetails as MuiAccordionDetails
} from '@material-ui/core';


export const Accordion = withStyles({
  root: {
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    '&:first-child': {
      borderTop: 0,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme) =>( {
  root: {
    marginBottom: -1,
    minHeight: theme.spacing(1),
    '&$expanded': {
      minHeight: theme.spacing(1),
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const AccordionActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    justifyContent: "space-around"
  },
}))(MuiAccordionActions);
