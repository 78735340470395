/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import * as _ from 'lodash';
import { useSelector } from 'react-redux'

const useHeuristicReturnPath = (origins, defaultPath) => {

  const history = useSelector((state) => state.history);
  const location = history[0].pathname;

  defaultPath = defaultPath ? defaultPath : history[1] ? history[1].pathname : location;
  origins = origins ? origins : [];

  const [returnPath, setReturnPath] = useState(defaultPath);

  useEffect(() => {

    const originRx = origins
      .map(o => new RegExp(o.replace('{id}', '[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}') + '$'));

    const navActions =
      history.reduce(
        (acc, item, i, arr) => {
          if (arr[i + 1]) acc.push({ destination: item, source: arr[i + 1] });
          return acc;
        },
        []
      );

    const returnPaths = navActions
      .filter(n => n.destination.pathname === location)
      .filter(n => originRx.some(rx => n.source.pathname.match(rx)));

    if (_.isEmpty(returnPaths)) setReturnPath(defaultPath);
    else setReturnPath(returnPaths[0].source.pathname)

  }, [history, location, defaultPath, origins])

  return returnPath;
}

export default useHeuristicReturnPath;