// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { DataStore } from '@aws-amplify/datastore';
import { useHistory } from "react-router-dom";

const useSignOut = () =>{

    const history = useHistory();
    const {signOut, toSignIn} = useAuthenticator();
  
    return async () => {
        await DataStore.clear();
        history && history.push('/');
        signOut();
        toSignIn();
    }
}

export default useSignOut;
