
import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    Edit
} from 'react-admin';
import { FormWithRedirect } from "ra-core";
import { ContentPageEditor } from "./contentPageEditor";
import { DetailsDateField, DetailsPanel } from "../../components/DetailsPanel";
import DefaultPagination, {PerPageDefaults} from "../../components/ListView/DefaultPagination";

const ContentPageFilters = [
    <TextInput
        label="Search name"
        source="uniqueName.contains"
        alwaysOn
        key='searchFilter'
    />
];

const ContentPageTitle = ({ record }) => {
    return <span>
        Pages - {record.uniqueName}
    </span>;
};

export const ContentPageList = (props) => {
    return (
        <List
            {...props}
            exporter={false}
            bulkActionButtons={false}
            filters={ContentPageFilters}
            sort={{ field: 'uniqueName', order: 'ASC' }}
            pagination={<DefaultPagination/>}
            perPage={PerPageDefaults.default}
        >
            <Datagrid
                rowClick="edit"
            >
                <TextField source="uniqueName" label="Name" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </Datagrid>
        </List >
    )
};


export const ContentPageEdit = props => {

    return (
        <Edit
            {...props}
            title={<ContentPageTitle />}
            actions={false}
            mutationMode='pessimistic'
            aside={<ContentPageDetails />}
            component='div'
        >
            <FormWithRedirect
                resource="Playlist"
                render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving,
                    record
                }) => (
                    <ContentPageEditor
                        record={record}
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        pristine={pristine}
                        saving={saving}
                    />
                )}
            />
        </Edit>
    )

};

const ContentPageDetails = () => (
    <DetailsPanel size="small">
        <DetailsDateField label="Created at" source="createdAt" />
        <DetailsDateField label="Updated at" source="updatedAt" />
    </DetailsPanel>
)

