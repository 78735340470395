import {makeStyles} from "@material-ui/core";

export const useDetailPanelStyles = makeStyles(theme => ({
    asideSmall: {
      [theme.breakpoints.up('sm')]: {
        order: 1,
        width: '15em',
        marginLeft: '1em',
        height: 'fit-content'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    asideLarge: {
      [theme.breakpoints.up('sm')]: {
        order: 1,
        width: '25em',
        marginLeft: '1em',
        height: 'fit-content'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    field: {
      display: "block",
      padding: "4px 0 8px"
    },
    label: {
      color: "rgba(0, 0, 0, 0.54)"
    },
    profilePicture:{
      verticalAlign: "middle",
      width: "100%",
      padding: "8px 0px",
      borderRadius: "50%"     
    },
    image:{
      verticalAlign: "middle",
      width: "100%",
      padding: "8px 0px",
    },
    video:{
      padding: "8px 0px",
    }

  }));