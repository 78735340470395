import dataProviderCommands from "./dataProviderCommands";
import prettyoutput from 'prettyoutput';
import { DataStore } from "aws-amplify";
import * as models from '../../models'
import * as _ from 'lodash';

const pageMap = new Map(
  [
    ["CompleteJourney", "Übersicht"],
    ["ENAS", "ENAS - Übersicht"],
    ["ENAS/ENAS-Automotive", "ENAS - Usecase Automotive"],
    ["ENAS/ENAS-Energy", "ENAS - Usecase Energy"],
    ["ENAS/ENAS-Health", "ENAS - Usecase Health"],
    ["ENAS/ENAS-Industry", "ENAS - Usecase Industry"],
    ["ENAS/ENAS-Showroom", "ENAS - Showroom"],
    ["ENAS/ENAS-Showroom/ENAS-CNT-Sensorik", "ENAS - CNT-Sensorik"],
    ["ENAS/ENAS-Showroom/ENAS-Magnetfeldsensorik", "ENAS - Magnetfeldsensorik"],
    ["IIS-EAS", "IIS-EAS - Übersicht"],
    ["IIS-EAS/IIS-EAS-Automotive", "IIS-EAS - Usecase Automotive"],
    ["IIS-EAS/IIS-EAS-Health", "IIS-EAS - Usecase Health"],
    ["IIS-EAS/IIS-EAS-Industry", "IIS-EAS - Usecase Industry"],
    ["IIS-EAS/IIS-EAS-Robotics", "IIS-EAS - Usecase Robotics"],
    ["IIS-EAS/IIS-EAS-Showroom", "IIS-EAS - Showroom"],
    ["IIS-EAS/IIS-EAS-Showroom/IIS-EAS-Edge-KI", "IIS-EAS - Edge KI"],
    ["IIS-EAS/IIS-EAS-Showroom/IIS-EAS-Elektronik", "IIS-EAS - Elektronik"],
    ["IPMS", "IPMS - Übersicht"],
    ["IPMS/IPMS-Automotive", "IPMS - Usecase Automotive"],
    ["IPMS/IPMS-Health", "IPMS - Usecase Health"],
    ["IPMS/IPMS-Industry", "IPMS - Usecase Industry"],
    ["IPMS/IPMS-Showroom", "IPMS - Showroom"],
    ["IPMS/IPMS-Showroom/HOLO", "IPMS - Holografie"],
    ["IPMS/IPMS-Showroom/NIR", "IPMS - Nahinfrarot-Spektrometer"],
    ["IZM-ASSID", "IZM-ASSID - Übersicht"],
    ["IZM-ASSID/IZM-ASSID-AI", "IZM-ASSID - Usecase Artificial Intelligence"],
    ["IZM-ASSID/IZM-ASSID-HPC", "IZM-ASSID - Usecase High Performance Computing"],
    ["IZM-ASSID/IZM-ASSID-Industry", "IZM-ASSID - Usecase Industry"],
    ["IZM-ASSID/IZM-ASSID-Showroom", "IZM-ASSID - Showroom"],
    ["IZM-ASSID/IZM-ASSID-Showroom/IZM-ASSID-Flexaufbau", "IZM-ASSID - Flexaufbau"],
    ["IZM-ASSID/IZM-ASSID-Showroom/IZM-ASSID-Waferstapel", "IZM-ASSID - Waferstapel"],
    ["IZM-ASSID/IZM-ASSID-Smarthome", "IZM-ASSID - Usecase Smarthome"],
    ["Leistungszentrum", "Leistungszentrum - Übersicht"],
    ["Leistungszentrum/LZ-Automotive", "Leistungszentrum - Usecase Automotive"],
    ["Leistungszentrum/LZ-Health", "Leistungszentrum - Usecase Health"],
    ["Leistungszentrum/LZ-Human-Machine-Interaction", "Leistungszentrum - Usecase Human-Machine-Interaction"],
    ["Leistungszentrum/LZ-Industrial-IoT", "Leistungszentrum - Usecase Industrial IoT"],
    ["Leistungszentrum/LZ-Showroom", "Leistungszentrum - Showroom"],
    ["Leistungszentrum/LZ-Showroom/MUT", "Leistungszentrum - MUT"],
    ["Leistungszentrum/LZ-Showroom/USeP", "Leistungszentrum - USeP"],
  ]
);

const routesMap = new Map(
  [
    ["Übersicht", "CompleteJourney"],
    ["ENAS - Übersicht", "ENAS"],
    ["ENAS - Usecase Automotive", "ENAS/ENAS-Automotive"],
    ["ENAS - Usecase Energy", "ENAS/ENAS-Energy"],
    ["ENAS - Usecase Health", "ENAS/ENAS-Health"],
    ["ENAS - Usecase Industry", "ENAS/ENAS-Industry"],
    ["ENAS - Showroom", "ENAS/ENAS-Showroom"],
    ["ENAS - CNT-Sensorik", "ENAS/ENAS-Showroom/ENAS-CNT-Sensorik"],
    ["ENAS - Magnetfeldsensorik", "ENAS/ENAS-Showroom/ENAS-Magnetfeldsensorik"],
    ["IIS-EAS - Übersicht", "IIS-EAS"],
    ["IIS-EAS - Usecase Automotive", "IIS-EAS/IIS-EAS-Automotive"],
    ["IIS-EAS - Usecase Health", "IIS-EAS/IIS-EAS-Health"],
    ["IIS-EAS - Usecase Industry", "IIS-EAS/IIS-EAS-Industry"],
    ["IIS-EAS - Usecase Robotics", "IIS-EAS/IIS-EAS-Robotics"],
    ["IIS-EAS - Showroom", "IIS-EAS/IIS-EAS-Showroom"],
    ["IIS-EAS - Edge KI", "IIS-EAS/IIS-EAS-Showroom/IIS-EAS-Edge-KI"],
    ["IIS-EAS - Elektronik", "IIS-EAS/IIS-EAS-Showroom/IIS-EAS-Elektronik"],
    ["IPMS - Übersicht", "IPMS"],
    ["IPMS - Usecase Automotive", "IPMS/IPMS-Automotive"],
    ["IPMS - Usecase Health", "IPMS/IPMS-Health"],
    ["IPMS - Usecase Industry", "IPMS/IPMS-Industry"],
    ["IPMS - Showroom", "IPMS/IPMS-Showroom"],
    ["IPMS - Holografie", "IPMS/IPMS-Showroom/HOLO"],
    ["IPMS - Nahinfrarot-Spektrometer", "IPMS/IPMS-Showroom/NIR"],
    ["IZM-ASSID - Übersicht", "IZM-ASSID"],
    ["IZM-ASSID - Usecase Artificial Intelligence", "IZM-ASSID/IZM-ASSID-AI"],
    ["IZM-ASSID - Usecase High Performance Computing", "IZM-ASSID/IZM-ASSID-HPC"],
    ["IZM-ASSID - Usecase Industry", "IZM-ASSID/IZM-ASSID-Industry"],
    ["IZM-ASSID - Showroom", "IZM-ASSID/IZM-ASSID-Showroom"],
    ["IZM-ASSID - Flexaufbau", "IZM-ASSID/IZM-ASSID-Showroom/IZM-ASSID-Flexaufbau"],
    ["IZM-ASSID - Waferstapel", "IZM-ASSID/IZM-ASSID-Showroom/IZM-ASSID-Waferstapel"],
    ["IZM-ASSID - Usecase Smarthome", "IZM-ASSID/IZM-ASSID-Smarthome"],
    ["Leistungszentrum - Übersicht", "Leistungszentrum"],
    ["Leistungszentrum - Usecase Automotive", "Leistungszentrum/LZ-Automotive"],
    ["Leistungszentrum - Usecase Health", "Leistungszentrum/LZ-Health"],
    ["Leistungszentrum - Usecase Human-Machine-Interaction", "Leistungszentrum/LZ-Human-Machine-Interaction"],
    ["Leistungszentrum - Usecase Industrial IoT", "Leistungszentrum/LZ-Industrial-IoT"],
    ["Leistungszentrum - Showroom", "Leistungszentrum/LZ-Showroom"],
    ["Leistungszentrum - MUT", "Leistungszentrum/LZ-Showroom/MUT"],
    ["Leistungszentrum - USeP", "Leistungszentrum/LZ-Showroom/USeP"],
  ]
);


const toursFromAutoPilotRoutes = (routes) => {

    const tours = routes.map(t => {
        let origin = 'Übersicht';
        if (pageMap.has(t.Id)) origin = pageMap.get(t.Id);
        let schedule = t.routes.map(r => pageMap.get(r));
        return {origin, schedule}; 
    });

    return tours;
} 

const jsonPickerOptions =
{
  types: [{ description: 'JSON', accept: { 'application/json': ['.json'] } },],
  excludeAcceptAllOption: true,
  multiple: false
};

const tourCommands = {
  purge: {
    description: 'remove all tours',
    usage: 'purge (whatif)',
    fn: async (terminal, whatif) => {
      try {
        const tours = await DataStore.query(models.Tour)

        terminal.log('# Deleting tours:')
        const loglist = tours.map((b) => b.origin);
        terminal.log(prettyoutput(loglist))

        if (!whatif) {
          const result = await Promise.all(
            tours.map((b) => DataStore.delete(b))
          )
          terminal.log(`# Deleted ${result.length} tours.`)
        } else {
          terminal.log(`# Would have deleted ${tours.length} tours.`)
        }
      } catch (error) {
        console.error(error)
        return error.message
      }
    },
  },
  upload: {
    description: 'update tours from autoPilot.json',
    usage: 'update',
    fn: async (terminal) => {
      try {
        let [fileHandle] = await window.showOpenFilePicker(jsonPickerOptions);
        const fileData = await fileHandle.getFile();
        const toursData = JSON.parse(await fileData.text());

        const tours = toursData.institutes.map(i => {
          const origin = pageMap.get(i.Id);
          const schedule = i.routes.map(r => pageMap.get(r));
          return {origin, schedule};
        });

        terminal.log(`Adding ${tours.length} tours:`);
        terminal.log(prettyoutput(tours));

        for (let i = 0; i < tours.length; i++){
          const t = tours[i];
          try {
            const result = await terminal.dataProvider.create('Tour', {data: t});
            terminal.log(`✔ Adding tour for ${t.origin}.`);
          }
          catch (error) {
            terminal.log(`❌ Adding tour for ${t.origin} failed.`);
            return error.message;
          }
        }
        return
      } catch (error) {
        console.error(error)
        return error.message
      }
    },
  },
  list: {
    description: 'list Tours',
    usage: 'list <page> <perPage>',
    fn: async (terminal, page, perPage) =>
      await dataProviderCommands.list.fn(terminal, 'Tour', page, perPage),
  },
  get: {
    description: 'get Tour by id',
    usage: 'get <id>',
    fn: async (terminal, id) =>
      await dataProviderCommands.get.fn(terminal, 'Tour', id),
  },
  delete: {
    description: 'delete Tour by id',
    usage: 'delete <id>',
    fn: async (terminal, id) =>
      await dataProviderCommands.delete.fn(terminal, 'Tour', id),
  }
}

export default tourCommands;
