
export const getDisplayName = (locale) => languages[locale]?.displayName ?? "";
export const getNativeName = (locale) => languages[locale]?.nativeName ?? "";

export const languages = {
	"af": {displayName: "Afrikaans", nativeName: "Afrikaans"},
	"af-NA": {displayName: "Afrikaans (Namibië)", nativeName: "Afrikaans (Namibië)"},
	"af-ZA": {displayName: "Afrikaans (South Africa)", nativeName: "Afrikaans (Suid-Afrika)"},
	"agq": {displayName: "Aghem", nativeName: "Aghem"},
	"agq-CM": {displayName: "Aghem (Kàmàlûŋ)", nativeName: "Aghem (Kàmàlûŋ)"},
	"ak": {displayName: "Akan", nativeName: "Akan"},
	"ak-GH": {displayName: "Akan (Gaana)", nativeName: "Akan (Gaana)"},
	"am": {displayName: "Amharic", nativeName: "አማርኛ"},
	"am-ET": {displayName: "Amharic (Ethiopia)", nativeName: "አማርኛ (ኢትዮጵያ)"},
	"ar": {displayName: "Arabic", nativeName: "العربية"},
	"ar-001": {displayName: "العربية (العالم)", nativeName: "العربية (العالم)"},
	"ar-AE": {displayName: "Arabic (United Arab Emirates)", nativeName: "العربية (الإمارات العربية المتحدة)"},
	"ar-BH": {displayName: "Arabic (Bahrain)", nativeName: "العربية (البحرين)"},
	"ar-DJ": {displayName: "العربية (جيبوتي)", nativeName: "العربية (جيبوتي)"},
	"ar-DZ": {displayName: "Arabic (Algeria)", nativeName: "العربية (الجزائر)"},
	"ar-EG": {displayName: "Arabic (Egypt)", nativeName: "العربية (مصر)"},
	"ar-ER": {displayName: "العربية (إريتريا)", nativeName: "العربية (إريتريا)"},
	"ar-IL": {displayName: "العربية (إسرائيل)", nativeName: "العربية (إسرائيل)"},
	"ar-IQ": {displayName: "Arabic (Iraq)", nativeName: "العربية (العراق)"},
	"ar-JO": {displayName: "Arabic (Jordan)", nativeName: "العربية (الأردن)"},
	"ar-KM": {displayName: "العربية (جزر القمر)", nativeName: "العربية (جزر القمر)"},
	"ar-KW": {displayName: "Arabic (Kuwait)", nativeName: "العربية (الكويت)"},
	"ar-LB": {displayName: "Arabic (Lebanon)", nativeName: "العربية (لبنان)"},
	"ar-LY": {displayName: "Arabic (Libya)", nativeName: "العربية (ليبيا)"},
	"ar-MA": {displayName: "Arabic (Morocco)", nativeName: "العربية (المغرب)"},
	"ar-MR": {displayName: "العربية (موريتانيا)", nativeName: "العربية (موريتانيا)"},
	"ar-OM": {displayName: "Arabic (Oman)", nativeName: "العربية (عُمان)"},
	"ar-PS": {displayName: "العربية (السلطة الفلسطينية)", nativeName: "العربية (السلطة الفلسطينية)"},
	"ar-QA": {displayName: "Arabic (Qatar)", nativeName: "العربية (قطر)"},
	"ar-SA": {displayName: "Arabic (Saudi Arabia)", nativeName: "العربية (المملكة العربية السعودية)"},
	"ar-SD": {displayName: "العربية (السودان)", nativeName: "العربية (السودان)"},
	"ar-SO": {displayName: "العربية (الصومال)", nativeName: "العربية (الصومال)"},
	"ar-SS": {displayName: "العربية (جنوب السودان)", nativeName: "العربية (جنوب السودان)"},
	"ar-SY": {displayName: "Arabic (Syria)", nativeName: "العربية (سوريا)"},
	"ar-TD": {displayName: "العربية (تشاد)", nativeName: "العربية (تشاد)"},
	"ar-TN": {displayName: "Arabic (Tunisia)", nativeName: "العربية (تونس)"},
	"ar-YE": {displayName: "Arabic (Yemen)", nativeName: "العربية (اليمن)"},
	"arn": {displayName: "Mapuche", nativeName: "Mapudungun"},
	"arn-CL": {displayName: "Mapuche (Chile)", nativeName: "Mapudungun (CL)"},
	"as": {displayName: "Assamese", nativeName: "অসমীয়া"},
	"as-IN": {displayName: "Assamese (India)", nativeName: "অসমীয়া (ভাৰত)"},
	"asa": {displayName: "Kipare", nativeName: "Kipare"},
	"asa-TZ": {displayName: "Kipare (Tadhania)", nativeName: "Kipare (Tadhania)"},
	"ast": {displayName: "asturianu", nativeName: "asturianu"},
	"ast-ES": {displayName: "asturianu (España)", nativeName: "asturianu (España)"},
	"az": {displayName: "Azerbaijani", nativeName: "azərbaycan"},
	"az-Cyrl": {displayName: "Azerbaijani (Cyrillic)", nativeName: "азәрбајҹан"},
	"az-Cyrl-AZ": {displayName: "Azerbaijani (Cyrillic, Azerbaijan)", nativeName: "азәрбајҹан (Кирил, Азәрбајҹан)"},
	"az-Latn": {displayName: "Azerbaijani (Latin)", nativeName: "azərbaycan"},
	"az-Latn-AZ": {displayName: "Azerbaijani (Latin, Azerbaijan)", nativeName: "azərbaycan (latın, Azərbaycan)"},
	"ba": {displayName: "Bashkir", nativeName: "ba"},
	"ba-RU": {displayName: "Bashkir (Russia)", nativeName: "ba (RU)"},
	"bas": {displayName: "Ɓàsàa", nativeName: "Ɓàsàa"},
	"bas-CM": {displayName: "Ɓàsàa (Kàmɛ̀rûn)", nativeName: "Ɓàsàa (Kàmɛ̀rûn)"},
	"be": {displayName: "Belarusian", nativeName: "беларуская"},
	"be-BY": {displayName: "Belarusian (Belarus)", nativeName: "беларуская (Беларусь)"},
	"bem": {displayName: "Ichibemba", nativeName: "Ichibemba"},
	"bem-ZM": {displayName: "Ichibemba (Zambia)", nativeName: "Ichibemba (Zambia)"},
	"bez": {displayName: "Hibena", nativeName: "Hibena"},
	"bez-TZ": {displayName: "Hibena (Hutanzania)", nativeName: "Hibena (Hutanzania)"},
	"bg": {displayName: "Bulgarian", nativeName: "български"},
	"bg-BG": {displayName: "Bulgarian (Bulgaria)", nativeName: "български (България)"},
	"bm": {displayName: "bamanakan", nativeName: "bamanakan"},
	"bm-Latn-ML": {displayName: "bamanakan (Latn, Mali)", nativeName: "bamanakan (Latn, Mali)"},
	"bn": {displayName: "Bangla", nativeName: "বাংলা"},
	"bn-BD": {displayName: "Bangla (Bangladesh)", nativeName: "বাংলা (বাংলাদেশ)"},
	"bn-IN": {displayName: "Bangla (India)", nativeName: "বাংলা (ভারত)"},
	"bo": {displayName: "Tibetan", nativeName: "བོད་སྐད་"},
	"bo-CN": {displayName: "Tibetan (China)", nativeName: "བོད་སྐད་ (རྒྱ་ནག)"},
	"bo-IN": {displayName: "བོད་སྐད་ (རྒྱ་གར་)", nativeName: "བོད་སྐད་ (རྒྱ་གར་)"},
	"br": {displayName: "Breton", nativeName: "brezhoneg"},
	"br-FR": {displayName: "Breton (France)", nativeName: "brezhoneg (Frañs)"},
	"brx": {displayName: "बड़ो", nativeName: "बड़ो"},
	"brx-IN": {displayName: "बड़ो (भारत)", nativeName: "बड़ो (भारत)"},
	"bs": {displayName: "Bosnian", nativeName: "bosanski"},
	"bs-Cyrl": {displayName: "Bosnian (Cyrillic)", nativeName: "босански"},
	"bs-Cyrl-BA": {displayName: "Bosnian (Cyrillic, Bosnia & Herzegovina)", nativeName: "босански (ћирилица, Босна и Херцеговина)"},
	"bs-Latn": {displayName: "Bosnian (Latin)", nativeName: "bosanski"},
	"bs-Latn-BA": {displayName: "Bosnian (Latin, Bosnia & Herzegovina)", nativeName: "bosanski (latinica, Bosna i Hercegovina)"},
	"byn": {displayName: "byn", nativeName: "byn"},
	"byn-ER": {displayName: "byn (ER)", nativeName: "byn (ER)"},
	"ca": {displayName: "Catalan", nativeName: "català"},
	"ca-AD": {displayName: "català (Andorra)", nativeName: "català (Andorra)"},
	"ca-ES": {displayName: "Catalan (Spain)", nativeName: "català (Espanya)"},
	"ca-ES-valencia": {displayName: "Catalan (Spain, Valencian)", nativeName: "valencià (Espanya, valencià)"},
	"ca-FR": {displayName: "català (França)", nativeName: "català (França)"},
	"ca-IT": {displayName: "català (Itàlia)", nativeName: "català (Itàlia)"},
	"ce": {displayName: "нохчийн", nativeName: "нохчийн"},
	"ce-RU": {displayName: "нохчийн (Росси)", nativeName: "нохчийн (Росси)"},
	"ceb": {displayName: "Cebuano", nativeName: "Cebuano"},
	"ceb-Latn-PH": {displayName: "Cebuano (Latin, Pilipinas)", nativeName: "Cebuano (Latin, Pilipinas)"},
	"cgg": {displayName: "Rukiga", nativeName: "Rukiga"},
	"cgg-UG": {displayName: "Rukiga (Uganda)", nativeName: "Rukiga (Uganda)"},
	"chr": {displayName: "Cherokee", nativeName: "ᏣᎳᎩ"},
	"chr-Cher-US": {displayName: "Cherokee (Cherokee, United States)", nativeName: "ᏣᎳᎩ (ᏣᎳᎩ, ᏌᏊ ᎢᏳᎾᎵᏍᏔᏅ ᏍᎦᏚᎩ)"},
	"ku": {displayName: "Kurdish", nativeName: "Kurdish"},
	"ku-Arab-IQ": {displayName: "Kurdish (Arabic, Iraq)", nativeName: "کوردی (عەرەبی, عێراق)"},
	"ku-Arab-IR": {displayName: "کوردی (عەرەبی, ئێران)", nativeName: "کوردی (عەرەبی, ئێران)"},
	"co": {displayName: "Corsican", nativeName: "co"},
	"co-FR": {displayName: "Corsican (France)", nativeName: "co (FR)"},
	"cs": {displayName: "Czech", nativeName: "čeština"},
	"cs-CZ": {displayName: "Czech (Czechia)", nativeName: "čeština (Česko)"},
	"cu": {displayName: "cu", nativeName: "cu"},
	"cu-RU": {displayName: "cu (RU)", nativeName: "cu (RU)"},
	"cy": {displayName: "Welsh", nativeName: "Cymraeg"},
	"cy-GB": {displayName: "Welsh (United Kingdom)", nativeName: "Cymraeg (Y Deyrnas Unedig)"},
	"da": {displayName: "Danish", nativeName: "dansk"},
	"da-DK": {displayName: "Danish (Denmark)", nativeName: "dansk (Danmark)"},
	"da-GL": {displayName: "dansk (Grønland)", nativeName: "dansk (Grønland)"},
	"dav": {displayName: "Kitaita", nativeName: "Kitaita"},
	"dav-KE": {displayName: "Kitaita (Kenya)", nativeName: "Kitaita (Kenya)"},
	"de": {displayName: "German", nativeName: "Deutsch"},
	"de-AT": {displayName: "German (Austria)", nativeName: "Deutsch (Österreich)"},
	"de-BE": {displayName: "Deutsch (Belgien)", nativeName: "Deutsch (Belgien)"},
	"de-CH": {displayName: "German (Switzerland)", nativeName: "Deutsch (Schweiz)"},
	"de-DE": {displayName: "German (Germany)", nativeName: "Deutsch (Deutschland)"},
	"de-IT": {displayName: "Deutsch (Italien)", nativeName: "Deutsch (Italien)"},
	"de-LI": {displayName: "German (Liechtenstein)", nativeName: "Deutsch (Liechtenstein)"},
	"de-LU": {displayName: "German (Luxembourg)", nativeName: "Deutsch (Luxemburg)"},
	"dje": {displayName: "Zarmaciine", nativeName: "Zarmaciine"},
	"dje-NE": {displayName: "Zarmaciine (Nižer)", nativeName: "Zarmaciine (Nižer)"},
	"dsb": {displayName: "Lower Sorbian", nativeName: "dolnoserbšćina"},
	"dsb-DE": {displayName: "Lower Sorbian (Germany)", nativeName: "dolnoserbšćina (Nimska)"},
	"dua": {displayName: "duálá", nativeName: "duálá"},
	"dua-CM": {displayName: "duálá (Cameroun)", nativeName: "duálá (Cameroun)"},
	"dv": {displayName: "Divehi", nativeName: "dv"},
	"dv-MV": {displayName: "Divehi (Maldives)", nativeName: "dv (MV)"},
	"dyo": {displayName: "joola", nativeName: "joola"},
	"dyo-SN": {displayName: "joola (Senegal)", nativeName: "joola (Senegal)"},
	"dz": {displayName: "རྫོང་ཁ", nativeName: "རྫོང་ཁ"},
	"dz-BT": {displayName: "Dzongkha (Bhutan)", nativeName: "རྫོང་ཁ། (འབྲུག།)"},
	"ebu": {displayName: "Kĩembu", nativeName: "Kĩembu"},
	"ebu-KE": {displayName: "Kĩembu (Kenya)", nativeName: "Kĩembu (Kenya)"},
	"ee": {displayName: "Eʋegbe", nativeName: "Eʋegbe"},
	"ee-GH": {displayName: "Eʋegbe (Ghana nutome)", nativeName: "Eʋegbe (Ghana nutome)"},
	"ee-TG": {displayName: "Eʋegbe (Togo nutome)", nativeName: "Eʋegbe (Togo nutome)"},
	"el": {displayName: "Greek", nativeName: "Ελληνικά"},
	"el-CY": {displayName: "Ελληνικά (Κύπρος)", nativeName: "Ελληνικά (Κύπρος)"},
	"el-GR": {displayName: "Greek (Greece)", nativeName: "Ελληνικά (Ελλάδα)"},
	"en": {displayName: "English", nativeName: "English"},
	"en-001": {displayName: "English (World)", nativeName: "English (World)"},
	"en-150": {displayName: "English (Europe)", nativeName: "English (Europe)"},
	"en-AE": {displayName: "English (United Arab Emirates)", nativeName: "English (United Arab Emirates)"},
	"en-AG": {displayName: "English (Antigua & Barbuda)", nativeName: "English (Antigua & Barbuda)"},
	"en-AI": {displayName: "English (Anguilla)", nativeName: "English (Anguilla)"},
	"en-AS": {displayName: "English (American Samoa)", nativeName: "English (American Samoa)"},
	"en-AT": {displayName: "English (Austria)", nativeName: "English (Austria)"},
	"en-AU": {displayName: "English (Australia)", nativeName: "English (Australia)"},
	"en-BB": {displayName: "English (Barbados)", nativeName: "English (Barbados)"},
	"en-BE": {displayName: "English (Belgium)", nativeName: "English (Belgium)"},
	"en-BI": {displayName: "English (Burundi)", nativeName: "English (Burundi)"},
	"en-BM": {displayName: "English (Bermuda)", nativeName: "English (Bermuda)"},
	"en-BS": {displayName: "English (Bahamas)", nativeName: "English (Bahamas)"},
	"en-BW": {displayName: "English (Botswana)", nativeName: "English (Botswana)"},
	"en-BZ": {displayName: "English (Belize)", nativeName: "English (Belize)"},
	"en-CA": {displayName: "English (Canada)", nativeName: "English (Canada)"},
	"en-CC": {displayName: "English (Cocos [Keeling] Islands)", nativeName: "English (Cocos [Keeling] Islands)"},
	"en-CH": {displayName: "English (Switzerland)", nativeName: "English (Switzerland)"},
	"en-CK": {displayName: "English (Cook Islands)", nativeName: "English (Cook Islands)"},
	"en-CM": {displayName: "English (Cameroon)", nativeName: "English (Cameroon)"},
	"en-CX": {displayName: "English (Christmas Island)", nativeName: "English (Christmas Island)"},
	"en-CY": {displayName: "English (Cyprus)", nativeName: "English (Cyprus)"},
	"en-DE": {displayName: "English (Germany)", nativeName: "English (Germany)"},
	"en-DK": {displayName: "English (Denmark)", nativeName: "English (Denmark)"},
	"en-DM": {displayName: "English (Dominica)", nativeName: "English (Dominica)"},
	"en-ER": {displayName: "English (Eritrea)", nativeName: "English (Eritrea)"},
	"en-FI": {displayName: "English (Finland)", nativeName: "English (Finland)"},
	"en-FJ": {displayName: "English (Fiji)", nativeName: "English (Fiji)"},
	"en-FK": {displayName: "English (Falkland Islands)", nativeName: "English (Falkland Islands)"},
	"en-FM": {displayName: "English (Micronesia)", nativeName: "English (Micronesia)"},
	"en-GB": {displayName: "English (United Kingdom)", nativeName: "English (United Kingdom)"},
	"en-GD": {displayName: "English (Grenada)", nativeName: "English (Grenada)"},
	"en-GG": {displayName: "English (Guernsey)", nativeName: "English (Guernsey)"},
	"en-GH": {displayName: "English (Ghana)", nativeName: "English (Ghana)"},
	"en-GI": {displayName: "English (Gibraltar)", nativeName: "English (Gibraltar)"},
	"en-GM": {displayName: "English (Gambia)", nativeName: "English (Gambia)"},
	"en-GU": {displayName: "English (Guam)", nativeName: "English (Guam)"},
	"en-GY": {displayName: "English (Guyana)", nativeName: "English (Guyana)"},
	"en-HK": {displayName: "English (Hong Kong SAR)", nativeName: "English (Hong Kong SAR)"},
	"en-IE": {displayName: "English (Ireland)", nativeName: "English (Ireland)"},
	"en-IL": {displayName: "English (Israel)", nativeName: "English (Israel)"},
	"en-IM": {displayName: "English (Isle of Man)", nativeName: "English (Isle of Man)"},
	"en-IN": {displayName: "English (India)", nativeName: "English (India)"},
	"en-IO": {displayName: "English (British Indian Ocean Territory)", nativeName: "English (British Indian Ocean Territory)"},
	"en-JE": {displayName: "English (Jersey)", nativeName: "English (Jersey)"},
	"en-JM": {displayName: "English (Jamaica)", nativeName: "English (Jamaica)"},
	"en-KE": {displayName: "English (Kenya)", nativeName: "English (Kenya)"},
	"en-KI": {displayName: "English (Kiribati)", nativeName: "English (Kiribati)"},
	"en-KN": {displayName: "English (St. Kitts & Nevis)", nativeName: "English (St. Kitts & Nevis)"},
	"en-KY": {displayName: "English (Cayman Islands)", nativeName: "English (Cayman Islands)"},
	"en-LC": {displayName: "English (St. Lucia)", nativeName: "English (St. Lucia)"},
	"en-LR": {displayName: "English (Liberia)", nativeName: "English (Liberia)"},
	"en-LS": {displayName: "English (Lesotho)", nativeName: "English (Lesotho)"},
	"en-MG": {displayName: "English (Madagascar)", nativeName: "English (Madagascar)"},
	"en-MH": {displayName: "English (Marshall Islands)", nativeName: "English (Marshall Islands)"},
	"en-MO": {displayName: "English (Macao SAR)", nativeName: "English (Macao SAR)"},
	"en-MP": {displayName: "English (Northern Mariana Islands)", nativeName: "English (Northern Mariana Islands)"},
	"en-MS": {displayName: "English (Montserrat)", nativeName: "English (Montserrat)"},
	"en-MT": {displayName: "English (Malta)", nativeName: "English (Malta)"},
	"en-MU": {displayName: "English (Mauritius)", nativeName: "English (Mauritius)"},
	"en-MW": {displayName: "English (Malawi)", nativeName: "English (Malawi)"},
	"en-MY": {displayName: "English (Malaysia)", nativeName: "English (Malaysia)"},
	"en-NA": {displayName: "English (Namibia)", nativeName: "English (Namibia)"},
	"en-NF": {displayName: "English (Norfolk Island)", nativeName: "English (Norfolk Island)"},
	"en-NG": {displayName: "English (Nigeria)", nativeName: "English (Nigeria)"},
	"en-NL": {displayName: "English (Netherlands)", nativeName: "English (Netherlands)"},
	"en-NR": {displayName: "English (Nauru)", nativeName: "English (Nauru)"},
	"en-NU": {displayName: "English (Niue)", nativeName: "English (Niue)"},
	"en-NZ": {displayName: "English (New Zealand)", nativeName: "English (New Zealand)"},
	"en-PG": {displayName: "English (Papua New Guinea)", nativeName: "English (Papua New Guinea)"},
	"en-PH": {displayName: "English (Philippines)", nativeName: "English (Philippines)"},
	"en-PK": {displayName: "English (Pakistan)", nativeName: "English (Pakistan)"},
	"en-PN": {displayName: "English (Pitcairn Islands)", nativeName: "English (Pitcairn Islands)"},
	"en-PR": {displayName: "English (Puerto Rico)", nativeName: "English (Puerto Rico)"},
	"en-PW": {displayName: "English (Palau)", nativeName: "English (Palau)"},
	"en-RW": {displayName: "English (Rwanda)", nativeName: "English (Rwanda)"},
	"en-SB": {displayName: "English (Solomon Islands)", nativeName: "English (Solomon Islands)"},
	"en-SC": {displayName: "English (Seychelles)", nativeName: "English (Seychelles)"},
	"en-SD": {displayName: "English (Sudan)", nativeName: "English (Sudan)"},
	"en-SE": {displayName: "English (Sweden)", nativeName: "English (Sweden)"},
	"en-SG": {displayName: "English (Singapore)", nativeName: "English (Singapore)"},
	"en-SH": {displayName: "English (St Helena, Ascension, Tristan da Cunha)", nativeName: "English (St Helena, Ascension, Tristan da Cunha)"},
	"en-SI": {displayName: "English (Slovenia)", nativeName: "English (Slovenia)"},
	"en-SL": {displayName: "English (Sierra Leone)", nativeName: "English (Sierra Leone)"},
	"en-SS": {displayName: "English (South Sudan)", nativeName: "English (South Sudan)"},
	"en-SX": {displayName: "English (Sint Maarten)", nativeName: "English (Sint Maarten)"},
	"en-SZ": {displayName: "English (Eswatini)", nativeName: "English (Eswatini)"},
	"en-TC": {displayName: "English (Turks & Caicos Islands)", nativeName: "English (Turks & Caicos Islands)"},
	"en-TK": {displayName: "English (Tokelau)", nativeName: "English (Tokelau)"},
	"en-TO": {displayName: "English (Tonga)", nativeName: "English (Tonga)"},
	"en-TT": {displayName: "English (Trinidad & Tobago)", nativeName: "English (Trinidad & Tobago)"},
	"en-TV": {displayName: "English (Tuvalu)", nativeName: "English (Tuvalu)"},
	"en-TZ": {displayName: "English (Tanzania)", nativeName: "English (Tanzania)"},
	"en-UG": {displayName: "English (Uganda)", nativeName: "English (Uganda)"},
	"en-UM": {displayName: "English (U.S. Outlying Islands)", nativeName: "English (U.S. Outlying Islands)"},
	"en-US": {displayName: "English (United States)", nativeName: "English (United States)"},
	"en-US-posix": {displayName: "English (United States, Computer)", nativeName: "English (United States, Computer)"},
	"en-VC": {displayName: "English (St. Vincent & Grenadines)", nativeName: "English (St. Vincent & Grenadines)"},
	"en-VG": {displayName: "English (British Virgin Islands)", nativeName: "English (British Virgin Islands)"},
	"en-VI": {displayName: "English (U.S. Virgin Islands)", nativeName: "English (U.S. Virgin Islands)"},
	"en-VU": {displayName: "English (Vanuatu)", nativeName: "English (Vanuatu)"},
	"en-WS": {displayName: "English (Samoa)", nativeName: "English (Samoa)"},
	"en-ZA": {displayName: "English (South Africa)", nativeName: "English (South Africa)"},
	"en-ZM": {displayName: "English (Zambia)", nativeName: "English (Zambia)"},
	"en-ZW": {displayName: "English (Zimbabwe)", nativeName: "English (Zimbabwe)"},
	"eo": {displayName: "esperanto", nativeName: "esperanto"},
	"eo-001": {displayName: "esperanto (Mondo)", nativeName: "esperanto (Mondo)"},
	"es": {displayName: "Spanish", nativeName: "español"},
	"es-419": {displayName: "Spanish (Latin America)", nativeName: "español (Latinoamérica)"},
	"es-AR": {displayName: "Spanish (Argentina)", nativeName: "español (Argentina)"},
	"es-BO": {displayName: "Spanish (Bolivia)", nativeName: "español (Bolivia)"},
	"es-BR": {displayName: "español (Brasil)", nativeName: "español (Brasil)"},
	"es-BZ": {displayName: "español (Belice)", nativeName: "español (Belice)"},
	"es-CL": {displayName: "Spanish (Chile)", nativeName: "español (Chile)"},
	"es-CO": {displayName: "Spanish (Colombia)", nativeName: "español (Colombia)"},
	"es-CR": {displayName: "Spanish (Costa Rica)", nativeName: "español (Costa Rica)"},
	"es-CU": {displayName: "Spanish (Cuba)", nativeName: "español (Cuba)"},
	"es-DO": {displayName: "Spanish (Dominican Republic)", nativeName: "español (República Dominicana)"},
	"es-EC": {displayName: "Spanish (Ecuador)", nativeName: "español (Ecuador)"},
	"es-ES": {displayName: "Spanish (Spain)", nativeName: "español (España)"},
	"es-GQ": {displayName: "español (Guinea Ecuatorial)", nativeName: "español (Guinea Ecuatorial)"},
	"es-GT": {displayName: "Spanish (Guatemala)", nativeName: "español (Guatemala)"},
	"es-HN": {displayName: "Spanish (Honduras)", nativeName: "español (Honduras)"},
	"es-MX": {displayName: "Spanish (Mexico)", nativeName: "español (México)"},
	"es-NI": {displayName: "Spanish (Nicaragua)", nativeName: "español (Nicaragua)"},
	"es-PA": {displayName: "Spanish (Panama)", nativeName: "español (Panamá)"},
	"es-PE": {displayName: "Spanish (Peru)", nativeName: "español (Perú)"},
	"es-PH": {displayName: "español (Filipinas)", nativeName: "español (Filipinas)"},
	"es-PR": {displayName: "Spanish (Puerto Rico)", nativeName: "español (Puerto Rico)"},
	"es-PY": {displayName: "Spanish (Paraguay)", nativeName: "español (Paraguay)"},
	"es-SV": {displayName: "Spanish (El Salvador)", nativeName: "español (El Salvador)"},
	"es-US": {displayName: "Spanish (United States)", nativeName: "español (Estados Unidos)"},
	"es-UY": {displayName: "Spanish (Uruguay)", nativeName: "español (Uruguay)"},
	"es-VE": {displayName: "Spanish (Venezuela)", nativeName: "español (Venezuela)"},
	"et": {displayName: "Estonian", nativeName: "eesti"},
	"et-EE": {displayName: "Estonian (Estonia)", nativeName: "eesti (Eesti)"},
	"eu": {displayName: "Basque", nativeName: "euskara"},
	"eu-ES": {displayName: "Basque (Spain)", nativeName: "euskara (Espainia)"},
	"ewo": {displayName: "ewondo", nativeName: "ewondo"},
	"ewo-CM": {displayName: "ewondo (Kamərún)", nativeName: "ewondo (Kamərún)"},
	"fa": {displayName: "Persian", nativeName: "فارسی"},
	"prs-AF": {displayName: "prs (Afghanistan)", nativeName: "prs (افغانستان)"},
	"fa-IR": {displayName: "Persian (Iran)", nativeName: "فارسی (ایران)"},
	"ff": {displayName: "Fulah", nativeName: "Pulaar"},
	"ff-Latn": {displayName: "Fulah (Latin)", nativeName: "Pulaar"},
	"ff-Latn-BF": {displayName: "Pulaar (Latn, Burkibaa Faaso)", nativeName: "Pulaar (Latn, Burkibaa Faaso)"},
	"ff-Latn-CM": {displayName: "Pulaar (Latn, Kameruun)", nativeName: "Pulaar (Latn, Kameruun)"},
	"ff-Latn-GH": {displayName: "Pulaar (Latn, Ganaa)", nativeName: "Pulaar (Latn, Ganaa)"},
	"ff-Latn-GM": {displayName: "Pulaar (Latn, Gammbi)", nativeName: "Pulaar (Latn, Gammbi)"},
	"ff-Latn-GN": {displayName: "Pulaar (Latn, Gine)", nativeName: "Pulaar (Latn, Gine)"},
	"ff-Latn-GW": {displayName: "Pulaar (Latn, Gine-Bisaawo)", nativeName: "Pulaar (Latn, Gine-Bisaawo)"},
	"ff-Latn-LR": {displayName: "Pulaar (Latn, Liberiyaa)", nativeName: "Pulaar (Latn, Liberiyaa)"},
	"ff-Latn-MR": {displayName: "Pulaar (Latn, Muritani)", nativeName: "Pulaar (Latn, Muritani)"},
	"ff-Latn-NE": {displayName: "Pulaar (Latn, Nijeer)", nativeName: "Pulaar (Latn, Nijeer)"},
	"ff-Latn-NG": {displayName: "Fulah (Latin, Nigeria)", nativeName: "Pulaar (Latn, Nijeriyaa)"},
	"ff-Latn-SL": {displayName: "Pulaar (Latn, Seraa liyon)", nativeName: "Pulaar (Latn, Seraa liyon)"},
	"ff-Latn-SN": {displayName: "Fulah (Latin, Senegal)", nativeName: "Pulaar (Latn, Senegaal)"},
	"fi": {displayName: "Finnish", nativeName: "suomi"},
	"fi-FI": {displayName: "Finnish (Finland)", nativeName: "suomi (Suomi)"},
	"fil": {displayName: "Filipino", nativeName: "Filipino"},
	"fil-PH": {displayName: "Filipino (Philippines)", nativeName: "Filipino (Pilipinas)"},
	"fo": {displayName: "Faroese", nativeName: "føroyskt"},
	"fo-DK": {displayName: "føroyskt (Danmark)", nativeName: "føroyskt (Danmark)"},
	"fo-FO": {displayName: "Faroese (Faroe Islands)", nativeName: "føroyskt (Føroyar)"},
	"fr": {displayName: "French", nativeName: "français"},
	"fr-BE": {displayName: "French (Belgium)", nativeName: "français (Belgique)"},
	"fr-BF": {displayName: "français (Burkina Faso)", nativeName: "français (Burkina Faso)"},
	"fr-BI": {displayName: "français (Burundi)", nativeName: "français (Burundi)"},
	"fr-BJ": {displayName: "français (Bénin)", nativeName: "français (Bénin)"},
	"fr-BL": {displayName: "français (Saint-Barthélemy)", nativeName: "français (Saint-Barthélemy)"},
	"fr-CA": {displayName: "French (Canada)", nativeName: "français (Canada)"},
	"fr-CD": {displayName: "French (Congo [DRC])", nativeName: "français (Congo [République démocratique du])"},
	"fr-CF": {displayName: "français (République centrafricaine)", nativeName: "français (République centrafricaine)"},
	"fr-CG": {displayName: "français (Congo)", nativeName: "français (Congo)"},
	"fr-CH": {displayName: "French (Switzerland)", nativeName: "français (Suisse)"},
	"fr-CI": {displayName: "French (Côte d’Ivoire)", nativeName: "français (Côte d’Ivoire)"},
	"fr-CM": {displayName: "French (Cameroon)", nativeName: "français (Cameroun)"},
	"fr-DJ": {displayName: "français (Djibouti)", nativeName: "français (Djibouti)"},
	"fr-DZ": {displayName: "français (Algérie)", nativeName: "français (Algérie)"},
	"fr-FR": {displayName: "French (France)", nativeName: "français (France)"},
	"fr-GA": {displayName: "français (Gabon)", nativeName: "français (Gabon)"},
	"fr-GF": {displayName: "français (Guyane française)", nativeName: "français (Guyane française)"},
	"fr-GN": {displayName: "français (Guinée)", nativeName: "français (Guinée)"},
	"fr-GP": {displayName: "français (Guadeloupe)", nativeName: "français (Guadeloupe)"},
	"fr-GQ": {displayName: "français (Guinée équatoriale)", nativeName: "français (Guinée équatoriale)"},
	"fr-HT": {displayName: "French (Haiti)", nativeName: "français (Haïti)"},
	"fr-KM": {displayName: "français (Comores)", nativeName: "français (Comores)"},
	"fr-LU": {displayName: "French (Luxembourg)", nativeName: "français (Luxembourg)"},
	"fr-MA": {displayName: "French (Morocco)", nativeName: "français (Maroc)"},
	"fr-MC": {displayName: "French (Monaco)", nativeName: "français (Monaco)"},
	"fr-MF": {displayName: "français (Saint-Martin)", nativeName: "français (Saint-Martin)"},
	"fr-MG": {displayName: "français (Madagascar)", nativeName: "français (Madagascar)"},
	"fr-ML": {displayName: "French (Mali)", nativeName: "français (Mali)"},
	"fr-MQ": {displayName: "français (Martinique)", nativeName: "français (Martinique)"},
	"fr-MR": {displayName: "français (Mauritanie)", nativeName: "français (Mauritanie)"},
	"fr-MU": {displayName: "français (Maurice)", nativeName: "français (Maurice)"},
	"fr-NC": {displayName: "français (Nouvelle-Calédonie)", nativeName: "français (Nouvelle-Calédonie)"},
	"fr-NE": {displayName: "français (Niger)", nativeName: "français (Niger)"},
	"fr-PF": {displayName: "français (Polynésie française)", nativeName: "français (Polynésie française)"},
	"fr-PM": {displayName: "français (Saint-Pierre-et-Miquelon)", nativeName: "français (Saint-Pierre-et-Miquelon)"},
	"fr-RE": {displayName: "French (Réunion)", nativeName: "français (La Réunion)"},
	"fr-RW": {displayName: "français (Rwanda)", nativeName: "français (Rwanda)"},
	"fr-SC": {displayName: "français (Seychelles)", nativeName: "français (Seychelles)"},
	"fr-SN": {displayName: "French (Senegal)", nativeName: "français (Sénégal)"},
	"fr-SY": {displayName: "français (Syrie)", nativeName: "français (Syrie)"},
	"fr-TD": {displayName: "français (Tchad)", nativeName: "français (Tchad)"},
	"fr-TG": {displayName: "français (Togo)", nativeName: "français (Togo)"},
	"fr-TN": {displayName: "français (Tunisie)", nativeName: "français (Tunisie)"},
	"fr-VU": {displayName: "français (Vanuatu)", nativeName: "français (Vanuatu)"},
	"fr-WF": {displayName: "français (Wallis-et-Futuna)", nativeName: "français (Wallis-et-Futuna)"},
	"fr-YT": {displayName: "français (Mayotte)", nativeName: "français (Mayotte)"},
	"fur": {displayName: "furlan", nativeName: "furlan"},
	"fur-IT": {displayName: "furlan (Italie)", nativeName: "furlan (Italie)"},
	"fy": {displayName: "Western Frisian", nativeName: "Frysk"},
	"fy-NL": {displayName: "Western Frisian (Netherlands)", nativeName: "Frysk (Nederlân)"},
	"ga": {displayName: "Irish", nativeName: "Gaeilge"},
	"ga-IE": {displayName: "Irish (Ireland)", nativeName: "Gaeilge (Éire)"},
	"gd": {displayName: "Scottish Gaelic", nativeName: "Gàidhlig"},
	"gd-GB": {displayName: "Scottish Gaelic (United Kingdom)", nativeName: "Gàidhlig (An Rìoghachd Aonaichte)"},
	"gl": {displayName: "Galician", nativeName: "galego"},
	"gl-ES": {displayName: "Galician (Spain)", nativeName: "galego (España)"},
	"gn": {displayName: "Guarani", nativeName: "gn"},
	"gn-PY": {displayName: "Guarani (Paraguay)", nativeName: "gn (PY)"},
	"gsw": {displayName: "Swiss German", nativeName: "Schwiizertüütsch"},
	"gsw-CH": {displayName: "Schwiizertüütsch (Schwiiz)", nativeName: "Schwiizertüütsch (Schwiiz)"},
	"gsw-FR": {displayName: "Swiss German (France)", nativeName: "Elsässisch (Frànkrisch)"},
	"gsw-LI": {displayName: "Schwiizertüütsch (Liächteschtäi)", nativeName: "Schwiizertüütsch (Liächteschtäi)"},
	"gu": {displayName: "Gujarati", nativeName: "ગુજરાતી"},
	"gu-IN": {displayName: "Gujarati (India)", nativeName: "ગુજરાતી (ભારત)"},
	"guz": {displayName: "Ekegusii", nativeName: "Ekegusii"},
	"guz-KE": {displayName: "Ekegusii (Kenya)", nativeName: "Ekegusii (Kenya)"},
	"gv": {displayName: "Gaelg", nativeName: "Gaelg"},
	"gv-IM": {displayName: "Gaelg (Ellan Vannin)", nativeName: "Gaelg (Ellan Vannin)"},
	"ha": {displayName: "Hausa", nativeName: "Hausa"},
	"ha-Latn-GH": {displayName: "Hausa (Latin, Gana)", nativeName: "Hausa (Latin, Gana)"},
	"ha-Latn-NE": {displayName: "Hausa (Latin, Nijar)", nativeName: "Hausa (Latin, Nijar)"},
	"ha-Latn-NG": {displayName: "Hausa (Latin, Nigeria)", nativeName: "Hausa (Latin, Najeriya)"},
	"haw": {displayName: "Hawaiian", nativeName: "ʻŌlelo Hawaiʻi"},
	"haw-US": {displayName: "Hawaiian (United States)", nativeName: "ʻŌlelo Hawaiʻi (ʻAmelika Hui Pū ʻIa)"},
	"he": {displayName: "Hebrew", nativeName: "עברית"},
	"he-IL": {displayName: "Hebrew (Israel)", nativeName: "עברית (ישראל)"},
	"hi": {displayName: "Hindi", nativeName: "हिन्दी"},
	"hi-IN": {displayName: "Hindi (India)", nativeName: "हिन्दी (भारत)"},
	"hr": {displayName: "Croatian", nativeName: "hrvatski"},
	"hr-BA": {displayName: "Croatian (Bosnia & Herzegovina)", nativeName: "hrvatski (Bosna i Hercegovina)"},
	"hr-HR": {displayName: "Croatian (Croatia)", nativeName: "hrvatski (Hrvatska)"},
	"hsb": {displayName: "Upper Sorbian", nativeName: "hornjoserbšćina"},
	"hsb-DE": {displayName: "Upper Sorbian (Germany)", nativeName: "hornjoserbšćina (Němska)"},
	"hu": {displayName: "Hungarian", nativeName: "magyar"},
	"hu-HU": {displayName: "Hungarian (Hungary)", nativeName: "magyar (Magyarország)"},
	"hy": {displayName: "Armenian", nativeName: "հայերեն"},
	"hy-AM": {displayName: "Armenian (Armenia)", nativeName: "հայերեն (Հայաստան)"},
	"ia": {displayName: "interlingua", nativeName: "interlingua"},
	"ia-001": {displayName: "interlingua (Mundo)", nativeName: "interlingua (Mundo)"},
	"id": {displayName: "Indonesian", nativeName: "Indonesia"},
	"id-ID": {displayName: "Indonesian (Indonesia)", nativeName: "Indonesia (Indonesia)"},
	"ig": {displayName: "Igbo", nativeName: "Asụsụ Igbo"},
	"ig-NG": {displayName: "Igbo (Nigeria)", nativeName: "Asụsụ Igbo (Naịjịrịa)"},
	"ii": {displayName: "Yi", nativeName: "ꆈꌠꉙ"},
	"ii-CN": {displayName: "Yi (China)", nativeName: "ꆈꌠꉙ (ꍏꇩ)"},
	"is": {displayName: "Icelandic", nativeName: "íslenska"},
	"is-IS": {displayName: "Icelandic (Iceland)", nativeName: "íslenska (Ísland)"},
	"it": {displayName: "Italian", nativeName: "italiano"},
	"it-CH": {displayName: "Italian (Switzerland)", nativeName: "italiano (Svizzera)"},
	"it-IT": {displayName: "Italian (Italy)", nativeName: "italiano (Italia)"},
	"it-SM": {displayName: "italiano (San Marino)", nativeName: "italiano (San Marino)"},
	"it-VA": {displayName: "italiano (Città del Vaticano)", nativeName: "italiano (Città del Vaticano)"},
	"iu": {displayName: "Inuktitut", nativeName: "iu"},
	"iu-CA": {displayName: "iu (CA)", nativeName: "iu (CA)"},
	"iu-Latn": {displayName: "Inuktitut (Latin)", nativeName: "iu"},
	"iu-Latn-CA": {displayName: "Inuktitut (Latin, Canada)", nativeName: "iu (Latn, CA)"},
	"ja": {displayName: "Japanese", nativeName: "日本語"},
	"ja-JP": {displayName: "Japanese (Japan)", nativeName: "日本語 (日本)"},
	"jgo": {displayName: "Ndaꞌa", nativeName: "Ndaꞌa"},
	"jgo-CM": {displayName: "Ndaꞌa (Kamɛlûn)", nativeName: "Ndaꞌa (Kamɛlûn)"},
	"jmc": {displayName: "Kimachame", nativeName: "Kimachame"},
	"jmc-TZ": {displayName: "Kimachame (Tanzania)", nativeName: "Kimachame (Tanzania)"},
	"jv": {displayName: "Jawa", nativeName: "Jawa"},
	"jv-Latn-ID": {displayName: "Jawa (Latin, Indonésia)", nativeName: "Jawa (Latin, Indonésia)"},
	"ka": {displayName: "Georgian", nativeName: "ქართული"},
	"ka-GE": {displayName: "Georgian (Georgia)", nativeName: "ქართული (საქართველო)"},
	"kab": {displayName: "Taqbaylit", nativeName: "Taqbaylit"},
	"kab-DZ": {displayName: "Taqbaylit (Lezzayer)", nativeName: "Taqbaylit (Lezzayer)"},
	"kam": {displayName: "Kikamba", nativeName: "Kikamba"},
	"kam-KE": {displayName: "Kikamba (Kenya)", nativeName: "Kikamba (Kenya)"},
	"kde": {displayName: "Chimakonde", nativeName: "Chimakonde"},
	"kde-TZ": {displayName: "Chimakonde (Tanzania)", nativeName: "Chimakonde (Tanzania)"},
	"kea": {displayName: "kabuverdianu", nativeName: "kabuverdianu"},
	"kea-CV": {displayName: "kabuverdianu (Kabu Verdi)", nativeName: "kabuverdianu (Kabu Verdi)"},
	"khq": {displayName: "Koyra ciini", nativeName: "Koyra ciini"},
	"khq-ML": {displayName: "Koyra ciini (Maali)", nativeName: "Koyra ciini (Maali)"},
	"ki": {displayName: "Gikuyu", nativeName: "Gikuyu"},
	"ki-KE": {displayName: "Gikuyu (Kenya)", nativeName: "Gikuyu (Kenya)"},
	"kk": {displayName: "Kazakh", nativeName: "қазақ тілі"},
	"kk-KZ": {displayName: "Kazakh (Kazakhstan)", nativeName: "қазақ тілі (Қазақстан)"},
	"kkj": {displayName: "kakɔ", nativeName: "kakɔ"},
	"kkj-CM": {displayName: "kakɔ (Kamɛrun)", nativeName: "kakɔ (Kamɛrun)"},
	"kl": {displayName: "Kalaallisut", nativeName: "kalaallisut"},
	"kl-GL": {displayName: "Kalaallisut (Greenland)", nativeName: "kalaallisut (Kalaallit Nunaat)"},
	"kln": {displayName: "Kalenjin", nativeName: "Kalenjin"},
	"kln-KE": {displayName: "Kalenjin (Emetab Kenya)", nativeName: "Kalenjin (Emetab Kenya)"},
	"km": {displayName: "Khmer", nativeName: "ខ្មែរ"},
	"km-KH": {displayName: "Khmer (Cambodia)", nativeName: "ខ្មែរ (កម្ពុជា)"},
	"kn": {displayName: "Kannada", nativeName: "ಕನ್ನಡ"},
	"kn-IN": {displayName: "Kannada (India)", nativeName: "ಕನ್ನಡ (ಭಾರತ)"},
	"ko": {displayName: "Korean", nativeName: "한국어"},
	"ko-KP": {displayName: "한국어(조선민주주의인민공화국)", nativeName: "한국어(조선민주주의인민공화국)"},
	"ko-KR": {displayName: "Korean (Korea)", nativeName: "한국어(대한민국)"},
	"kok": {displayName: "Konkani", nativeName: "कोंकणी"},
	"kok-IN": {displayName: "Konkani (India)", nativeName: "कोंकणी (भारत)"},
	"ks": {displayName: "Kashmiri", nativeName: "کٲشُر"},
	"ks-Arab-IN": {displayName: "کٲشُر (اَربی, ہِندوستان)", nativeName: "کٲشُر (اَربی, ہِندوستان)"},
	"ksb": {displayName: "Kishambaa", nativeName: "Kishambaa"},
	"ksb-TZ": {displayName: "Kishambaa (Tanzania)", nativeName: "Kishambaa (Tanzania)"},
	"ksf": {displayName: "rikpa", nativeName: "rikpa"},
	"ksf-CM": {displayName: "rikpa (kamɛrún)", nativeName: "rikpa (kamɛrún)"},
	"ksh": {displayName: "Kölsch", nativeName: "Kölsch"},
	"ksh-DE": {displayName: "Kölsch en Doütschland", nativeName: "Kölsch en Doütschland"},
	"kw": {displayName: "kernewek", nativeName: "kernewek"},
	"kw-GB": {displayName: "kernewek (Rywvaneth Unys)", nativeName: "kernewek (Rywvaneth Unys)"},
	"ky": {displayName: "Kyrgyz", nativeName: "кыргызча"},
	"ky-KG": {displayName: "Kyrgyz (Kyrgyzstan)", nativeName: "кыргызча (Кыргызстан)"},
	"lag": {displayName: "Kɨlaangi", nativeName: "Kɨlaangi"},
	"lag-TZ": {displayName: "Kɨlaangi (Taansanía)", nativeName: "Kɨlaangi (Taansanía)"},
	"lb": {displayName: "Luxembourgish", nativeName: "Lëtzebuergesch"},
	"lb-LU": {displayName: "Luxembourgish (Luxembourg)", nativeName: "Lëtzebuergesch (Lëtzebuerg)"},
	"lg": {displayName: "Luganda", nativeName: "Luganda"},
	"lg-UG": {displayName: "Luganda (Yuganda)", nativeName: "Luganda (Yuganda)"},
	"lkt": {displayName: "Lakȟólʼiyapi", nativeName: "Lakȟólʼiyapi"},
	"lkt-US": {displayName: "Lakȟólʼiyapi (Mílahaŋska Tȟamákȟočhe)", nativeName: "Lakȟólʼiyapi (Mílahaŋska Tȟamákȟočhe)"},
	"ln": {displayName: "lingála", nativeName: "lingála"},
	"ln-AO": {displayName: "lingála (Angóla)", nativeName: "lingála (Angóla)"},
	"ln-CD": {displayName: "lingála (Republíki ya Kongó Demokratíki)", nativeName: "lingála (Republíki ya Kongó Demokratíki)"},
	"ln-CF": {displayName: "lingála (Repibiki ya Afríka ya Káti)", nativeName: "lingála (Repibiki ya Afríka ya Káti)"},
	"ln-CG": {displayName: "lingála (Kongo)", nativeName: "lingála (Kongo)"},
	"lo": {displayName: "Lao", nativeName: "ລາວ"},
	"lo-LA": {displayName: "Lao (Laos)", nativeName: "ລາວ (ລາວ)"},
	"lrc": {displayName: "لۊری شومالی", nativeName: "لۊری شومالی"},
	"lrc-IQ": {displayName: "لۊری شومالی (IQ)", nativeName: "لۊری شومالی (IQ)"},
	"lrc-IR": {displayName: "لۊری شومالی (IR)", nativeName: "لۊری شومالی (IR)"},
	"lt": {displayName: "Lithuanian", nativeName: "lietuvių"},
	"lt-LT": {displayName: "Lithuanian (Lithuania)", nativeName: "lietuvių (Lietuva)"},
	"lu": {displayName: "Tshiluba", nativeName: "Tshiluba"},
	"lu-CD": {displayName: "Tshiluba (Ditunga wa Kongu)", nativeName: "Tshiluba (Ditunga wa Kongu)"},
	"luo": {displayName: "Dholuo", nativeName: "Dholuo"},
	"luo-KE": {displayName: "Dholuo (Kenya)", nativeName: "Dholuo (Kenya)"},
	"luy": {displayName: "Luluhia", nativeName: "Luluhia"},
	"luy-KE": {displayName: "Luluhia (Kenya)", nativeName: "Luluhia (Kenya)"},
	"lv": {displayName: "Latvian", nativeName: "latviešu"},
	"lv-LV": {displayName: "Latvian (Latvia)", nativeName: "latviešu (Latvija)"},
	"mas": {displayName: "Maa", nativeName: "Maa"},
	"mas-KE": {displayName: "Maa (Kenya)", nativeName: "Maa (Kenya)"},
	"mas-TZ": {displayName: "Maa (Tansania)", nativeName: "Maa (Tansania)"},
	"mer": {displayName: "Kĩmĩrũ", nativeName: "Kĩmĩrũ"},
	"mer-KE": {displayName: "Kĩmĩrũ (Kenya)", nativeName: "Kĩmĩrũ (Kenya)"},
	"mfe": {displayName: "kreol morisien", nativeName: "kreol morisien"},
	"mfe-MU": {displayName: "kreol morisien (Moris)", nativeName: "kreol morisien (Moris)"},
	"mg": {displayName: "Malagasy", nativeName: "Malagasy"},
	"mg-MG": {displayName: "Malagasy (Madagasikara)", nativeName: "Malagasy (Madagasikara)"},
	"mgh": {displayName: "Makua", nativeName: "Makua"},
	"mgh-MZ": {displayName: "Makua (Umozambiki)", nativeName: "Makua (Umozambiki)"},
	"mgo": {displayName: "metaʼ", nativeName: "metaʼ"},
	"mgo-CM": {displayName: "metaʼ (Kamalun)", nativeName: "metaʼ (Kamalun)"},
	"mi": {displayName: "Maori", nativeName: "Māori"},
	"mi-NZ": {displayName: "Maori (New Zealand)", nativeName: "Māori (Aotearoa)"},
	"mk": {displayName: "Macedonian", nativeName: "македонски"},
	"mk-MK": {displayName: "Macedonian (North Macedonia)", nativeName: "македонски (Северна Македонија)"},
	"ml": {displayName: "Malayalam", nativeName: "മലയാളം"},
	"ml-IN": {displayName: "Malayalam (India)", nativeName: "മലയാളം (ഇന്ത്യ)"},
	"mn": {displayName: "Mongolian", nativeName: "монгол"},
	"mn-MN": {displayName: "Mongolian (Mongolia)", nativeName: "монгол (Монгол)"},
	"mn-Mong": {displayName: "Mongolian (Mongolian)", nativeName: "mn"},
	"mn-Mong-CN": {displayName: "Mongolian (Mongolian, China)", nativeName: "mn (Mong, CN)"},
	"mn-Mong-MN": {displayName: "Mongolian (Mongolian, Mongolia)", nativeName: "ᠮᠣᠩᠭᠣᠯ (ᠮᠣᠩᠭᠣᠯ ᠪᠢᠴᠢᠭ᠌, ᠮᠣᠩᠭᠣᠯ)"},
	"moh": {displayName: "Mohawk", nativeName: "Kanienʼkéha"},
	"moh-CA": {displayName: "Mohawk (Canada)", nativeName: "Kanienʼkéha (CA)"},
	"mr": {displayName: "Marathi", nativeName: "मराठी"},
	"mr-IN": {displayName: "Marathi (India)", nativeName: "मराठी (भारत)"},
	"ms": {displayName: "Malay", nativeName: "Melayu"},
	"ms-BN": {displayName: "Malay (Brunei)", nativeName: "Melayu (Brunei)"},
	"ms-MY": {displayName: "Malay (Malaysia)", nativeName: "Melayu (Malaysia)"},
	"ms-SG": {displayName: "Melayu (Singapura)", nativeName: "Melayu (Singapura)"},
	"mt": {displayName: "Maltese", nativeName: "Malti"},
	"mt-MT": {displayName: "Maltese (Malta)", nativeName: "Malti (Malta)"},
	"mua": {displayName: "MUNDAŊ", nativeName: "MUNDAŊ"},
	"mua-CM": {displayName: "MUNDAŊ (kameruŋ)", nativeName: "MUNDAŊ (kameruŋ)"},
	"my": {displayName: "Burmese", nativeName: "မြန်မာ"},
	"my-MM": {displayName: "Burmese (Myanmar)", nativeName: "မြန်မာ (မြန်မာ)"},
	"mzn": {displayName: "مازرونی", nativeName: "مازرونی"},
	"mzn-IR": {displayName: "مازرونی (ایران)", nativeName: "مازرونی (ایران)"},
	"naq": {displayName: "Khoekhoegowab", nativeName: "Khoekhoegowab"},
	"naq-NA": {displayName: "Khoekhoegowab (Namibiab)", nativeName: "Khoekhoegowab (Namibiab)"},
	"nb": {displayName: "Norwegian Bokmål", nativeName: "norsk bokmål"},
	"nb-NO": {displayName: "Norwegian Bokmål (Norway)", nativeName: "norsk bokmål (Norge)"},
	"nb-SJ": {displayName: "norsk bokmål (Svalbard og Jan Mayen)", nativeName: "norsk bokmål (Svalbard og Jan Mayen)"},
	"nd": {displayName: "isiNdebele", nativeName: "isiNdebele"},
	"nd-ZW": {displayName: "isiNdebele (Zimbabwe)", nativeName: "isiNdebele (Zimbabwe)"},
	"nds": {displayName: "nds", nativeName: "nds"},
	"nds-DE": {displayName: "nds (DE)", nativeName: "nds (DE)"},
	"nds-NL": {displayName: "nds (NL)", nativeName: "nds (NL)"},
	"ne": {displayName: "Nepali", nativeName: "नेपाली"},
	"ne-IN": {displayName: "Nepali (India)", nativeName: "नेपाली (भारत)"},
	"ne-NP": {displayName: "Nepali (Nepal)", nativeName: "नेपाली (नेपाल)"},
	"nl": {displayName: "Dutch", nativeName: "Nederlands"},
	"nl-AW": {displayName: "Nederlands (Aruba)", nativeName: "Nederlands (Aruba)"},
	"nl-BE": {displayName: "Dutch (Belgium)", nativeName: "Nederlands (België)"},
	"nl-BQ": {displayName: "Nederlands (Bonaire, Sint Eustatius en Saba)", nativeName: "Nederlands (Bonaire, Sint Eustatius en Saba)"},
	"nl-CW": {displayName: "Nederlands (Curaçao)", nativeName: "Nederlands (Curaçao)"},
	"nl-NL": {displayName: "Dutch (Netherlands)", nativeName: "Nederlands (Nederland)"},
	"nl-SR": {displayName: "Nederlands (Suriname)", nativeName: "Nederlands (Suriname)"},
	"nl-SX": {displayName: "Nederlands (Sint-Maarten)", nativeName: "Nederlands (Sint-Maarten)"},
	"nmg": {displayName: "nmg", nativeName: "nmg"},
	"nmg-CM": {displayName: "nmg (Kamerun)", nativeName: "nmg (Kamerun)"},
	"nn": {displayName: "Norwegian Nynorsk", nativeName: "nynorsk"},
	"nn-NO": {displayName: "Norwegian Nynorsk (Norway)", nativeName: "nynorsk (Noreg)"},
	"nnh": {displayName: "Shwóŋò ngiembɔɔn", nativeName: "Shwóŋò ngiembɔɔn"},
	"nnh-CM": {displayName: "Shwóŋò ngiembɔɔn (Kàmalûm)", nativeName: "Shwóŋò ngiembɔɔn (Kàmalûm)"},
	"nqo": {displayName: "nqo", nativeName: "nqo"},
	"nqo-GN": {displayName: "nqo (GN)", nativeName: "nqo (GN)"},
	"nr": {displayName: "nr", nativeName: "nr"},
	"nr-ZA": {displayName: "nr (ZA)", nativeName: "nr (ZA)"},
	"nso": {displayName: "Sesotho sa Leboa", nativeName: "nso"},
	"nso-ZA": {displayName: "Sesotho sa Leboa (South Africa)", nativeName: "nso (Afrika Borwa)"},
	"nus": {displayName: "Thok Nath", nativeName: "Thok Nath"},
	"nus-SS": {displayName: "Thok Nath (SS)", nativeName: "Thok Nath (SS)"},
	"nyn": {displayName: "Runyankore", nativeName: "Runyankore"},
	"nyn-UG": {displayName: "Runyankore (Uganda)", nativeName: "Runyankore (Uganda)"},
	"oc": {displayName: "Occitan", nativeName: "oc"},
	"oc-FR": {displayName: "Occitan (France)", nativeName: "oc (FR)"},
	"om": {displayName: "Oromo", nativeName: "Oromoo"},
	"om-ET": {displayName: "Oromo (Ethiopia)", nativeName: "Oromoo (Itoophiyaa)"},
	"om-KE": {displayName: "Oromoo (Keeniyaa)", nativeName: "Oromoo (Keeniyaa)"},
	"or": {displayName: "Odia", nativeName: "ଓଡ଼ିଆ"},
	"or-IN": {displayName: "Odia (India)", nativeName: "ଓଡ଼ିଆ (ଭାରତ)"},
	"os": {displayName: "ирон", nativeName: "ирон"},
	"os-GE": {displayName: "ирон (Гуырдзыстон)", nativeName: "ирон (Гуырдзыстон)"},
	"os-RU": {displayName: "ирон (Уӕрӕсе)", nativeName: "ирон (Уӕрӕсе)"},
	"pa": {displayName: "Punjabi", nativeName: "ਪੰਜਾਬੀ"},
	"pa-Arab": {displayName: "Punjabi (Arabic)", nativeName: "پنجابی"},
	"pa-Arab-PK": {displayName: "Punjabi (Arabic, Pakistan)", nativeName: "پنجابی (عربی, پاکستان)"},
	"pa-Guru": {displayName: "ਪੰਜਾਬੀ", nativeName: "ਪੰਜਾਬੀ"},
	"pa-IN": {displayName: "Punjabi (India)", nativeName: "ਪੰਜਾਬੀ (ਭਾਰਤ)"},
	"pl": {displayName: "Polish", nativeName: "polski"},
	"pl-PL": {displayName: "Polish (Poland)", nativeName: "polski (Polska)"},
	"prg": {displayName: "prūsiskan", nativeName: "prūsiskan"},
	"prg-001": {displayName: "prūsiskan (001)", nativeName: "prūsiskan (001)"},
	"ps": {displayName: "Pashto", nativeName: "پښتو"},
	"ps-AF": {displayName: "Pashto (Afghanistan)", nativeName: "پښتو (افغانستان)"},
	"ps-PK": {displayName: "پښتو (پاکستان)", nativeName: "پښتو (پاکستان)"},
	"pt": {displayName: "Portuguese", nativeName: "português"},
	"pt-AO": {displayName: "português (Angola)", nativeName: "português (Angola)"},
	"pt-BR": {displayName: "Portuguese (Brazil)", nativeName: "português (Brasil)"},
	"pt-CH": {displayName: "português (Suíça)", nativeName: "português (Suíça)"},
	"pt-CV": {displayName: "português (Cabo Verde)", nativeName: "português (Cabo Verde)"},
	"pt-GQ": {displayName: "português (Guiné Equatorial)", nativeName: "português (Guiné Equatorial)"},
	"pt-GW": {displayName: "português (Guiné-Bissau)", nativeName: "português (Guiné-Bissau)"},
	"pt-LU": {displayName: "português (Luxemburgo)", nativeName: "português (Luxemburgo)"},
	"pt-MO": {displayName: "português (RAE de Macau)", nativeName: "português (RAE de Macau)"},
	"pt-MZ": {displayName: "português (Moçambique)", nativeName: "português (Moçambique)"},
	"pt-PT": {displayName: "Portuguese (Portugal)", nativeName: "português (Portugal)"},
	"pt-ST": {displayName: "português (São Tomé e Príncipe)", nativeName: "português (São Tomé e Príncipe)"},
	"pt-TL": {displayName: "português (Timor-Leste)", nativeName: "português (Timor-Leste)"},
	"quz": {displayName: "quz", nativeName: "quz"},
	"quz-BO": {displayName: "quz (Bolivia)", nativeName: "quz (Bolivia)"},
	"quz-EC": {displayName: "quz (Ecuador)", nativeName: "quz (Ecuador)"},
	"quz-PE": {displayName: "quz (Peru)", nativeName: "quz (Perú)"},
	"quc": {displayName: "Kʼicheʼ", nativeName: "Kʼicheʼ"},
	"quc-GT": {displayName: "Kʼicheʼ (GT)", nativeName: "Kʼicheʼ (GT)"},
	"rm": {displayName: "Romansh", nativeName: "rumantsch"},
	"rm-CH": {displayName: "Romansh (Switzerland)", nativeName: "rumantsch (Svizra)"},
	"rn": {displayName: "Ikirundi", nativeName: "Ikirundi"},
	"rn-BI": {displayName: "Ikirundi (Uburundi)", nativeName: "Ikirundi (Uburundi)"},
	"ro": {displayName: "Romanian", nativeName: "română"},
	"ro-MD": {displayName: "Romanian (Moldova)", nativeName: "română (Republica Moldova)"},
	"ro-RO": {displayName: "Romanian (Romania)", nativeName: "română (România)"},
	"rof": {displayName: "Kihorombo", nativeName: "Kihorombo"},
	"rof-TZ": {displayName: "Kihorombo (Tanzania)", nativeName: "Kihorombo (Tanzania)"},
	"ru": {displayName: "Russian", nativeName: "русский"},
	"ru-BY": {displayName: "русский (Беларусь)", nativeName: "русский (Беларусь)"},
	"ru-KG": {displayName: "русский (Киргизия)", nativeName: "русский (Киргизия)"},
	"ru-KZ": {displayName: "русский (Казахстан)", nativeName: "русский (Казахстан)"},
	"ru-MD": {displayName: "Russian (Moldova)", nativeName: "русский (Молдова)"},
	"ru-RU": {displayName: "Russian (Russia)", nativeName: "русский (Россия)"},
	"ru-UA": {displayName: "русский (Украина)", nativeName: "русский (Украина)"},
	"rw": {displayName: "Kinyarwanda", nativeName: "Kinyarwanda"},
	"rw-RW": {displayName: "Kinyarwanda (Rwanda)", nativeName: "Kinyarwanda (U Rwanda)"},
	"rwk": {displayName: "Kiruwa", nativeName: "Kiruwa"},
	"rwk-TZ": {displayName: "Kiruwa (Tanzania)", nativeName: "Kiruwa (Tanzania)"},
	"sa": {displayName: "Sanskrit", nativeName: "संस्कृत भाषा"},
	"sa-IN": {displayName: "Sanskrit (India)", nativeName: "संस्कृत भाषा (IN)"},
	"sah": {displayName: "Sakha", nativeName: "саха тыла"},
	"sah-RU": {displayName: "Sakha (Russia)", nativeName: "саха тыла (Арассыыйа)"},
	"saq": {displayName: "Kisampur", nativeName: "Kisampur"},
	"saq-KE": {displayName: "Kisampur (Kenya)", nativeName: "Kisampur (Kenya)"},
	"sbp": {displayName: "Ishisangu", nativeName: "Ishisangu"},
	"sbp-TZ": {displayName: "Ishisangu (Tansaniya)", nativeName: "Ishisangu (Tansaniya)"},
	"sd": {displayName: "Sindhi", nativeName: "سنڌي"},
	"sd-Arab-PK": {displayName: "Sindhi (Arabic, Pakistan)", nativeName: "سنڌي (عربي, پاڪستان)"},
	"se": {displayName: "Northern Sami", nativeName: "davvisámegiella"},
	"se-FI": {displayName: "Northern Sami (Finland)", nativeName: "davvisámegiella (Suopma)"},
	"se-NO": {displayName: "Northern Sami (Norway)", nativeName: "davvisámegiella (Norga)"},
	"se-SE": {displayName: "Northern Sami (Sweden)", nativeName: "davvisámegiella (Ruoŧŧa)"},
	"seh": {displayName: "sena", nativeName: "sena"},
	"seh-MZ": {displayName: "sena (Moçambique)", nativeName: "sena (Moçambique)"},
	"ses": {displayName: "Koyraboro senni", nativeName: "Koyraboro senni"},
	"ses-ML": {displayName: "Koyraboro senni (Maali)", nativeName: "Koyraboro senni (Maali)"},
	"sg": {displayName: "Sängö", nativeName: "Sängö"},
	"sg-CF": {displayName: "Sängö (Ködörösêse tî Bêafrîka)", nativeName: "Sängö (Ködörösêse tî Bêafrîka)"},
	"shi": {displayName: "ⵜⴰⵛⵍⵃⵉⵜ", nativeName: "ⵜⴰⵛⵍⵃⵉⵜ"},
	"shi-Latn": {displayName: "Tashelḥiyt", nativeName: "Tashelḥiyt"},
	"shi-Latn-MA": {displayName: "Tashelḥiyt (Latn, lmɣrib)", nativeName: "Tashelḥiyt (Latn, lmɣrib)"},
	"shi-Tfng": {displayName: "ⵜⴰⵛⵍⵃⵉⵜ", nativeName: "ⵜⴰⵛⵍⵃⵉⵜ"},
	"shi-Tfng-MA": {displayName: "ⵜⴰⵛⵍⵃⵉⵜ (Tfng, ⵍⵎⵖⵔⵉⴱ)", nativeName: "ⵜⴰⵛⵍⵃⵉⵜ (Tfng, ⵍⵎⵖⵔⵉⴱ)"},
	"si": {displayName: "Sinhala", nativeName: "සිංහල"},
	"si-LK": {displayName: "Sinhala (Sri Lanka)", nativeName: "සිංහල (ශ්‍රී ලංකාව)"},
	"sk": {displayName: "Slovak", nativeName: "slovenčina"},
	"sk-SK": {displayName: "Slovak (Slovakia)", nativeName: "slovenčina (Slovensko)"},
	"sl": {displayName: "Slovenian", nativeName: "slovenščina"},
	"sl-SI": {displayName: "Slovenian (Slovenia)", nativeName: "slovenščina (Slovenija)"},
	"sma": {displayName: "Southern Sami", nativeName: "Åarjelsaemien gïele"},
	"sma-NO": {displayName: "Southern Sami (Norway)", nativeName: "Åarjelsaemien gïele (NO)"},
	"sma-SE": {displayName: "Southern Sami (Sweden)", nativeName: "Åarjelsaemien gïele (SE)"},
	"smj": {displayName: "Lule Sami", nativeName: "julevsámegiella"},
	"smj-NO": {displayName: "Lule Sami (Norway)", nativeName: "julevsámegiella (NO)"},
	"smj-SE": {displayName: "Lule Sami (Sweden)", nativeName: "julevsámegiella (SE)"},
	"smn": {displayName: "Inari Sami", nativeName: "anarâškielâ"},
	"smn-FI": {displayName: "Inari Sami (Finland)", nativeName: "anarâškielâ (Suomâ)"},
	"sms": {displayName: "Skolt Sami", nativeName: "sms"},
	"sms-FI": {displayName: "Skolt Sami (Finland)", nativeName: "sms (FI)"},
	"sn": {displayName: "chiShona", nativeName: "chiShona"},
	"sn-Latn-ZW": {displayName: "chiShona (Latn, Zimbabwe)", nativeName: "chiShona (Latn, Zimbabwe)"},
	"so": {displayName: "Somali", nativeName: "Soomaali"},
	"so-DJ": {displayName: "Soomaali (Jabuuti)", nativeName: "Soomaali (Jabuuti)"},
	"so-ET": {displayName: "Soomaali (Itoobiya)", nativeName: "Soomaali (Itoobiya)"},
	"so-KE": {displayName: "Soomaali (Kenya)", nativeName: "Soomaali (Kenya)"},
	"so-SO": {displayName: "Somali (Somalia)", nativeName: "Soomaali (Soomaaliya)"},
	"sq": {displayName: "Albanian", nativeName: "shqip"},
	"sq-AL": {displayName: "Albanian (Albania)", nativeName: "shqip (Shqipëri)"},
	"sq-MK": {displayName: "shqip (Maqedonia e Veriut)", nativeName: "shqip (Maqedonia e Veriut)"},
	"sq-XK": {displayName: "shqip (Kosovë)", nativeName: "shqip (Kosovë)"},
	"sr": {displayName: "Serbian", nativeName: "српски"},
	"sr-Cyrl": {displayName: "Serbian (Cyrillic)", nativeName: "српски"},
	"sr-Cyrl-BA": {displayName: "Serbian (Cyrillic, Bosnia & Herzegovina)", nativeName: "српски (ћирилица, Босна и Херцеговина)"},
	"sr-Cyrl-ME": {displayName: "Serbian (Cyrillic, Montenegro)", nativeName: "српски (ћирилица, Црна Гора)"},
	"sr-Cyrl-RS": {displayName: "Serbian (Cyrillic, Serbia)", nativeName: "српски (ћирилица, Србија)"},
	"sr-Cyrl-XK": {displayName: "српски (ћирилица, Косово)", nativeName: "српски (ћирилица, Косово)"},
	"sr-Latn": {displayName: "Serbian (Latin)", nativeName: "srpski"},
	"sr-Latn-BA": {displayName: "Serbian (Latin, Bosnia & Herzegovina)", nativeName: "srpski (latinica, Bosna i Hercegovina)"},
	"sr-Latn-ME": {displayName: "Serbian (Latin, Montenegro)", nativeName: "srpski (latinica, Crna Gora)"},
	"sr-Latn-RS": {displayName: "Serbian (Latin, Serbia)", nativeName: "srpski (latinica, Srbija)"},
	"sr-Latn-XK": {displayName: "srpski (latinica, Kosovo)", nativeName: "srpski (latinica, Kosovo)"},
	"ss": {displayName: "siSwati", nativeName: "siSwati"},
	"ss-SZ": {displayName: "siSwati (eSwatini)", nativeName: "siSwati (eSwatini)"},
	"ss-ZA": {displayName: "siSwati (ZA)", nativeName: "siSwati (ZA)"},
	"ssy": {displayName: "ssy", nativeName: "ssy"},
	"ssy-ER": {displayName: "ssy (ER)", nativeName: "ssy (ER)"},
	"st": {displayName: "Sesotho", nativeName: "Sesotho"},
	"st-LS": {displayName: "Sesotho (LS)", nativeName: "Sesotho (LS)"},
	"st-ZA": {displayName: "Sesotho (South Africa)", nativeName: "Sesotho (ZA)"},
	"sv": {displayName: "Swedish", nativeName: "svenska"},
	"sv-AX": {displayName: "svenska (Åland)", nativeName: "svenska (Åland)"},
	"sv-FI": {displayName: "Swedish (Finland)", nativeName: "svenska (Finland)"},
	"sv-SE": {displayName: "Swedish (Sweden)", nativeName: "svenska (Sverige)"},
	"sw": {displayName: "Kiswahili", nativeName: "Kiswahili"},
	"sw-CD": {displayName: "Kiswahili (Jamhuri ya Kidemokrasia ya Kongo)", nativeName: "Kiswahili (Jamhuri ya Kidemokrasia ya Kongo)"},
	"sw-KE": {displayName: "Kiswahili (Kenya)", nativeName: "Kiswahili (Kenya)"},
	"sw-TZ": {displayName: "Kiswahili (Tanzania)", nativeName: "Kiswahili (Tanzania)"},
	"sw-UG": {displayName: "Kiswahili (Uganda)", nativeName: "Kiswahili (Uganda)"},
	"syr": {displayName: "Syriac", nativeName: "syr"},
	"syr-SY": {displayName: "Syriac (Syria)", nativeName: "syr (SY)"},
	"ta": {displayName: "Tamil", nativeName: "தமிழ்"},
	"ta-IN": {displayName: "Tamil (India)", nativeName: "தமிழ் (இந்தியா)"},
	"ta-LK": {displayName: "Tamil (Sri Lanka)", nativeName: "தமிழ் (இலங்கை)"},
	"ta-MY": {displayName: "தமிழ் (மலேசியா)", nativeName: "தமிழ் (மலேசியா)"},
	"ta-SG": {displayName: "தமிழ் (சிங்கப்பூர்)", nativeName: "தமிழ் (சிங்கப்பூர்)"},
	"te": {displayName: "Telugu", nativeName: "తెలుగు"},
	"te-IN": {displayName: "Telugu (India)", nativeName: "తెలుగు (భారతదేశం)"},
	"teo": {displayName: "Kiteso", nativeName: "Kiteso"},
	"teo-KE": {displayName: "Kiteso (Kenia)", nativeName: "Kiteso (Kenia)"},
	"teo-UG": {displayName: "Kiteso (Uganda)", nativeName: "Kiteso (Uganda)"},
	"tg": {displayName: "Tajik", nativeName: "тоҷикӣ"},
	"tg-Cyrl-TJ": {displayName: "Tajik (Cyrillic, Tajikistan)", nativeName: "тоҷикӣ (Кириллӣ, Тоҷикистон)"},
	"th": {displayName: "Thai", nativeName: "ไทย"},
	"th-TH": {displayName: "Thai (Thailand)", nativeName: "ไทย (ไทย)"},
	"ti": {displayName: "Tigrinya", nativeName: "ትግርኛ"},
	"ti-ER": {displayName: "Tigrinya (Eritrea)", nativeName: "ትግርኛ (ኤርትራ)"},
	"ti-ET": {displayName: "Tigrinya (Ethiopia)", nativeName: "ትግርኛ (ኢትዮጵያ)"},
	"tig": {displayName: "tig", nativeName: "tig"},
	"tig-ER": {displayName: "tig (ER)", nativeName: "tig (ER)"},
	"tk": {displayName: "Turkmen", nativeName: "türkmen dili"},
	"tk-TM": {displayName: "Turkmen (Turkmenistan)", nativeName: "türkmen dili (Türkmenistan)"},
	"tn": {displayName: "Setswana", nativeName: "tn"},
	"tn-BW": {displayName: "Setswana (Botswana)", nativeName: "tn (BW)"},
	"tn-ZA": {displayName: "Setswana (South Africa)", nativeName: "tn (Aforika Borwa)"},
	"to": {displayName: "lea fakatonga", nativeName: "lea fakatonga"},
	"to-TO": {displayName: "lea fakatonga (Tonga)", nativeName: "lea fakatonga (Tonga)"},
	"tr": {displayName: "Turkish", nativeName: "Türkçe"},
	"tr-CY": {displayName: "Türkçe (Kıbrıs)", nativeName: "Türkçe (Kıbrıs)"},
	"tr-TR": {displayName: "Turkish (Turkey)", nativeName: "Türkçe (Türkiye)"},
	"ts": {displayName: "Xitsonga", nativeName: "ts"},
	"ts-ZA": {displayName: "Xitsonga (South Africa)", nativeName: "ts (ZA)"},
	"tt": {displayName: "Tatar", nativeName: "татар"},
	"tt-RU": {displayName: "Tatar (Russia)", nativeName: "татар (Россия)"},
	"twq": {displayName: "Tasawaq senni", nativeName: "Tasawaq senni"},
	"twq-NE": {displayName: "Tasawaq senni (Nižer)", nativeName: "Tasawaq senni (Nižer)"},
	"tzm": {displayName: "Central Atlas Tamazight", nativeName: "Tamaziɣt n laṭlaṣ"},
	"tzm-Latn-MA": {displayName: "Tamaziɣt n laṭlaṣ (Latn, Meṛṛuk)", nativeName: "Tamaziɣt n laṭlaṣ (Latn, Meṛṛuk)"},
	"ug": {displayName: "Uyghur", nativeName: "ئۇيغۇرچە"},
	"ug-CN": {displayName: "Uyghur (China)", nativeName: "ئۇيغۇرچە (جۇڭگو)"},
	"uk": {displayName: "Ukrainian", nativeName: "українська"},
	"uk-UA": {displayName: "Ukrainian (Ukraine)", nativeName: "українська (Україна)"},
	"ur": {displayName: "Urdu", nativeName: "اردو"},
	"ur-IN": {displayName: "Urdu (India)", nativeName: "اردو (بھارت)"},
	"ur-PK": {displayName: "Urdu (Pakistan)", nativeName: "اردو (پاکستان)"},
	"uz": {displayName: "Uzbek", nativeName: "o‘zbek"},
	"uz-Arab": {displayName: "اوزبیک", nativeName: "اوزبیک"},
	"uz-Arab-AF": {displayName: "اوزبیک (عربی, افغانستان)", nativeName: "اوزبیک (عربی, افغانستان)"},
	"uz-Cyrl": {displayName: "Uzbek (Cyrillic)", nativeName: "ўзбекча"},
	"uz-Cyrl-UZ": {displayName: "Uzbek (Cyrillic, Uzbekistan)", nativeName: "ўзбекча (Кирил, Ўзбекистон)"},
	"uz-Latn": {displayName: "Uzbek (Latin)", nativeName: "o‘zbek"},
	"uz-Latn-UZ": {displayName: "Uzbek (Latin, Uzbekistan)", nativeName: "o‘zbek (lotin, Oʻzbekiston)"},
	"vai": {displayName: "ꕙꔤ", nativeName: "ꕙꔤ"},
	"vai-Latn": {displayName: "Vai", nativeName: "Vai"},
	"vai-Latn-LR": {displayName: "Vai (Latn, Laibhiya)", nativeName: "Vai (Latn, Laibhiya)"},
	"vai-Vaii": {displayName: "ꕙꔤ", nativeName: "ꕙꔤ"},
	"vai-Vaii-LR": {displayName: "ꕙꔤ (Vaii, ꕞꔤꔫꕩ)", nativeName: "ꕙꔤ (Vaii, ꕞꔤꔫꕩ)"},
	"ve": {displayName: "Venda", nativeName: "ve"},
	"ve-ZA": {displayName: "Venda (South Africa)", nativeName: "ve (ZA)"},
	"vi": {displayName: "Vietnamese", nativeName: "Tiếng Việt"},
	"vi-VN": {displayName: "Vietnamese (Vietnam)", nativeName: "Tiếng Việt (Việt Nam)"},
	"vo": {displayName: "vo", nativeName: "vo"},
	"vo-001": {displayName: "vo (001)", nativeName: "vo (001)"},
	"vun": {displayName: "Kyivunjo", nativeName: "Kyivunjo"},
	"vun-TZ": {displayName: "Kyivunjo (Tanzania)", nativeName: "Kyivunjo (Tanzania)"},
	"wae": {displayName: "Walser", nativeName: "Walser"},
	"wae-CH": {displayName: "Walser (Schwiz)", nativeName: "Walser (Schwiz)"},
	"wal": {displayName: "wal", nativeName: "wal"},
	"wal-ET": {displayName: "wal (ET)", nativeName: "wal (ET)"},
	"wo": {displayName: "Wolof", nativeName: "Wolof"},
	"wo-SN": {displayName: "Wolof (Senegal)", nativeName: "Wolof (Senegaal)"},
	"xh": {displayName: "isiXhosa", nativeName: "isiXhosa"},
	"xh-ZA": {displayName: "isiXhosa (South Africa)", nativeName: "isiXhosa (eMzantsi Afrika)"},
	"xog": {displayName: "Olusoga", nativeName: "Olusoga"},
	"xog-UG": {displayName: "Olusoga (Yuganda)", nativeName: "Olusoga (Yuganda)"},
	"yav": {displayName: "nuasue", nativeName: "nuasue"},
	"yav-CM": {displayName: "nuasue (Kemelún)", nativeName: "nuasue (Kemelún)"},
	"yi": {displayName: "Yiddish", nativeName: "ייִדיש"},
	"yi-001": {displayName: "Yiddish (World)", nativeName: "ייִדיש (וועלט)"},
	"yo": {displayName: "Yoruba", nativeName: "Èdè Yorùbá"},
	"yo-BJ": {displayName: "Èdè Yorùbá (Orílɛ́ède Bɛ̀nɛ̀)", nativeName: "Èdè Yorùbá (Orílɛ́ède Bɛ̀nɛ̀)"},
	"yo-NG": {displayName: "Yoruba (Nigeria)", nativeName: "Èdè Yorùbá (Orilẹ̀-èdè Nàìjíríà)"},
	"zgh": {displayName: "ⵜⴰⵎⴰⵣⵉⵖⵜ", nativeName: "ⵜⴰⵎⴰⵣⵉⵖⵜ"},
	"zgh-Tfng-MA": {displayName: "ⵜⴰⵎⴰⵣⵉⵖⵜ (Tfng, ⵍⵎⵖⵔⵉⴱ)", nativeName: "ⵜⴰⵎⴰⵣⵉⵖⵜ (Tfng, ⵍⵎⵖⵔⵉⴱ)"},
	"zh": {displayName: "Chinese", nativeName: "中文"},
	"zh-Hans": {displayName: "Chinese (Simplified)", nativeName: "中文"},
	"zh-CN": {displayName: "Chinese (China)", nativeName: "中文（中国）"},
	"zh-Hans-HK": {displayName: "中文（简体，香港特别行政区）", nativeName: "中文（简体，香港特别行政区）"},
	"zh-Hans-MO": {displayName: "中文（简体，澳门特别行政区）", nativeName: "中文（简体，澳门特别行政区）"},
	"zh-SG": {displayName: "Chinese (Singapore)", nativeName: "中文（新加坡）"},
	"zh-Hant": {displayName: "Chinese (Traditional)", nativeName: "中文"},
	"zh-HK": {displayName: "Chinese (Hong Kong SAR)", nativeName: "中文（香港特別行政區）"},
	"zh-MO": {displayName: "Chinese (Macao SAR)", nativeName: "中文（澳門特別行政區）"},
	"zh-TW": {displayName: "Chinese (Taiwan)", nativeName: "中文（台灣）"},
	"zu": {displayName: "isiZulu", nativeName: "isiZulu"},
	"zu-ZA": {displayName: "isiZulu (South Africa)", nativeName: "isiZulu (iNingizimu Afrika)"
	}
}


