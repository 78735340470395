import {transformFileAttachment} from "../../dataprovider/datastoreProvider"
import dataProviderCommands from "./dataProviderCommands";
import prettyoutput from 'prettyoutput';

const imagePickerOptions = 
{
    types: [ { description: 'Images', accept: { 'image/*': ['.png', '.jpeg', '.jpg'] } }, ],
    excludeAcceptAllOption: true,
    multiple: false
};

const imageFileCommands = {
    create: {
        description: 'create an imageFile from a local image',
        usage: 'create -> choose file from ImagePicker',
        fn: async (terminal) =>{
            try
            {   
                let [fileHandle] = await window.showOpenFilePicker(imagePickerOptions);
                const fileData = await fileHandle.getFile();

                const data = transformFileAttachment({_imageStorageKey : {rawFile: fileData}}, ['_imageStorageKey'], {level: 'public'});
                const result = await terminal.dataProvider.create('ImageFile', {data: data});

                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list image files',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "ImageFile",page,perPage)
    },
    get: {
        description: 'get ImageFile by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"ImageFile",id)
    },
    delete: {
        description: 'delete ImageFile by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"ImageFile",id)
    },
    listlang:
    {
        description: 'list language associations for a imagefile',
        usage: 'listlang <imageFileId>',
        fn: async (terminal, imageId) => {
            try
            {
                const {data:imageFileLanguageList} = await terminal.dataProvider.getList('ImageFileLanguage', {});
                const imageFileLanguages = imageFileLanguageList.filter(ifl => ifl.imagefile.id === imageId);
                terminal.log(prettyoutput(imageFileLanguages));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addlang:
    {
        description: 'associate an ImageFile with a language.',
        usage: 'addlang <imageFileId> <languageId>',
        fn: async (terminal, imageId, languageId) => 
        {
            try
            {
                const {data:imagefile} = await terminal.dataProvider.getOne('ImageFile', {id:imageId});
                const {data:language} = await terminal.dataProvider.getOne('Language',  {id:languageId});
                const data = {
                    imagefile: imagefile,
                    language: language
                }
                const result = await terminal.dataProvider.create('ImageFileLanguage', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remlang: 
    {
        description: 'remove a language association',
        usage: 'remlang <imageFileLanguageId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"ImageFileLanguage",id)
    }
}

export default imageFileCommands;