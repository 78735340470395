import React, { useState, useEffect, useRef } from "react";
import * as _ from 'lodash';
import { Storage } from "@aws-amplify/storage";

/**
 *  Retrieves a presigned url from the S3 storage for a given key, and
 *  provides it to its children.
 * 
 *  ## Props
 * 
 *  ### **required**
 *  @param {} record the object containing the s3 key
 *  @param {string} source the field name of the key in the record
 *  
 *  ### *optional*
 *  @param {string} destination the field name to store the url in
 *  (defaults to 'source + _url')
 *  @param {boolean} appendRecord whether to add the url field to the
 *  root of childs props or to append it to the record
 *  (defaults to false)
 *  
 */
export const S3RecordFileProvider = ({
  children,
  source,
  destination,
  storageoptions,
  record,
  appendRecord,
  ...props }) => {

  const [url, setUrl] = useState('');
  const mountedRef = useRef(true)

  if (_.isEmpty(destination)) destination = `${source}_url`

  useEffect(() => {
    const getUrl = async () => {
      if (record[source]) {
        try {
          const url = await Storage.get(record[source], storageoptions);
          if (!mountedRef.current) return null;
          setUrl(url);
        }
        catch (error) {
          console.error(error);
        }
      }
    }

    let { expires = 900 } = storageoptions ?? {}; // presigned urls expire after 15 min (900s) by default
    expires *= (.8 * 1000);                // refresh the prsigned url at 80% to the expiration date 
    const refreshPresignedUrl = setInterval(() => getUrl(), expires);

    getUrl();
    return () => {
      clearInterval(refreshPresignedUrl);
      mountedRef.current = false;
    }
  }, [])

  const buildChildProps = () => {
    let { basePath, ...childProps } = props;
    if (appendRecord) {
      let extendedRecord = { ...record }
      extendedRecord[destination] = url;
      return { record: extendedRecord, ...props }
    }
    else {
      childProps[destination] = url;
      return childProps;
    }
  }

  return (
    React.Children.toArray(children)
      .map(c => React.cloneElement(c, buildChildProps())))

};

S3RecordFileProvider.displayName = "S3RecordFileProvider"



/**
 *  Retrieves a presigned url from the S3 storage for a given key, and
 *  provides it to its children.
 * 
 *  ## Props
 * 
 *  ### **required**
 *  @param {string} key the s3 key of the file
 *  
 *  ### *optional*
 *  @param {string} destination the field name to store the url in
 *  (defaults to 'src')
 *  
 */
export const S3FileProvider = ({
  children,
  s3key,
  destination,
  storageoptions,
  ...props }) => {

  const [url, setUrl] = useState(undefined);
  const mountedRef = useRef(true)

  if (_.isEmpty(destination)) destination = `src`

  useEffect(() => {
    const getUrl = async () => {
      if (s3key) {
        try {
          const url = await Storage.get(s3key, storageoptions);
          if (!mountedRef.current) return null;
          setUrl(url);
        }
        catch (error) {
          console.error(error);
        }
      }
    }

    let { expires = 900 } = storageoptions ?? {}; // presigned urls expire after 15 min (900s) by default
    expires *= (.8 * 1000);                // refresh the prsigned url at 80% to the expiration date 
    const refreshPresignedUrl = setInterval(() => getUrl(), expires);

    getUrl();
    return () => {
      clearInterval(refreshPresignedUrl);
      mountedRef.current = false;
    }
  }, [])

  const buildChildProps = () => {
    let {...childProps } = props;
    childProps[destination] = url;
    return childProps;
  }

  return (
    url ?
    React.Children.toArray(children)
      .map(c => React.cloneElement(c, buildChildProps())) 
    : null
    )

};

S3FileProvider.displayName = "S3FileProvider"