import { RECORD_LOCATION, CLEAR_HISTORY } from "./action";
import * as _ from 'lodash';

const initialState = [];
const historyLength = 50;

const historyReducer = (state = initialState, action) =>
{
    switch(action.type) {
        case RECORD_LOCATION: {       
            if(_.isEmpty(state) || state[0].pathname != action.payload.pathname)
            {
                // return [action.payload, ...state.slice(0,historyLength-1)];
                return [action.payload, ...state];
            }
            else return state;
        }
        case CLEAR_HISTORY: {
            return initialState;
        }
        default: 
            return state;      
    }
}


export default historyReducer;