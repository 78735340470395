import prettyoutput from 'prettyoutput';
import dataProviderCommands from "./dataProviderCommands";

const translationsCommands = {
    create: {
        description: 'add a text translation',
        usage: 'create <text> <languageKeyId> <translationKeyId>',
        fn: async(terminal, text, languageId, translationKeyId) =>{
            try{
                let data ={
                    value:text,
                    translationkeyID:translationKeyId,
                    languageID:languageId
                }
                const result = await terminal.dataProvider.create('Translation', {data: data});
                 terminal.log(prettyoutput(result));
                 return
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list translations',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "Translation",page,perPage)
    },
    get: {
        description: 'get translation by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"Translation",id)
    },
    delete: {
        description: 'delete translation by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"Translation",id)
    }
}

export default translationsCommands