import prettyoutput from 'prettyoutput';
import {transformFileAttachment} from "../../dataprovider/datastoreProvider"
import dataProviderCommands from "./dataProviderCommands";

const imagePickerOptions = 
{
    types: [ { description: 'Images', accept: { 'image/*': ['.png', '.jpeg', '.jpg'] } }, ],
    excludeAcceptAllOption: true,
    multiple: false
};

const videoPickerOptions = 
{
    types: [ { description: 'Vieos', accept: { 'video/*': ['.mp4','.m4v'] } }, ],
    excludeAcceptAllOption: true,
    multiple: false
};

const videoFileCommands = {
    create: {
        description: 'create an videoFile from a local poster image and video',
        usage: 'createVideo <title> -> choose poster image, then choose video ',
        fn: async (terminal, title) =>{
            try
            {   
                let [posterFileHandle] = await window.showOpenFilePicker(imagePickerOptions);
                const posterFileData = await posterFileHandle.getFile();

                let [videoFileHandle] = await window.showOpenFilePicker(videoPickerOptions);
                const vidoeFileData = await videoFileHandle.getFile(); 

                const data = transformFileAttachment(
                    {
                        title: title, 
                        _posterStorageKey : {rawFile: posterFileData}, 
                        _videoStorageKey : {rawFile: vidoeFileData}
                    }, 
                    ['_posterStorageKey', '_videoStorageKey'], 
                    {level: 'public'}
                );
                const result = await terminal.dataProvider.create('VideoFile', {data: data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch(error){
                console.error(error);
                return error.message;
            }
        }
    },
    list: {
        description: 'list video files',
        usage: 'list <page> <perPage>',
        fn: async (terminal, page, perPage) => await dataProviderCommands.list.fn(terminal, "VideoFile",page,perPage)
    },
    get: {
        description: 'get video file by id',
        usage: 'get <id>',
        fn: async (terminal, id) => await dataProviderCommands.get.fn(terminal,"VideoFile",id)
    },
    delete: {
        description: 'delete video file by id',
        usage: 'delete <id>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"VideoFile",id)
    },
    listlang:
    {
        description: 'list language associations for a video file',
        usage: 'listlang <videoFileId>',
        fn: async (terminal, videoId) => {
            try
            {
                const {data:videoFileLanguageList} = await terminal.dataProvider.getList('VideoFileLanguage', {});
                const videoFileLanguages = videoFileLanguageList.filter(vfl => vfl.videofile.id === videoId);
                terminal.log(prettyoutput(videoFileLanguages));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
        
    },
    addlang:
    {
        description: 'associate an VideoFile with a language',
        usage: 'addlang <videoFileId> <languageId>',
        fn: async (terminal, videoId, languageId) => 
        {
            try
            {
                const {data:videofile} = await terminal.dataProvider.getOne('VideoFile', {id:videoId});
                const {data:language} = await terminal.dataProvider.getOne('Language',  {id:languageId});
                const data = {
                    videofile: videofile,
                    language: language
                }
                const result = await terminal.dataProvider.create('VideoFileLanguage', {data:data});
                terminal.log(prettyoutput(result));
                return;
            }
            catch (error)
            {
                console.error(error);
                return error.message;
            }
        }
    },
    remlang: 
    {
        description: 'remove a language association',
        usage: 'remlang <videoFileLanguageId>',
        fn: async (terminal, id) => await dataProviderCommands.delete.fn(terminal,"VideoFileLanguage",id)
    }
}

export default videoFileCommands;